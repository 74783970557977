import React, { Component } from "react";

import {connect} from "react-redux";
import adultIcon from "../../assets/images/menu/adult.png";
import minusIcon from "../../assets/images/menu/minus.png";
import plusIcon from "../../assets/images/menu/plus.png";
import childrenIcon from "../../assets/images/menu/childern.png";
import infantsIcon from "../../assets/images/menu/infants.png";

class PassengersDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adults : 1,
            children : 0,
            infants  : 0,
        };
    }

    componentDidMount() {
        let  {searchFlight} = this.props.flight;
        this.setState(searchFlight)
         this.setState({
             adults :searchFlight.adults ||  1,
             children :searchFlight.children ||  0,
             infants  : searchFlight.infants || 0,
         });
    }

    adultOption = (add) => {
        const {adults, children, infants} = this.state;
        const count = adults + children + infants;
        if(add === 'add' && count < 9){
            // this.setState({adults : adults+1})
            this.onChange('adults', adults+1);
        }
        else if(adults > 1){
            if(infants > (adults -1)){
                // this.setState({infants : adults - 1});
                this.onChange('infants', adults-1);

            }
            // this.setState({adults : adults - 1})
            this.onChange('adults', adults-1);

        }
    }
    childrenOption = (add) => {
        const {adults, children, infants} = this.state;
        const count = adults + children + infants;
        if(add === 'add' && count < 9){
            // this.setState({children : children+1})
            this.onChange('children', children+1);
        }
        else if(children > 0){
            // this.setState({children : children-1})
            this.onChange('children', children-1);
        }
    }
    infantsOption = (add) => {
        const {adults, children, infants} = this.state;
        const count = adults + children + infants;
        if(add === 'add' && infants < adults && count < 9){
            // this.setState({infants : infants+1})
            this.onChange('infants', infants+1);

        }
        else if(adults > 0){
            // this.setState({infants : infants-1})
            this.onChange('infants', infants-1);
        }
    }


    onChange(name, value){
        this.setState({[name] : value})
        this.props.onChange(value , name);
    }


    render() {
        const {adults, children, infants } = this.state
        const count = adults + children + infants;
        const adultPlusDisable = count > 8;
        const adultMinusDisable = adults < 2;
        const childrenPlusDisable = count > 8;
        const childrenMinusDisable = children < 1;
        const infantsPlusDisable = infants >= adults || count > 8 ;
        const infantsMinusDisable = infants < 1;
        return (
            <div className="dropdown select-drop-down">
                <button  className="btn-dropdown dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                    {count} {infants || children ? 'Passengers' :  count > 1 ? 'Adults' : 'Adult'}
                </button>
                <div className="dropdown-menu passengers-dropdown" aria-labelledby="dropdownMenuButton">
                    <form>
                        <div className="passengers-box">
                            <div className="passengers-row d-flex justify-content-between align-items-center">
                                <div className="d-flex passengers-age align-items-center">
                                    <div className="img d-flex"><img src={adultIcon} alt="a"/></div>
                                    <div className="d-flex flex-column">
                                        <span className="f-15">Adult</span>
                                        <span className="f-12">>12 years</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center quantity-option">
                                    <button type="button" className="btn btn-primary" onClick={() => this.adultOption('minus')} disabled={adultMinusDisable}>
                                        <img src={minusIcon} alt="a"/>
                                    </button>
                                    <span>{adults}</span>
                                    <button type="button" className="btn btn-primary" onClick={() => this.adultOption('add')} disabled={adultPlusDisable}>
                                        <img src={plusIcon} alt="a"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="passengers-box">
                            <div className="passengers-row d-flex justify-content-between align-items-center">
                                <div className="d-flex passengers-age align-items-center">
                                    <div className="img d-flex"><img src={childrenIcon} alt="a"/></div>
                                    <div className="d-flex flex-column">
                                        <span className="f-15">Children</span>
                                        <span className="f-12">2-12 years</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center quantity-option">
                                    <button type="button" className="btn btn-primary" onClick={() => this.childrenOption('minus')} disabled={childrenMinusDisable}> <img src={minusIcon} alt="a"/></button>
                                    <span>{children}</span>
                                    <button type="button" className="btn btn-primary" onClick={() => this.childrenOption('add')} disabled={childrenPlusDisable}> <img src={plusIcon} alt="a"/></button>
                                </div>
                            </div>
                        </div>
                        <div className="passengers-box">
                            <div className="passengers-row d-flex justify-content-between align-items-center">
                                <div className="d-flex passengers-age align-items-center">
                                    <div className="img d-flex"><img src={infantsIcon} alt="a"/></div>
                                    <div className="d-flex flex-column">
                                        <span className="f-15">Infants</span>
                                        <span className="f-12">{`< 2 years`}</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center quantity-option">
                                    <button type="button" className="btn btn-primary" onClick={() => this.infantsOption('minus')} disabled={infantsMinusDisable}> <img src={minusIcon} alt="a"/></button>
                                    <span>{infants}</span>
                                    <button type="button" className="btn btn-primary" onClick={() => this.infantsOption('add')} disabled={infantsPlusDisable}> <img src={plusIcon} alt="a"/></button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}



const mapStateToProps = state =>({
    flight: state.flight,
});



export default connect(
    mapStateToProps,
    null
)(PassengersDropdown);
