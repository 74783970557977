import {
  MENU_OPEN,
  DESIGNATION,
  DESIGNATION_FORM_HIDE,
  LANDING_PAGE,
  SEARCH_FLIGHT,
} from "../actions";

const initialState = {
  menuOpen: false,
  landingPage: false,
  designation: {},
  designationFormHide: true,
  searchFlight: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MENU_OPEN:
      return {
        ...state,
        menuOpen: action.payload,
      };
    case DESIGNATION:
      return {
        ...state,
        designation: action.payload,
      };
    case DESIGNATION_FORM_HIDE:
      return {
        ...state,
        designationFormHide: action.payload,
      };
    case LANDING_PAGE:
      return {
        ...state,
        landingPage: action.payload,
      };
    case SEARCH_FLIGHT:
      return {
        ...state,
        searchFlight: action.payload,
      };
    default:
      return state;
  }
};
