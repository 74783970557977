import React, { Component } from "react";
import logo from "../../assets/images/dl-logo.gif";
import { NavLink } from "react-router-dom";
import "./Header.scss";
import SideBarMenu from "../SideBarMenu/SideBarMenu";
import { logout, toggleMenu, loginAsGuest } from "../../store/actions";
import { connect } from "react-redux";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
// import { basicToken } from "../../utils/tokenUtils";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleGuestLogin = this.handleGuestLogin.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  logout = () => {
    this.props.logout();
  };

  handleNavigation = () => {
    const { menuOpen } = this.props.golbal;
    this.props.toggleMenu(!menuOpen);
  };
  linkOnClick = () => {
    this.props.toggleMenu(false);
  };

  handleGuestLogin = () => {
    setTimeout(() => {
      this.props.loginAsGuest();
    }, 500);
  };

  renderLogoutLeftMenu() {
    return (
      <>
        <div className="guest-login" onClick={this.handleGuestLogin}>
          <i className="icon-login text-primary"></i>
          <span> LOGIN AS GUEST</span>
        </div>
        <ul className="menu-link m-0">
          <li>
            <NavLink to="/account/login">
              <i className="icon-login text-primary"></i>
              <span> Login</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/account/registration">
              <i className="icon-user text-primary"></i>
              <span>Registration</span>
            </NavLink>
          </li>
        </ul>
      </>
    );
  }
  renderLogInLeftMenu() {
    const { menuOpen } = this.props.golbal;
    const isGuest = localStorage.getItem("user");
    const userName = localStorage.getItem("name");
    const menuClass = menuOpen
      ? "menu-btn bg-primary d-flex justify-content-center align-items-center active"
      : "menu-btn bg-primary d-flex justify-content-center align-items-center";

    return (
      <ul className="menu-link m-0 d-flex ">
        <li>
          <span className="guest-logged-in">
            LOGGED IN AS{" "}
            {isGuest === "guest"
              ? "GUEST"
              : userName
              ? userName.toUpperCase()
              : "USER"}
          </span>
        </li>

        <li>
          <NavLink to="/journey">
            <i className="icon-hotel text-primary"></i>
            <span>Start Journey</span>
          </NavLink>
        </li>

        <li>
          <button onClick={this.toggle}>
            <i className="icon-logout text-primary"></i>
            <span>LogOut</span>
          </button>
        </li>
        <li className="p-0">
          <button onClick={this.handleNavigation} className={menuClass}>
            <div className={menuOpen ? "nav-icon active" : "nav-icon"}>
              <span>&nbsp;</span>
            </div>
          </button>
        </li>
      </ul>
    );
  }

  render() {
    const { loggedIn } = this.props.auth;
    
    return (
      <div>
        <header className="header">
          <div className="header-container d-flex justify-content-between">
            <div className="logo  d-flex justify-content-center align-items-center">
              <NavLink
                to="/"
                className="d-inline-flex justify-content-center align-items-center"
              >
                Dynamic Living
                <img src={logo} alt=" Dynamic Living" className="w-100" />
              </NavLink>
            </div>
            <div className="navigation d-flex justify-content-between">
              {loggedIn
                ? this.renderLogInLeftMenu()
                : this.renderLogoutLeftMenu()}
            </div>
          </div>
        </header>
        {loggedIn ? <SideBarMenu onClick={this.linkOnClick} /> : null}
        <ConfirmationModal
          modal={this.state.modal}
          text="Are you sure you want to logout ?"
          onClickNo={() => this.setState({ modal: false })}
          onClickYes={() => {
            this.setState({ modal: false });
            this.logout();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  golbal: state.golbal,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: (bol) => dispatch(toggleMenu(bol)),
    logout: () => dispatch(logout()),
    loginAsGuest: () => dispatch(loginAsGuest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
