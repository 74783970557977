import React, { Component } from "react";
import "./Crime.scss";
import { connect } from "react-redux";

class CrimeDetails extends Component {
  render() {
    const { listing, id } = this.props;
    const { crime } = listing;
    const crimeDetails = crime.incidents.find(
      (data) => data.incident_code === id
    );

    return (
      <div className="modal-content__container-job text-white">
        <div className="modal-header text-center bg-primary border-0 py-4 text-white d-flex justify-content-center align-items-center font-secondary font-weight-bold">
          Crime Details
        </div>
        <div className="content-job-details px-0 px-md-5 mx-5 my-4 font-secondary">
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident Address:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_address}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident Code:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_code}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident Offense:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_offense}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident Against:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_offense_crime_against}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident Degree:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_offense_description}{" "}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident Type:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_source_original_type}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident Date:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_date.split("T")[0]}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Offence Code:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_offense_code}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Incident source:</div>
            <div className="col-12 col-md-9 value">
              {crimeDetails.incident_source_name.replace(
                new RegExp("\\_", "g"),
                " "
              )}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Description:</div>
            <div className="col-12 col-md-9 value">
              {" "}
              {crimeDetails.incident_offense_detail_description}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-12 col-md-3 key">Source Name:</div>
            <div className="col-12 col-md-9 value">
              {" "}
              {crimeDetails.incident_source_name}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listing: state.listing,
  golbal: state.golbal,
});

export default connect(mapStateToProps, null)(CrimeDetails);
