import React, { Component } from "react";
import {NavLink } from "react-router-dom";
import "./Footer.scss";
import DestinationForm from "../DestinationForm/DestinationForm";
import {connect} from "react-redux";

const date = new Date();
const year = date.getFullYear();

class Footer extends Component {

    destinationFormRender(){
        const {loggedIn}  = this.props.auth;
        const designationFormHide = this.props.golbal.designationFormHide;
        return  loggedIn && !designationFormHide ?  <DestinationForm /> : null
    }
    render() {
        return (
            <footer className="footer bg-primary text-white">
                <div className="footer__container bg-primary d-flex flex-column flex-lg-row  align-items-center justify-content-center justify-content-lg-between">
                    <div className="copy-right order-1 order-lg-0">
                        Copyright © {year} DYNAMIC LIVING. - All Rights Reserved
                    </div>
                    <div className="footer-menu order-0 order-lg-1">
                        <ul className="m-0">
                            <li><NavLink to="/privacy-policy" activeClassName="active">Privacy policy</NavLink></li>
                            <li><NavLink to="/terms-and-condition" activeClassName="active">Terms & Condition</NavLink></li>
                            {/*<li><NavLink to="/" activeClassName="active">Sitemap</NavLink></li>*/}
                        </ul>
                        <ul className="m-0">
                            <li className="noboder"><a href="https://www.facebook.com/dynamicsliving" target="_blank" rel="noopener noreferrer"><i className="icon-fb"></i></a></li>
                            <li className="noboder"><a href="https://twitter.com/Dynamics_Living" target="_blank" rel="noopener noreferrer"><i className="icon-twitter"></i></a></li>
                            <li className="noboder"><a href="https://www.instagram.com/thedynamicliving/" target="_blank" rel="noopener noreferrer"><i className="icon-instagram"></i></a></li>
                            <li className="noboder"><a href="https://plus.google.com/" target="_blank" rel="noopener noreferrer"><i className="icon-google-plus"></i></a></li>
                         </ul>
                    </div>
                </div>
                {this.destinationFormRender()}
            </footer>
        );
    }
}



const mapStateToProps = state =>({
    auth: state.auth,
    golbal: state.golbal,

});

export default connect(
    mapStateToProps,
    null
)(Footer);
