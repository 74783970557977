import React, { Component } from "react";
import "./LandingPage.scss";
import { connect } from "react-redux";
import { designation, landingPageToggle, logout } from "../../store/actions";
import States from "../../constant/states";
import { errorToast } from "../../utils/toaster";
import { Link, withRouter } from "react-router-dom";
import { sortedStateByCityAlphabetic } from "../../utils/helper";
import BannerSlider from "../../components/BannerSlider/BannerSlider";
import $ from "jquery";
import aboutImg from "../../assets/images/landingPageAssets/about-us.png";
import signature from "../../assets/images/landingPageAssets/signature.png";
import ourVision from "../../assets/images/landingPageAssets/our-vision.png";
import playIcon from "../../assets/images/landingPageAssets/play-icon.png";
import dvm from "../../assets/images/landingPageAssets/dvm.png";
import relator from "../../assets/images/landingPageAssets/relator.png";
import partner from "../../assets/images/landingPageAssets/partner.png";
import statistics from "../../assets/images/landingPageAssets/statistics.png";
import banner1 from "../../assets/images/landingPageAssets/b1.jpg";
import banner2 from "../../assets/images/landingPageAssets/b2.jpg";
import banner3 from "../../assets/images/landingPageAssets/b3.jpg";
import banner4 from "../../assets/images/landingPageAssets/b4.jpg";
import banner5 from "../../assets/images/landingPageAssets/b5.jpg";
import Slider from "react-slick";
// import footerLogo from "../../assets/images/download.png";

const citiesSort = sortedStateByCityAlphabetic();

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: true,
  centerPadding: "40px",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerPadding: "0",
      },
    },
  ],
};

const slides = [banner1, banner2, banner3, banner4, banner5];

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittedForm: true,
      to: { name: "" },
      from: { name: "" },
      filterBy: "state",
      isRelocating: "",
      menuActive: false,
      modal: false,
      newsletterEmail: "",
      newsletterEmailLoading: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  logout = () => {
    this.props.logout();
  };

  renderOptions() {
    const filterBy = this.state.filterBy;
    return filterBy === "city"
      ? citiesSort.map((item) => {
          return (
            <option key={item.state} value={item.cities[0].name}>
              {item.cities[0].name}
            </option>
          );
        })
      : States.map((item) => {
          return (
            <option key={item.state} value={item.name}>
              {item.name}
            </option>
          );
        });
  }

  componentDidMount() {
    this.props.landingPageToggle(true);
    this.watchWindowScroll();
  }

  componentWillUnmount() {
    // this.props.landingPageToggle(false);
    //header issue
    // this.watchWindowScroll();
  }

  watchWindowScroll() {
    const $window = $(window);
    const $header = $("#landing-header");
    $window.scroll(function () {
      const windowScroll = $(window).scrollTop();
      if (windowScroll > 0) $header.addClass("header__bg");
      else $header.removeClass("header__bg");
    });
  }

  changePreference = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value === "true" ? true : false });
  };

  change = (event) => {
    const { filterBy } = this.state;
    let { name, value } = event.target;
    if (name === "filterBy") {
      this.setState({ filterBy: value });
    } else {
      if (filterBy === "city") {
        for (let i = 0; i < States.length; i++) {
          if (States[i].cities[0].name === value) {
            this.setState({ [name]: States[i].cities[0] });
            break;
          }
        }
      } else {
        const state = States.find((state) => state.name === value);
        this.setState({ [name]: state });
      }
    }
  };

  submit = () => {
    const { to, from, filterBy, isRelocating } = this.state;
    // console.log('relocating: ', isRelocating === false)
    if(isRelocating === ""){
      errorToast("Please select Relocating or Viewing city/state first");
      return
    }
    if (isRelocating === false) {
      if (!to.name) {
        errorToast("Location Field is required");
        return false;
      }

      this.props.designation({
        to,
        filterBy,
        isRelocating,
      });
    } else {
      if (!to.name && !from.name) {
        errorToast("All Fields both are required");
        return false;
      } else if (!to.name) {
        errorToast("State and location Field is required");
        return false;
      } else if (!from.name) {
        errorToast("State and location of Field is required");
        return false;
      } else if (to.name === from.name) {
        errorToast("both locations are same");
        return false;
      }
      this.props.designation({
        to,
        from,
        filterBy,
        isRelocating,
      });
    }

    this.props.history.push("/home");
  };

  handleNavigation = () => {
    const { menuActive } = this.state;
    this.setState({ menuActive: !menuActive });
  };

  render() {
    const { loggedIn } = this.props.auth;
    const { menuActive, isRelocating } = this.state;
    const isGuest = localStorage.getItem("user");
    // console.log('isguest: ', isGuest)

    return (
      <div className="lp">
        <div className="landing-page main-wrapper">
          <div className="container-fluid landing-page__main-wrapper">
            <section className="destination d-flex align-items-center justify-content-center">
              <div className="destination__content d-flex align-items-center justify-content-center flex-column">
                <p className="destination__sub-heading">Search Your Desired</p>
                <p className="destination__heading">Destination</p>
                <select
                  className="destination__select mb-4"
                  name="isRelocating"
                  onChange={this.changePreference}
                  value={this.state.isRelocating}
                >
                  <option value="">
                    Relocating or Viewing city/state details?
                  </option>
                  <option value={false}>View details</option>
                  <option value={true}>Relocating</option>
                </select>

                {isRelocating === true ? (
                  <div>
                    <select
                      className="destination__select my-4"
                      name="filterBy"
                      onChange={this.change}
                      value={this.state.filterBy}
                    >
                      <option value="state">State</option>
                      <option value="city">City</option>
                    </select>
                    <select
                      className="destination__select my-4"
                      name="to"
                      onChange={this.change}
                      value={this.state.to.name}
                    >
                      <option className="disabled active">
                        Where you want to relocate?
                      </option>
                      {this.renderOptions()}
                    </select>
                    <select
                      className="destination__select mb-4"
                      name="from"
                      onChange={this.change}
                      value={this.state.from.name}
                    >
                      <option className="disabled active">
                        Where are you currently located right now?
                      </option>
                      {this.renderOptions()}
                    </select>
                  </div>
                ) : isRelocating === false ? (
                  <div>
                    <select
                      className="destination__select my-4"
                      name="filterBy"
                      onChange={this.change}
                      value={this.state.filterBy}
                    >
                      <option value="state">State</option>
                      <option value="city">City</option>
                    </select>
                    <select
                      className="destination__select mb-4"
                      name="to"
                      onChange={this.change}
                      value={this.state.to.name}
                    >
                      <option className="disabled active">
                        Which location do you want to view?
                      </option>
                      {this.renderOptions()}
                    </select>
                  </div>
                ) : null}

                <button
                  className="destination__btn"
                  type="submit"
                  onClick={this.submit}
                >
                  Search Now!
                </button>
              </div>
            </section>
          </div>
          <BannerSlider />
          {/*<ConfirmationModal*/}
          {/*    modal={this.state.modal}*/}
          {/*    text="Are you sure you want to logout ?"*/}
          {/*    onClickNo={() => this.setState({modal: false}) }*/}
          {/*    onClickYes={() => {*/}
          {/*      this.setState({modal: false})*/}
          {/*      this.logout();*/}
          {/*    }}*/}
          {/*/>*/}
        </div>
        <section className="container lp-about-us">
          <div className="row">
            <div className="col-12 col-lg-5 d-flex align-items-center">
              <div className="py-5 lp-about-us-content">
                <h3 className="sub-title-heading">About us</h3>
                <h3 className="lp-primary-heading">
                  Dynamic <br /> Living LLC{" "}
                </h3>
                <p>Hi and welcome to Dynamic Living!</p>
                <p>
                  Headquartered in Fredericksburg, Virginia, Dynamic Living is
                  an online platform that aims to be a one-stop solution for all
                  your moving needs and requirements.My name is Roody Labaze,
                  the founder and CEO of Dynamic Living. As a child, I
                  movedaround quite frequently and never really stayed in a
                  place longer than a year. Every time we moved, I had to
                  re-embrace new friends, a new neighborhood, and a new school.
                  This was a lifestyle I had come to accept. Years later, when I
                  was operating as a licensed insurance agent, I realized just
                  how many people were also dealing with the struggle and
                  complexity of moving and relocating. I decided to help.I
                  started Dynamic Living LLC to ease the process of moving for
                  every individual and their family going forward.
                </p>
                <p>
                  <Link to="/about-us">
                    <b>READ MORE</b>
                  </Link>
                </p>
                <img src={signature} alt="signature" />
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <div className="position-relative lp-about-us-img">
                <img className="w-100" src={aboutImg} alt="img" />
                <div className="quote">
                  <p className="m-0">“Live, Move, & Breathe Dynamically”</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid lp-our-vision">
          <div className="row">
            <div className="col-12 col-lg-6 pl-0">
              <div className="lp-our-vision-img position-relative">
                <img
                  className="w-100 vision-img"
                  src={ourVision}
                  alt="vision"
                />
                <button data-toggle="modal" data-target="#videoModal">
                  <img src={playIcon} alt="vision" />
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="lp-our-vision-content">
                {/* <h3 className="sub-title-heading text-white">Neque porro quisquam est</h3> */}
                <h3 className="lp-primary-heading text-white">
                  Our Vision <br /> and Values
                </h3>
                <p>
                  At Dynamic Living, we envision providing our customers with
                  customized services that will help them resonate moving with a
                  memorable experience. We intend to make the whole process of
                  moving stress-free, making life easier for everybody!
                </p>
                <p>
                  The core{" "}
                  <b>
                    <i>values</i>
                  </b>{" "}
                  of Living Dynamic are:
                </p>
                <ul className="d-flex flex-column">
                  <li> Integrity & Accountability</li>
                  <li> Teamwork</li>
                  <li> Quality & Trust</li>
                </ul>
                <p>
                  We acknowledge that moving from one place to another can be
                  hard and stressful, so let Dynamic Living become your trusted
                  partner in helping you move peacefully.
                </p>
                {/* <button className="btn btn-primary">Read More</button> */}
              </div>
            </div>
          </div>
        </section>

        <section className="lp-services">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="lp-primary-heading text-center mb-5">
                  Our Services
                </h3>
              </div>
            </div>

            <div className="row lp-services-row">
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="lp-services-row-content">
                  <h3 className="lp-services-heading">DMV Resources</h3>
                  <p>
                    Whether you're relocating across town, across the state, or
                    even internationally, we're the moving company for you. We
                    understand that every household move is unique; therefore,
                    we attempt to offer a wide range of moving resources to help
                    you have a more individualized experience. Our DMV Resources
                    give you access to needful moving things to create a
                    tailored plan that will match your budget, time frame, and
                    specific moving requirements.
                  </p>
                  {isGuest !== "guest" ? (
                    <Link
                      to="/license-change"
                      className="btn btn-dark text-uppercase"
                    >
                      Learn More
                    </Link>
                  ) : (
                    <Link
                      // to="/license-change"
                      to="/"
                      className="btn btn-dark text-uppercase"
                    >
                      Please sign up to learn more
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="lp-services-row-img w-100 position-relative">
                  <img className="w-100" src={dvm} alt="dvm" />
                </div>
              </div>
            </div>
            <div className="row lp-services-row">
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="lp-services-row-content">
                  <h3 className="lp-services-heading">Realtors</h3>
                  <p>
                    We offer Full Moving Services to Realtors, allowing your
                    customers to plan and accelerate their long-distance or
                    local moves more conveniently and affordably. Our relocation
                    specialist team helps clients easily and proficiently
                    streamline the moving processes and reduce stress by saving
                    time and energy.
                  </p>
                  <Link to="/realtors/" className="btn btn-dark text-uppercase">
                    Learn More
                  </Link>
                </div> 
              </div>
              <div className="col-12 col-lg-6">
                <div className="lp-services-row-img w-100 position-relative">
                  <img className="w-100" src={relator} alt="dvm" />
                </div>
              </div>
            </div>
            <div className="row lp-services-row">
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="lp-services-row-content">
                  <h3 className="lp-services-heading">Partner With Us</h3>
                  <p>
                    Partner with us to explore and experience what stress-free
                    moving feels like. Our objective with each realtor
                    partnership is to provide value for you, your customers, and
                    your organization.{" "}
                    <b>
                      <i>Let's experience hassle-free moving!</i>
                    </b>
                  </p>
                  <Link
                    to="/partner-with-us"
                    className="btn btn-dark text-uppercase"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="lp-services-row-img w-100 position-relative">
                  <img className="w-100" src={partner} alt="dvm" />
                </div>
              </div>
            </div>
            <div className="row lp-services-row">
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="lp-services-row-content">
                  <h3 className="lp-services-heading">Resources</h3>
                  <p>
                    Are you planning to move? Then plan ahead of it with us. We
                    make moving to a new location or state manageable. Our team
                    helps you source crucial statistics of available
                    universities, hospitals, local stores, hotels, and much more
                    on a city and state-wide basis.
                  </p>

                  {isGuest !== "guest" ? (
                    <Link
                      to="/junk-removal-companies"
                      className="btn btn-dark text-uppercase"
                    >
                      Learn More
                    </Link>
                  ) : (
                    <Link
                    // to="/junk-removal-companies"

                      to="/"
                      className="btn btn-dark text-uppercase"
                    >
                      Please sign up to learn more
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="lp-services-row-img w-100 position-relative">
                  <img className="w-100" src={statistics} alt="dvm" />
                </div>
              </div>
            </div>
            {/* <div className="row lp-services-row">
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="lp-services-row-content">
                  <h3 className="lp-services-heading">Moving Tips</h3>
                  <p>
                    Get access to the best tips from our movers and make moving
                    a hassle-free event. We provide guidance from packing for a
                    local move-out to a state-wide move-out!
                  </p>
                  <Link
                    to="/moving-companies"
                    className="btn btn-dark text-uppercase"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="lp-services-row-img w-100 position-relative">
                  <img className="w-100" src={movingTip} alt="dvm" />
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section className="lp-how-it-work">
          <div className="d-flex align-items-center flex-column lp-how-it-work-content">
            <h3 className="text-center sub-title-heading ">DYNAMIC LLC</h3>
            <h2 className="text-center lp-primary-heading">How It Works</h2>
            <p className="text-center">
              We are here to make your moving process easy. Book our service,
              and our experts will be there to help you pack, load, and move
              safely.
            </p>
          </div>
          <div className="lp-how-it-work-slider">
            <Slider {...settings}>
              {slides.map((slide, index) => {
                return (
                  <div key={`slide-${index}`}>
                    <div className="banner-slide d-flex justify-content-center align-items-center px-4">
                      <img className="w-100" src={slide} alt="a" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>

        {/*   <section className="lp-newsletter">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 newletter-img">
                  <img className="w-100" src={newsletter} alt="news"/>
                </div>
                <div className="col-12 col-lg-6 lp-newsletter-content d-flex align-items-center">
                  <div className="w-100 pl-0 px-lg-4">
                    <h3 className="lp-primary-heading text-white">Sign up for the Newsletter</h3>
                    <p className="text-white">Sign up for the Real Insight to receive informative articles, local market statistics and helpful information tailored to you. See more.</p>
                    <form>
                      <input type="email" className="rounded-pill" value={this.state.newsletterEmail} onChange={this.onChangeEmail} placeholder="EMAIL ADDRESS ..."/>
                      <button className="btn btn-dark rounded-pill" onClick={this.newsLetterSubmitHandler}>Sign up Now</button>
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </section>*/}
        {/*
          <section className="lp-footer">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                      className="lp-footer__wrapper d-flex justify-content-center align-items-center flex-column"
                      style={{ backgroundImage: `url(${map})` }}
                  >
                    <a href="/">
                    <img className="footer-logo" src={logo} alt="footer-logo" />
                    </a>
                    <div className="lp-footer__contact d-flex flex-column flex-lg-row justify-content-between">
                      <div className="lp-footer__contact__phone">
                        <ImPhoneHangUp color="#000" size={20} />
                        <a href="tel:+1 800 455 4555 & 66">
                          +1 800 455 4555 & 66
                        </a>
                      </div>
                      <div className="lp-footer__contact__mail mt-3 mt-lg-0">
                        <GrMail  size={20} />
                        <a href="mailto:support@dynamicliving.com">
                          support@dynamicliving.com
                        </a>
                      </div>
                    </div>
                    <div className="lp-footer__socials d-flex">
                      <a
                          href="http://www.facebook.com"
                          target="_blank"
                          className="lp-footer__socials__icons mx-2"
                      >
                        <RiFacebookFill color="#fff" size={20} />
                      </a>
                      <a
                          href="http://www.twitter.com"
                          target="_blank"
                          className="lp-footer__socials__icons mx-2"
                      >
                        <AiOutlineTwitter color="#fff" size={20} />
                      </a>
                      <a
                          href="http://www.youtube.com"
                          target="_blank"
                          className="lp-footer__socials__icons mx-2"
                      >
                        <GrYoutube color="#fff" size={20} />
                      </a>
                      <a
                          href="http://www.instagram.com"
                          target="_blank"
                          className="lp-footer__socials__icons mx-2"
                      >
                        <TiSocialInstagram color="#fff" size={20} />
                      </a>
                      <a
                          href="http://www.linkedin.com"
                          target="_blank"
                          className="lp-footer__socials__icons mx-2"
                      >
                        <AiFillLinkedin color="#fff" size={20} />
                      </a>
                    </div>
                    <p className="lp-footer__copyrights text-center">Dynamic Living LLC® All Rights Reserved. ©2021 <a href="/">Dynamicliving.com</a> </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
*/}
        <div
          className="modal fade"
          id="videoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="videoModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"></h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  width="100%"
                  height="500"
                  title="myVideo"
                  src="https://www.youtube.com/embed/tgbNymZ7vqY"
                ></iframe>
              </div>
              {/* <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  golbal: state.golbal,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    landingPageToggle: (bol) => dispatch(landingPageToggle(bol)),
    designation: (designations) => dispatch(designation(designations)),
    logout: () => dispatch(logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));
