import { toast } from "react-toastify";

export const errorToast = msg => {
    toast.error(msg || 'Some thing went wrong try again later!');
};
export const successToast = msg => {
    toast.success(msg || 'Congratulation');
};

export const darkToast = msg => {
    toast.dark(msg || 'Some thing went wrong try again later!', {
        hideProgressBar: true
    });
};
