import React, { Component } from "react";
import {connect} from "react-redux";
import "./SalaryDetails.scss";
import {currency} from "../../../utils/helper";


class SalaryDetails extends Component{


    renderDetails(){
        const {city} = this.props;
        const {salary} = this.props.listing;
        if(!salary || !salary.salary)
            return null;
        return (
            <div className="modal-content__container-info text-white">
                <div className="head bg-primary">
                    <h2 className="modal-header-heading  text-center">Salary about <br/> <span className='font-weight-bold'>"{city}"</span> city</h2>
                </div>
                <div className="px-0 px-md-5 mx-5 my-4 font-secondary">
                    <div className="row ">
                        <div className="col-6 mb-5 key">
                            State Name:
                        </div>
                        <div className="col-6 mb-5">
                            {salary.salary.stateName}
                        </div>
                        <div className="col-6 mb-5 key">
                            Median Annual Wage:
                        </div>
                        <div className="col-6 mb-5">
                            {currency(salary.salary.medianAnnualWage)}
                        </div>
                        <div className="col-6 mb-5 key">
                            Mean Annual Wage:
                        </div>
                        <div className="col-6 mb-5">
                            {currency(salary.salary.meanAnnualWage)}
                        </div>
                        <div className="col-6 mb-5 key">
                            Average Earnings:
                        </div>
                        <div className="col-6 mb-5">
                            {currency(salary.salary.avgEarnings)}

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return  <div className="info-details-modal">
            {  this.renderDetails() }

        </div>;
    }


}


const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});


export default connect(
    mapStateToProps,
    null
)(SalaryDetails);

