import React, { Component } from "react";
import "./Relators.scss";
import RealtorsListing from "../../components/RealtorsListing/RealtorsListing";
import BannerCover from "../../components/BannerCover/BannerCover";
import banner from "../../assets/images/realator/realtor-banner.jpg";
import phone from "../../assets/images/realator/phone.png";
import web from "../../assets/images/realator/web.png";
import marker from "../../assets/images/realator/marker.png";
import email from "../../assets/images/realator/email.png";
import rel1 from "../../assets/images/realator/rel1.png";
import rel2 from "../../assets/images/realator/rel2.png";
import rel3 from "../../assets/images/realator/rel3.png";
import rel4 from "../../assets/images/realator/rel4.png";
import rel5 from "../../assets/images/realator/rel5.png";
import rel6 from "../../assets/images/realator/rel6.png";
import rel7 from "../../assets/images/realator/rel7.png";
import rel8 from "../../assets/images/realator/rel8.png";
import SelectDestination from "../../components/SelectDestination/SelectDestination";
import { getRealtors, resetRealtors } from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

const dummy = [
  {
    name: "Property Bunch",
    img: rel1,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
  {
    name: "Urban Home",
    img: rel2,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
  {
    name: "The Obald Group",
    img: rel3,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
  {
    name: "The Cree Team",
    img: rel4,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
  {
    name: "Equity Financial",
    img: rel5,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
  {
    name: "Hawaii",
    img: rel6,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
  {
    name: "Mark Alen",
    img: rel7,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
  {
    name: "Alpha",
    img: rel8,
    phone: "+1234-5678",
    location: "lorem ipsum, USA",
    email: "info@dynamic.com",
    web: "www.propertybunch.com",
  },
];

class Relators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBy: "",
      name: "",
      submitted : false
    };
  }
  formSubmit = (values) => {
    this.setState({
      filterBy: values.filterBy,
      name: values.destination,
      submitted: true
    });
    setTimeout(() => {
      this.props.getRealtors(values.destination, values.filterBy);
    });
  };

  realtorsListingRender = () => {
    const { listing } = this.props;
    return (
      <>
        {listing?.realtorsListing?.data?.agents ? (
          listing?.realtorsListing?.data?.agents?.map((d, i) => {
            return (
              <div
                key={i}
                className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
              >
                <div className="relator-box">
                  <div className="relator-box-img d-flex justify-content-center align-items-center">
                    <div className="img-box d-flex">
                      <img src={d?.photo?.href} alt="img" />
                    </div>
                  </div>

                  <h2 className="text-truncate">{d.name}</h2>
                  <div className="d-flex relator-box-list">
                    <div className="icon-box">
                      <img src={phone} alt="img" />
                    </div>
                    <a
                      className="text-truncate"
                      href={`tel:${d?.phones[0]?.number}`}
                    >
                      {d?.phones[0]?.number}
                    </a>
                  </div>
                  <div className="d-flex relator-box-list">
                    <div className="icon-box">
                      <img src={marker} alt="img" />
                    </div>
                    <a
                      className="text-truncate"
                      href={`https://www.google.com/maps/search/${d?.address?.line}`}
                      target="_blank"
                    >
                      {d?.address?.line}
                    </a>
                  </div>
                  <div className="d-flex relator-box-list">
                    <div className="icon-box">
                      <img src={email} alt="img" />
                    </div>
                    <a className="text-truncate" href={`mailto:${d.email}`}>
                      {d.email}
                    </a>
                  </div>
                  <div className="d-flex relator-box-list">
                    <div className="icon-box">
                      <img src={web} alt="img" />
                    </div>
                    <a
                      className="text-truncate"
                      href={
                        d?.href
                          ? `${
                              !d?.href.includes("http")
                                ? `https://${d?.href}`
                                : d?.href
                            }`
                          : `${d?.web_url}`
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {d?.href ? d?.href : d?.web_url}
                    </a>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="col-12 text-center w-100"><h3>No Data Found</h3></div>
        )}
      </>
    );
  };

  render() {
    // const { loggedIn } = this.props.auth;
    const { loading } = this.props.listing;
    
    return (
      <main className="bg-white">
        <BannerCover bgImg={banner} title="Realtors" />
        <section className="realtors-listing">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <SelectDestination
                  onClickHandler={(values) => this.formSubmit(values)}
                  className="dvm gray"
                />
              </div>
              {loading ? (
                <Loading className="red-border" />
              ) : this.state.submitted ? (
                this.realtorsListingRender()
              ): null}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  listing: state.listing,
  golbal: state.golbal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getRealtors: (city, filterBy) => dispatch(getRealtors(city, filterBy)),
    resetRealtors: () => dispatch(resetRealtors()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Relators));
