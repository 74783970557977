import Config from "../../config";
import { getToken } from "./tokenUtils";
import { handleFetchResponse } from "./handleFetchResponse";



export const fetchUtil = data => {
    // console.log('data: ', data)
    const { url, method = "GET", body = null, apiUrl = null, showMessage=true } = data;
    let baseUrl = `${Config.API_URL}${url}`;
    const token = getToken();

    let headers = { "Content-Type": "application/json" };
    if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
    }


    if(apiUrl){
        baseUrl = apiUrl;
    }
    return fetch(baseUrl, {
        method,
        headers,
        body,
    }).then((res) => handleFetchResponse(res,showMessage));
};
