import React, { Component } from "react";
import { connect } from "react-redux";
import "./EducationDetails.scss";

class EducationDetails extends Component {
  render() {
    const { id } = this.props;
    const { education } = this.props.listing;
    const edu = education.records.find((data) => data.record.id === id);

    return (
      <div className="text-white">
        <div className="modal-header-heading bg-primary d-flex justify-content-center align-items-center">
          {edu.record.fields.name}
        </div>

        <div className="content-job-details px-0 px-md-5 mx-5 my-4 font-secondary">
          <div className="description-line py-3 row">
            <div className="col-6  key">City:</div>
            <div className="col-6  value">
              {edu.record.fields.city.toLowerCase()}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-6 key">County:</div>
            <div className="col-6 value">
              {edu.record.fields.county.toLowerCase()}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-6 key">Address:</div>
            <div className="col-6 value">
              {edu.record.fields.address.toLowerCase()}
            </div>
          </div>
          <div className="description-line py-3 row">
            <div className="col-6 key">Telephone:</div>
            <div className="col-6 value">
              <a href={`tel:${edu.record.fields.telephone}`}>
                {edu.record.fields.telephone}
              </a>
            </div>
          </div>
          <div className="description-line py-3  row">
            <div className="col-6 key">Enroll:</div>
            <div className="col-6 value">{edu.record.fields.tot_enroll}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center mb-5 ">
          <a
            href={`https:${edu.record.fields.website}`}
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-primary"
          >
            More Details
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listing: state.listing,
  golbal: state.golbal,
});

export default connect(mapStateToProps, null)(EducationDetails);
