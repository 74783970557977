import React, { useEffect, useState } from "react";
import AmericanAirLinesLogo from "../../assets/images/icons/american-logo.png";
import AlaskaLogo from "../../assets/images/icons/alska.png";
import DeltaLogo from "../../assets/images/icons/delta.png";
import Movie from "../../assets/images/icons/movie.png";
import Eye from "../../assets/images/icons/eye.png";
import Flight from "../../assets/images/icons/flight.png";
import Star from "../../assets/images/icons/star.png";
import Alaram from "../../assets/images/icons/alaram.png";
import "./BookFlight.scss";
import "../Journey/Journey.scss";
import { Link, Redirect, useHistory } from "react-router-dom";
import FlightSearchForm from "../../components/FlightSearchForm/FlightSearchForm";
import { useDispatch, useSelector } from "react-redux";
import { getFlights, saveFlightOffers } from "../../store/actions";
import Loading from "../../components/Loading/Loading";
import moment from "moment";

const BookFlight = (props) => {
  const userRole = localStorage.getItem("user");
  const [flightOffers, setFlightOffers] = useState(null);
  const history = useHistory();
  const [filters, setFilters] = useState({
    cheap: true,
    fast: false,
    exp: false,
    sort: false,
  });
  const dispatch = useDispatch();
  const { searchFlight, getFlightLoading, flightListing } = useSelector(
    (storeState) => storeState.flight
  );
  const { loggedIn } = useSelector((storeState) => storeState.auth);

  useEffect(() => {
    const locationDeparture =
      searchFlight.locationDeparture?.address?.cityCode || "";
    const locationArrival =
      searchFlight.locationArrival?.address?.cityCode || "";
    const departure = searchFlight.departure || "";
    if (locationDeparture && locationArrival && departure) {
      dispatch(
        getFlights({
          oneWay: !!searchFlight.oneWay,
          arrival: searchFlight.arrival || null,
          travelClass: searchFlight.travelClass || "ECONOMY",
          departure: searchFlight.departure || "",
          locationDeparture:
            searchFlight.locationDeparture?.address?.cityCode || "",
          locationArrival:
            searchFlight.locationArrival?.address?.cityCode || "",
          adults: searchFlight.adults || 1,
          children: searchFlight.children || 0,
          infants: searchFlight.infants || 0,
          nonStop: !!searchFlight.nonStop,
        })
      );
    }
  }, [searchFlight]);

  const onFiltersChange = (name) => {
    if (name === "cheap") {
      setFilters({
        fast: false,
        exp: false,
        sort: false,
        cheap: true,
      });
    } else if (name === "fast") {
      setFilters({
        fast: true,
        exp: false,
        sort: false,
        cheap: false,
      });
    } else if (name === "exp") {
      setFilters({
        fast: false,
        exp: true,
        sort: false,
        cheap: false,
      });
    } else if (name === "sort") {
      setFilters({
        fast: false,
        exp: false,
        sort: true,
        cheap: false,
      });
    }
  };

  const submitHandler = (flight) => {
    dispatch(saveFlightOffers(flight));
    setFlightOffers(flight);
    history.push("/passenger-details");
    // <Redirect to="/passenger-details" />;
  };

  const guestLogout = () => {
    const remember = localStorage.getItem("__dynamic_remember");
    localStorage.clear();
    if (remember) {
      localStorage.setItem("__dynamic_remember", remember);
    }
    const user = localStorage.getItem("user");
    if (!user) {
      // history.push("/account/login");
      history.go(0); // Reload the current page
    }
  };

  if (!loggedIn) return <Redirect to="/account/login" />;

  // if (flightOffers)
  //   return <Redirect to="/passenger-details" />;

  return (
    <section className="book-flight-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="book-flight">
              <FlightSearchForm
                isJourney={false}
                className="background-white mb-5"
                onSubmit={submitHandler}
              />

              {/* <div className="filters w-100 d-flex flex-md-row flex-column justify-content-md-start align-items-center justify-content-center my-5">
                <button type="button" className={`${filters.cheap ? "selected" : ""} d-flex justify-conten-start align-items-center w-md-25 w-100 p-4`} onClick={() => onFiltersChange("cheap")}>
                  <span className="bg-icons mr-3">
                    <img src={Eye} alt="No icons" />
                  </span>

                  <span className="d-flex flex-column">
                    <span className="m-0 font-secondary f-13">Cheapest</span>
                    <span className="m-0 font-secondary f-20 text-black">$350</span>
                  </span>
                </button>
                <button type="button" className={`${filters.fast ? "selected" : ""} d-flex justify-content-start align-items-center w-md-25 w-100 p-4`} onClick={() => onFiltersChange("fast")}>
                  <span className="bg-icons mr-3">
                    <img src={Flight} alt="No icons" />
                  </span>

                  <span className="d-flex flex-column">
                    <span className="m-0 font-secondary f-13">Fastest</span>
                    <p className="m-0 font-secondary f-20 text-black">$350</p>
                  </span>
                </button>
                <button type="button" className={`${filters.exp ? "selected" : ""} d-flex justify-content-start align-items-center w-md-25 w-100 p-4`} onClick={() => onFiltersChange("exp")}>
                  <span className="bg-icons mr-3">
                    <img src={Star} alt="No icons" />
                  </span>

                  <span className="d-flex flex-column align-items-start">
                    <span className="m-0 font-secondary f-13">Best Experience</span>
                    <span className="m-0 font-secondary f-20 text-black">$385</span>
                  </span>
                </button>
                <button type="button" className={`${filters.sort ? "selected" : ""} d-flex justify-content-start align-items-center w-md-25 w-100 p-4`} onClick={() => onFiltersChange("sort")}>
                  <span className="bg-icons mr-3">
                    <img src={Alaram} alt="No icons" />
                  </span>

                  <span className="d-flex flex-column align-items-start">
                    <span className="m-0 font-secondary f-13 text-left">Sort by Flight</span>
                    <span className="m-0 font-secondary f-20 text-black">Time</span>
                  </span>
                </button>
              </div> */}

              {flightListing.responses?.length && !getFlightLoading ? (
                <>
                  {/*<div className="d-flex justify-content-between align-items-center w-100 card-header px-5">
                       <div className="w-40 flex-grow-1">
                         <p className="font-15 m-0 text-white">
                           Book Hassle Free || Book On Booking.com
                         </p>
                         <p className="font-15 m-0 text-white">
                           Book with peace of mind. No Payment Surcharges. 24x7
                           Customer Care
                         </p>
                       </div>

                        <div className="w-60 text-align-right">
                         <div className="btn btn-primary hover rounded-pill sponserd">
                           Sponsored
                         </div>
                       </div>
                     </div>*/}
                  {flightListing.responses.map((r, index) => {
                    return (
                      <div className="w-100 ticket-card mb-5" key={index}>
                        {/*<div className="d-flex justify-center align-items-center tag">
                                 <div className="mr-3">
                                   <img src={Movie} />
                                 </div>
                                 <div className="text-primary">Refundable</div>
                               </div>*/}
                        <div className="row p-5">
                          <div className="col-9">
                            {r?.itineraries.map((ri) => {
                              return ri?.segments.map((s, idx) => {
                                return (
                                  <div
                                    className="row mb-5 flight-card-con"
                                    key={idx}
                                  >
                                    <div className="col-4 d-flex justify-content-center align-items-center flight-card-left">
                                      {/*<img src={AmericanAirLinesLogo} alt="logo" />*/}
                                      {
                                        flightListing?.dictionaries?.carriers[
                                          s?.carrierCode
                                        ]
                                      }
                                    </div>
                                    <div className="col-8">
                                      <div className="d-flex justify-content-between align-items-end mb-2">
                                        <p className="m-0 f-13 font-secondary d-flex flex-column justify-content-center align-items-center">
                                          <span>
                                            {moment(s.departure.at).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </span>
                                          <span>
                                            {moment(s.departure.at).format(
                                              "HH MM"
                                            )}
                                          </span>
                                        </p>
                                        <p className="m-0 d-flex flex-column">
                                          <span className="m-0 text-center f-13 font-secondary">
                                            Total
                                          </span>
                                          <span className="m-0 f-13 font-secondary">
                                            {s.duration}
                                          </span>
                                        </p>
                                        <p className="m-0 f-13 font-secondary d-flex flex-column justify-content-center align-items-center">
                                          <span>
                                            {moment(s.departure.at).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            {moment(s.arrival.at).format(
                                              "HH MM"
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="line-bar"></div>
                                      <div className="d-flex justify-content-between align-items-start mt-2 flight-details-locations">
                                        <p className="m-0 f-13 font-secondary">
                                          {s.departure.iataCode}
                                        </p>
                                        <p className="m-0 f-13 font-secondary">
                                          Direct Flight
                                        </p>
                                        <p className="m-0 f-13 font-secondary">
                                          {s.arrival.iataCode}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              });
                            })}
                          </div>

                          <div className="col-3">
                            <div className="selection-box text-center py-5">
                              <p className="font-secondary font-15 m-0">
                                Booking
                              </p>
                              <h2 className="text-black f-20 font-secondary">
                                $ {r?.price?.total}
                              </h2>
                              <p className="font-secondary font-15">
                                Total price
                              </p>

                              {userRole != "guest" ? (
                                <button
                                  onClick={() => submitHandler(r)}
                                  className="btn btn-primary   rounded-pill"
                                >
                                  Select
                                </button>
                              ) : (
                                <button
                                  onClick={() => guestLogout()}
                                  className="btn btn-primary   rounded-pill"
                                >
                                  Select
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              {!flightListing.responses &&
              !flightListing.responses?.length &&
              !getFlightLoading ? (
                <div className="py-5 text-center">No flight Found.</div>
              ) : null}
              {getFlightLoading ? <Loading className="black" /> : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookFlight;
