import React, { Component } from "react";
import { connect } from "react-redux";

class JobDetails extends Component {
  render() {
    const { listing, id } = this.props;
    const { jobs } = listing;
    // console.log("THE JOB DETAILS JOBS: ", jobs);
    const selectedJob = jobs && jobs.jobs.find((data) => data.id === id);
    // const JobDetails = findJob.MatchedObjectDescriptor;
    return (
      <div className="modal-content__container-job text-white">
        <div className="modal-header text-center bg-primary border-0 py-4 text-white d-flex justify-content-center align-items-center font-secondary font-weight-bold">
          Job Description
        </div>
        <div className="content-job-details px-0 px-md-5 mx-0 mx-md-5 my-4 font-secondary">
          <div className="description-line row mb-4">
            <div className="col-12 col-md-2 key f-20">Job Title:</div>
            <div className="col-12 col-md-9 f-20">{selectedJob.title}</div>
          </div>
          <div className="description-line row mb-4 mt-3">
            <div className="col-12 col-md-2 key f-20">Organization:</div>
            <div className="col-12 col-md-9 f-20">{selectedJob.company}</div>
          </div>
          {selectedJob.type ? (
            <div className="description-line row mb-4 mt-3">
              <div className="col-12 col-md-2 key f-20">Job type:</div>
              <div className="col-12 col-md-9 f-20">{selectedJob.type}</div>
            </div>
          ) : null}

          <div className="description-line row mb-4 mt-3">
            <div className="col-12 col-md-2 key f-20">Location:</div>
            <div className="col-12 col-md-9 f-20">{selectedJob.location}</div>
          </div>
          <div className="description-line row mb-4 mt-3">
            <div className="col-12 col-md-2 key f-20">Job source:</div>
            <div className="col-12 col-md-9 f-20">{selectedJob.source}</div>
          </div>
          <div className="description-line row mt-4">
            <div className="col-12 mb-3 key f-22">Qualification Summary:</div>
            <div className="col-12 f-18">{selectedJob.snippet}</div>
          </div>

          <div className="description-line row mt-4">
            <div className="col-12 mb-3 key f-18">
              For futher details, click on the link below:
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <a
              className="btn btn-primary"
              href={selectedJob.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply Now!
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listing: state.listing,
  golbal: state.golbal,
});

export default connect(mapStateToProps, null)(JobDetails);
