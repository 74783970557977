import React, { Component } from "react";
import "./TermsAndConditon.scss";

class TermsAndCondition extends Component {
  render() {
    return (
      <div className="home terms-conditon">
        <div className="container">
          <div className="row">
            <div className="col-12 my-5 py-5  terms-and-condition">
              <h1 className="primary-heading mb-5">
                {" "}
                DYNAMIC LIVING – TERMS & CONDITIONS
              </h1>
              <p>
                Welcome to DYNAMIC LIVING, an online platform that aims to be a
                one-stop solution for all your moving needs and requirements.
              </p>
              <h2>1. GENERAL</h2>
              <p>
                The current Terms of Service ("Terms") apply to both you (the
                "User") and DYNAMIC LIVING ("Company," "we," or "us"). Its
                purpose is to keep track of and monitor your access to and usage
                of https://www.dynamicliving.com/ (the "Website"), whether
                you're a regular member or use our network in any other
                manner.Any of the following phrases and words such as solitary,
                multiplication, uppercase, and/or he/she or they are linked to a
                set and hence pertain to the same issue.
              </p>
              <blockquote>
                PLEASE NOTE: We reserve the right, at our sole discretion, to
                change, modify or otherwise alter these Terms and Conditions at
                any time. Unless otherwise indicated,
              </blockquote>
              <h2>2. CONSENT </h2>
              <p>
                Please read the "Terms and Conditions" carefully before
                browsing, accessing, consuming, or subscribing to the Website.
                By accessing the Website, you agree to abide by the
                https://www.dynamicliving.com/ Terms & Conditions. If you do not
                consent to or abide by the following terms & Conditions listed
                on this webpage, please refrain from using this Service/Website.
              </p>
              <h2>3. INFORMATION COLLECTION & USAGE</h2>
              <p>
                Visitors can view publicly available information without
                disclosing any private information. Certain services, on the
                other hand, may require the consumer to provide sensitive
                information. We use the information you provide to optimize our
                support and maintenance, such as storing data and improvements,
                refining our assistance and Website, and informing you of offers
                and discounts.
              </p>
              <h2>4. LOGIN/REGISTRATION</h2>
              <p>
                The user should first fill out a form up/login/submit private
                details online to utilize our Services. We'll need your
                username, telephone number, current address, and personal email
                to finalize your registration or make an account.
              </p>
              <h2>5. TERMINATION OF REGISTRATION</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>{" "}
                has the right to restrict or revoke a user's account or seize
                service and assistance at any moment if the Company's rights are
                violated.
              </p>
              <p>
                B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES,
                INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE
                FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE
              </p>
              <h2>6. DATA PRIVACY</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "}
                ensures that all information given is kept private. We never
                utilize or abuse your private information, specifically Personal
                Data. We do not expose or transfer private data to other
                companies, organizations, or persons in accordance with the law.
              </p>
              <h2>7. SECURITY</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} places a high value on the integrity of confidentiality,
                authentication, and data provided to us. We make efforts to
                prevent unauthorized entry to or use of your Private
                Information. However, we cannot ensure the security of any
                Private Data you (the user) post, send, or expose over our
                Website or email.
              </p>
              <p>
                The framing, mirroring, scraping or data mining of the Site or
                any of its content in any form and by any method is expressly
                prohibited.
              </p>
              <h2>6. UNAUTHORIZED USE OF INFORMATION</h2>
              <p>
                Without the previous written consent of the organization, users
                may not duplicate, edit, broadcast, broadcast, share, license,
                trade, generate, or redistribute any of the information,
                materials, services, products, or assets accessible on this
                Website.
              </p>
              <h2>8. THIRD-PARTY LINKS</h2>
              <p>
                Some of the information, items, and services featured on our
                Website may contain third-party links.{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "}, on the other hand, makes no claims or guarantees and
                accepts no accountability for third-party materials,
                commodities, or services.{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} does not respond to third-party feedback, complaints,
                issues, or questions. They need to be addressed to a third
                party.
              </p>
              <h2>9. COOKIES</h2>
              <p>
                By using or viewing the{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} website, you consent to the use of cookies in accordance
                with the{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} Terms and Conditions.Cookies are used on our webpage to
                enhance the authenticity of some sections. Cookies may be used
                in some affiliate/advertising connections
              </p>
              <h2>10. LICENSE</h2>
              <p>
                All trademarks, services, and goods on , including content and
                other material forms, are trademarked by DYNAMIC LIVING and/or
                its licensors unless otherwise stated in the contract.{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>{" "}
                owns all trademarks, and they may not be used until all of the{" "}
                's restrictions are satisfied.You may only get material from{" "}
                {"  "} for private use, subject to the limitations imposed by
                these contract terms.
                {"\n"}
                <p className="my-3">
                  <b>You should not:</b>
                </p>
                {"\n"}
                <ul className="d-flex flex-column dots">
                  <li>
                    Resell services from{" "}
                    <b>
                      <i>DYNAMIC LIVING</i>
                    </b>
                    {"  "}{" "}
                  </li>
                  <li>
                    Lease or rent out services from{" "}
                    <b>
                      <i>DYNAMIC LIVING</i>
                    </b>
                    {"  "} .
                  </li>
                  <li>
                    Duplicate products/services/assets from{" "}
                    <b>
                      <i>DYNAMIC LIVING</i>
                    </b>
                    {"  "} .
                  </li>
                  <li>
                    Rebrand and sell services from
                    <b>
                      <i>DYNAMIC LIVING</i>
                    </b>
                    {"  "}{" "}
                  </li>
                </ul>
              </p>
              <h2>11. RESOLVING DISPUTES</h2>
              <p>
                Any dispute or conflict that arises between a user and{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} . due to these Terms of Service, the Website, or any
                management entity (a "Claim") will be addressed using one of the
                processes outlined below.We strongly suggest you file a formal
                appeal in order to achieve an agreement. If a party desires to
                go to arbitration, one must do so through an agreed-upon
                authorized alternative dispute resolution ("ADR") provider. Both
                the ADR supplier and the clients must adhere to the following
                rules:
                <ul className="d-flex flex-column dots">
                  <li>
                    The investigation will be reached via phone, internet, or
                    handwritten submissions, depending on the process preferred
                    by the individual seeking arbitration.
                  </li>
                  <li>
                    The arbitrator will not require the parties or witness
                    accounts to attend in person unless the parties specifically
                    stipulate so.
                  </li>
                  <li>The case will be decided by a single arbitrator; and</li>
                  <li>
                    Any government department has the authority to make a
                    decision based on the arbitrator's decision.
                  </li>
                </ul>
              </p>
              <h2>12. INDEMNITY </h2>
              <p>
                You agree to indemnify{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} , its legislators, industry partners, buyers,
                forefathers, executive officers, supervisors, consultants,
                telecommunications companies, resellers, and lawmakers from any
                claim or request emerging from or related to any insight you
                have sent, publicized, or presented direct exposure to, whether
                implicitly or explicitly, including lawyer expenditures,
                judicial fees, and other accredited spending and payables.
              </p>
              <h2>13. DISCLAIMER</h2>
              <p>
                To the fullest degree authorized by law,{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} excludes certain promises, declarations, and warranties
                relating to our Website, including its usage. Nothing declared
                in this section should be construed as indicating that:
                <ul className="d-flex flex-column dots">
                  <li>
                    Reduce or eliminate our or your liability in the case of
                    death or physical injury.
                  </li>
                  <li>
                    Reducing or removing our or your responsibility in the event
                    of a forgery or a legal warranty violation;
                  </li>
                  <li>
                    Restrict our or your responsibility in any way not permitted
                    by law; or
                  </li>
                  <li>
                    Remove any duties you or we have that aren't specifically
                    forbidden by law.
                  </li>
                </ul>
                The liability limitations and exemptions outlined in this
                section and elsewhere in this declaration: (a) apply to the
                first paragraph; and (b) apply to all obligations arising out of
                the allegation, including those arising out of contractual,
                negligent, or statutory obligation breaches.As long as the
                Website, its content, and services are given for free,{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} will not be responsible for any loss or injuries of any
                sort.
              </p>
              <h2>14. Right To Change Terms</h2>
              <p>
                The{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} Service is governed by our Terms and Conditions, which
                contain a list of relevant legislation, as well as our User
                Agreement.{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} has the right to alter, change, or modify the Policy at
                any time. Any adjustments will be applied as soon as{" "}
                <b>
                  <i>DYNAMIC LIVING</i>
                </b>
                {"  "} considers them necessary.We highly advise that you
                examine our Policy on a regular basis so that you are aware of
                and can comply with any revisions we make.
              </p>
              <h2>15. CONTACT US</h2>
              <p>
                If you have any unresolved issues concerning our{" "}
                <b>Terms & Conditions</b>, please email our support
                professionals at
                <a href="mailto:support@dynamicliving.com">
                  support@dynamicliving.com
                </a>
                , and we will address them as soon as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndCondition;
