import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCompaniesListing,
  resetCompaniesListing,
} from "../../store/actions";
import Loading from "../../components/Loading/Loading";
import SelectDestination from "../../components/SelectDestination/SelectDestination";
import "./CompaniesListing.scss";
import Rating from "../Rating/Rating";
import { AiOutlineGlobal, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/all";
class CompaniesListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      limit: 30,
      page: 1,
    };
  }

  componentWillUnmount() {
    this.props.resetCompaniesListing();
  }

  formSubmit = (values) => {
    this.setState({ values, page: 1 });
    const { page, limit } = this.state;

    setTimeout(() => {
      this.props.getCompaniesListing(
        this.props.type,
        values.filterBy,
        values.destination,
        page,
        limit
      );

    });
  };

  loadMore = () => {
    const { companiesListing } = this.props.listing;
    if (!companiesListing) return null;

    let { page, limit, values } = this.state;
    console.log("companiesListing", companiesListing)
    page++;
    this.setState({ page });
    this.props.getCompaniesListing(
      this.props.type,
      values?.filterBy,
      values?.destination,
      page,
      limit,
      companiesListing?.curser
    );
  };

  renderListing() {
    const { companiesListing } = this.props.listing;
    const listing = companiesListing?.businesses;
    if (!listing) return null;

    return listing?.map((item, i) => {
      return (
        <div className="col-12 col-lg-6" key={`company-${i}`}>
          <div className="row mt-4 company-row">
            <div className="col-12 col-md-6 col-lg-4 company-images">
              {item.image_url ? (
                <div
                  className="company-img"
                  style={{ backgroundImage: "url(" + item.image_url + ")" }}
                ></div>
              ) : (
                <div className="company-img d-flex justify-content-between align-items-center">
                  <span className="company-name text-center"> {item.name}</span>
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-8 py-3">
              <h2 className="text-white text-truncate">{item.name}</h2>
              <Rating rating={item.rating} />

              <div className="d-flex align-items-center mb-2">
                <FaPhoneAlt size={14} />
                <a className="ml-3" href={`tel:${item.phone}`}>
                  {item.display_phone}
                </a>
              </div>

              <div className="d-flex align-items-center mb-2">
                <FaMapMarkerAlt size={14} />
                <span className="ml-3 ">{item?.location?.display_address}</span>
              </div>

              <div className="d-flex align-items-center">
                <AiOutlineGlobal size={14} />
                <a className="ml-3" target="_blank" href={item.url}>
                  View Website
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  renderLoadMoreBtn() {
    const { companiesListing, loading } = this.props.listing;
    const listing = companiesListing?.businesses;
    if (!listing) return null;

    const load = listing.length < companiesListing.total;

    return load && !loading ? (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <button className="btn btn-primary" onClick={this.loadMore}>
          Load More
        </button>
      </div>
    ) : loading ? (
      <Loading />
    ) : null;
  }

  render() {
    const { loggedIn } = this.props.auth;
    const { loading } = this.props.listing;
    if (!loggedIn) return <Redirect to="/account/login" />;

    const { companiesListing } = this.props.listing;
    const listing = companiesListing?.businesses;

    return (
      <div className="companies-listing">
        <div className="container list-change__container">
          <div className="row">
            <div className="col-12 moving-companies-wrapper">
              <div className="form d-flex justify-content-center">
                <SelectDestination
                  onClickHandler={(values) => this.formSubmit(values)}
                  className="dvm"
                />
              </div>
              <div className="row pb-4">
                {loading && !listing ? <Loading /> : this.renderListing()}
              </div>
              {(companiesListing?.businesses && companiesListing?.hasNext) ? this.renderLoadMoreBtn() : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  listing: state.listing,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCompaniesListing: (type, filterBy, name, page, limit, cursor) =>
      dispatch(getCompaniesListing(type, filterBy, name, page, limit, cursor )),
    resetCompaniesListing: () => dispatch(resetCompaniesListing()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesListing);
