import React, { Component } from "react";
import "./Loading.scss";
class Loading extends Component {
    render() {
        return (
            <div className={`loading ${this.props.className}`}>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
        );
    }
}
export default Loading;
