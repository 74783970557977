import React, { Component } from "react";
import {leftPanelToggle} from "../../store/actions";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import "./SidePanel.scss";


class SidePanel extends Component{
    constructor(props) {
        super(props);
        this.leftPanelToggle = this.leftPanelToggle.bind(this);
    }


    leftPanelToggle(){
        let {leftPanelLeft} = this.props.listing;
        this.props.leftPanelToggle(!leftPanelLeft);
    }

    render() {
        return (
            <div className="left-panel">
                <div className="left-panel-container position-relative">
                    <div className="left-panel-content fancy-scroll p-0">
                        {
                            this.props.children
                        }
                    </div>
                    <NavLink to="/home"   className="panel-toggle-btn" >
                        <span className="up"></span>
                        <span className="center"></span>
                        <span className="down"></span>
                    </NavLink>
                </div>
            </div>
        );
    }

}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        leftPanelToggle: (bol) => dispatch(leftPanelToggle(bol))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidePanel);

