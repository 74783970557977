import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import "./VehicleRegistrationChange.scss";
import Dvm from "../../components/Dvm/Dvm";

class VehicleRegistrationChange extends Component {
    render() {
        const { loggedIn } = this.props.auth;
        if (!loggedIn )
            return <Redirect to="/account/login" />;
        return (
            <Dvm/>
        );
    }
}



const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    null
)(VehicleRegistrationChange);
