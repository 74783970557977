import React, { Component } from "react";


import "./SocialLinks.scss";

class SocialLinks extends Component {
    render() {
        return (
           <aside className="social-links d-flex align-items-end justify-content-center">
                <ul>
                    <li><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><i className="icon-fb"></i></a></li>
                    <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="icon-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="icon-instagram"></i></a></li>
                    <li><a href="https://plus.google.com/" target="_blank" rel="noopener noreferrer"><i className="icon-google-plus"></i></a></li>
                    <li className="text-uppercase follow-us"><span>Follow Us:</span></li>
                </ul>
           </aside>
        );
    }
}



export default SocialLinks;
