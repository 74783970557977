import React, { Component } from "react";
import "./Home.scss";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";

import GoogleMap from "../../components/Map/GoogleMap";
import Crime from "./Crime/Crime";
import Info from "./Info/Info";
import Move from "./Move/Move";
import Weather from "./Weather/Weather";
import History from "./History/History";
import Education from "./Education/Education";
import Hotels from "./Hotels/Hotels";
import Jobs from "./Jobs/Jobs";
import Salary from "./Salary/Salary";
import {designationFormHide} from "../../store/actions";
import Hospital from "./Hospital/Hospital";
import Entertainments from "./Entertainments/Entertainments";

class Home extends Component {

    componentDidMount() {
        const {location} = this.props;
        this.checkDesignationForm(location)
    }

    componentWillUnmount() {
        this.props.designationFormHide(true);
    }

    componentDidUpdate(nextProps,) {
        const {location} = nextProps.history;
        this.checkDesignationForm(location)
    }

    checkDesignationForm = (location) =>{
        const designationFormHide = this.props.golbal.designationFormHide;
        const params = location.search
        if(params){
            if(!designationFormHide){
                this.props.designationFormHide(true);
            }
        }
        else {
            if(designationFormHide){
                this.props.designationFormHide(false);
            }
        }
    }

    render() {
        const { loggedIn } = this.props.auth;
        if (!loggedIn )
            return <Redirect to="/account/login" />;

        return (
            <div className="home">
                <div>
                    <Switch>
                        <Route exact path="/home/crime" name="crime" component={Crime} />
                        <Route exact path="/home/info" name="info" component={Info} />
                        <Route exact path="/home/move" name="move" component={Move} />
                        <Route exact path="/home/weather" name="weather" component={Weather} />
                        <Route exact path="/home/history" name="history" component={History} />
                        <Route exact path="/home/education" name="education" component={Education} />
                        <Route exact path="/home/hotels" name="hotels" component={Hotels} />
                        <Route exact path="/home/jobs" name="jobs" component={Jobs} />
                        <Route exact path="/home/salary" name="salary" component={Salary} />
                        <Route exact path="/home/hospital" name="hospital" component={Hospital} />
                        <Route exact path="/home/entertainments" name="hospital" component={Entertainments} />
                    </Switch>
                </div>
                <GoogleMap/>
            </div>
        );
    }
}


const mapStateToProps = state =>({
    auth: state.auth,
    golbal: state.golbal,
});


const mapDispatchToProps = dispatch => {
    return {
        designationFormHide: (bol) => dispatch(designationFormHide(bol)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
