import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import "./Account.scss";
import {ForgotPassword, Login, Registration, SetPassword} from "./index";
import {connect} from "react-redux";

class Account extends Component {
    render() {
        const { loggedIn } = this.props.auth;
        if (loggedIn)
            return <Redirect to="/home" />;

        return (
            <div className="account">
                <div className="container">
                    <div className="row">
                        <div className="account__container mx-auto col-12 col-md-6 d-flex justify-content-center align-items-center">
                            <Switch>
                                <Route exact path="/account/registration" name="registration" component={Registration} />
                                <Route exact path="/account/login" name="login" component={Login}  />
                                <Route exact path="/account/forgot-password" name="forgot-password" component={ForgotPassword}   />
                                <Route exact path="/account/reset-password/:refreshToken" name="set-password" component={SetPassword} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state =>({
    auth: state.auth,
});




export default connect(
    mapStateToProps,
    null
)(Account);
