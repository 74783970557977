import React, { Component } from "react";
import "./Education.scss";
import { getEducation } from "../../../store/actions";
import { connect } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import {
  getQueryString,
  getFilterBY,
  sortByAsc,
  sortByDes, 
} from "../../../utils/helper";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import EducationDetails from "./EducationDetails";
import { Pagination } from "antd";

class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      detailsId: null,
      filterBy: "",
      name: "",
      sortBy: null,
      currentPage: 1,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.openedDetails = this.openedDetails.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    console.log('edu::::', location)
    let filterBy = "",
      name = "";
    if (location.search) {
      filterBy = getFilterBY(location.search);
      name = getQueryString(location.search, filterBy);
    }
    if (name) {
      this.props.getEducation(name);
    }
    this.setState({
      filterBy,
      name,
    });
  }

  componentDidUpdate(nextProps) {
    const { location } = nextProps.history;
    const { name } = this.state;
    let nextName = null;
    let filterBy = "";
    if (location.search) {
      filterBy = getFilterBY(location.search);
      nextName = getQueryString(location.search, filterBy);
    }
    if (nextName && name !== nextName) {
      this.props.getEducation(nextName);
      this.setState({
        filterBy,
        name: nextName,
      });
    }
  }

  toggleModal() {
    const modalOpened = this.state.modalOpened;
    this.setState({
      modalOpened: !modalOpened,
    });
  }

  filter(order) {
    this.setState({
      sortBy: order,
    });
  }

  pageChange(e) {
    this.props.getEducation(this.state.name, e);

    this.setState({
      currentPage: e,
    });
  }

  openedDetails(id) {
    this.setState({
      detailsId: id,
    });
    this.toggleModal();
  }

  listingRender() {
    const { listing } = this.props;
    const education = listing.education;
    if (education && education.records && education.records.length) {
      return <ul className="bullet">{this.educationMap()}</ul>;
    }
    return (
      <div className="p-5 text-white text-center">
        {listing.education?.error
          ? listing.education?.error
          : " Not Education found data"}
      </div>
    );
  }

  educationMap() {
    const { education } = this.props.listing;
    let educationArray = [];
    if (education.records && education.records.length) {
      education.records.forEach((r) => {
        r.sortName = r.record.fields.name;
      });
      educationArray = education.records;
    }
    if (this.state.sortBy) {
      educationArray =
        this.state.sortBy === "asc"
          ? sortByAsc(education.records, "sortName")
          : sortByDes(education.records, "sortName");
    }
    return (
      <>
      <div className="education-listing-con">
        {educationArray.map((edu) => (
          <li
            className="text-white d-flex flex-column pointer"
            key={edu.record.id}
            onClick={() => this.openedDetails(edu.record.id)}
          >
            {edu.record.fields.name}
          </li>
        ))}
      </div>
        <div className="pagination-container mt-3">
          <Pagination
            defaultCurrent={1}
            current={this.state.currentPage}
            onChange={(e) => this.pageChange(e)}
            defaultPageSize={20}
            // total={1180}
            total={education.total_count}
            hideOnSinglePage={true}
            showSizeChanger={false}
          />
        </div>
      </>
    );
  }

  render() {
    const { listing } = this.props,
      { loading } = listing,
      modalOpened = this.state.modalOpened;
    const { name } = this.state;

    return (
      <div className="history-listing">
        <SidePanel>
          <div className="history-listing__wrapper">
            <div className="d-flex justify-content-between align-items-center p-4 m-3">
              <h3 className="primary-heading text-white m-0  heading">
                Lists of University
              </h3>
              <div className="dropdown filter-dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="icon-filter">&nbsp;</i>
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    onClick={() => this.filter("asc")}
                  >
                    Ascending
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => this.filter("des")}
                  >
                    Descending{" "}
                  </button>
                </div>
              </div>
            </div>

            {loading ? <Loading /> : this.listingRender()}
          </div>
        </SidePanel>
        {modalOpened && (
          <Module
            id="education"
            modalOpened={modalOpened}
            closeModal={this.toggleModal}
          >
            <EducationDetails city={name} id={this.state.detailsId} />
          </Module>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listing: state.listing,
  golbal: state.golbal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getEducation: (stateCode, page) => dispatch(getEducation(stateCode, page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Education);
