import { fetchUtil } from "../../utils/fetchUtils";
import { errorToast, successToast } from "../../utils/toaster";
import { basicToken } from "../../utils/tokenUtils";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const FETCHING = "FETCHING";
export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const IS_LOGIN = "IS_LOGIN";

const saveToLocalStorage = (res) => {
  if (res && res.access_token) {
    localStorage.DLToken = res.access_token;
    localStorage.user = JSON.stringify(res.user);
    localStorage.name = res.name;
  }
};

const rememberSaveCredentials = (remember, body) => {
  if (!remember) {
    localStorage.removeItem("__dynamic_remember");
    return null;
  }

  const user = JSON.parse(body);
  const credential = {
    email: user?.email,
    password: btoa(user?.password),
  };
  localStorage.setItem("__dynamic_remember", btoa(JSON.stringify(credential)));
};

export const login = (user, remember) => (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  dispatch({ type: FETCHING, payload: true });
  const body = JSON.stringify(user);
  fetchUtil({
    url: "user/login",
    method: "POST",
    body,
  })
    .then((res) => {
      saveToLocalStorage(res);
      rememberSaveCredentials(remember, body);
      dispatch({ type: LOGIN_SUCCESS, payload: res });
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAIL, payload: err });
      errorToast(err?.payload?.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const loginAsGuest = () => (dispatch) => {
  localStorage.DLToken = basicToken;
  localStorage.user = "guest";
  dispatch({ type: LOGIN_SUCCESS, payload: undefined });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT_REQUEST });
  const remember = localStorage.getItem("__dynamic_remember");
  localStorage.clear();
  if (remember) localStorage.setItem("__dynamic_remember", remember);
};

export const isLogin = () => (dispatch) => {
  const isToken = !!localStorage.DLToken;
  dispatch({ type: IS_LOGIN, payload: isToken });
};

export const registration = (user) => (dispatch) => {
  // dispatch({ type: REGISTRATION_REQUEST });
  dispatch({ type: FETCHING, payload: true });
  const body = JSON.stringify(user);
  fetchUtil({
    url: "user/create",
    method: "POST",
    body,
  })
    .then((res) => {
      dispatch({ type: REGISTRATION_SUCCESS, payload: res });
    })
    .catch((err) => {
      dispatch({ type: REGISTRATION_FAIL, payload: err.message });
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const forgotPassword = (email) => (dispatch) => {
  dispatch({ type: FETCHING, payload: true });
  const body = JSON.stringify(email);
  fetchUtil({
    url: "user/password/reset",
    method: "POST",
    body,
  })
    .then((res) => {
      successToast("Password Reset Email Sent.Please check your email address");
    })
    .catch((err) => {
      errorToast(err?.payload.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const setPassword = (password, token) => (dispatch) => {
  const body = JSON.stringify({
    password: password,
  });
  return fetchUtil({
    url: `user/password/set?resetToken=${token}`,
    method: "POST",
    body,
  });
};
