import {fetchUtil} from "../../utils/fetchUtils";
import {errorToast, successToast} from "../../utils/toaster";

export const SEARCH_FLIGHT = "SEARCH_FLIGHT";
export const SEARCH_SUGGESTION_LOADING = "SEARCH_SUGGESTION_LOADING";
export const SEARCH_SUGGESTION = "SEARCH_SUGGESTION";
export const FLIGHTS_LISTING = "FLIGHTS_LISTING";
export const GET_FLIGHTS_LOADING = "GET_FLIGHTS_LOADING";
export const FLIGHT_OFFERS = "FLIGHT_OFFERS";
export const FLIGHTS_BOOKING_LOADING = "FLIGHTS_BOOKING_LOADING";
export const FLIGHTS_BOOKING_SUCCESS = "FLIGHTS_BOOKING_SUCCESS";

export const searchingFlight = (data) => dispatch => {
    dispatch({ type: SEARCH_FLIGHT, payload: data });
};

export const saveFlightOffers = (data) => dispatch => {
    // console.log('saveFlightOffers', data);
    dispatch({ type: FLIGHT_OFFERS, payload: data });
};

export const searchSuggestion = (text) => dispatch => {
    dispatch({ type: SEARCH_SUGGESTION_LOADING, payload: true });
    fetchUtil({
        url: `flight/search?search=${text}`,
        method: "GET",
    })
        .then(res => {
            // console.log('res', res);
            dispatch({ type: SEARCH_SUGGESTION, payload: res?.data });
        })
        .catch(err => {
            dispatch({ type: SEARCH_SUGGESTION_LOADING, payload: false });

        })

};

export const getFlights = (body) => dispatch => {
    // console.log('body........', body);
    // const body = {
    //     "arrival":"2022-02-25",
    //     "travelClass":"FIRST",
    //     "departure":"2022-02-24",
    //     "locationDeparture":"PAR",
    //     "locationArrival":"BOS",
    //     "adults":1,
    //     "children":1,
    //     "infants":1,
    //     "nonStop":true
    // }
    dispatch({ type: GET_FLIGHTS_LOADING, payload: true });
    fetchUtil({
        url: "flight/search/flights",
        method: "POST",
        body : JSON.stringify(body)
    })
        .then(res => {
            // console.log('res', res);
            dispatch({ type: FLIGHTS_LISTING, payload: res?.data });
        })
        .catch(err => {
            dispatch({ type: GET_FLIGHTS_LOADING, payload: false });

        })

};


export const flightsBooking = (body) => dispatch => {
    dispatch({ type: FLIGHTS_BOOKING_LOADING, payload: true });
    fetchUtil({
        url: "flight/confirm/booking",
        method: "POST",
        body : JSON.stringify(body)
    })
        .then(res => {
            // console.log('res', res);
            dispatch({ type: FLIGHTS_BOOKING_SUCCESS, payload: res?.data });
            successToast('Booking created successfully');
        })
        .catch(err => {
            dispatch({ type: FLIGHTS_BOOKING_LOADING, payload: false });
            const errMsg =   err?.data?.errors?.length ? err?.data?.errors[0]?.detail : '';
            errorToast(errMsg);
        })

};
