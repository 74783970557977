import React, { Component } from "react";
import "./WebFooter.scss";
import {connect} from "react-redux";
import map from "../../assets/images/landingPageAssets/map.png";
import logo from "../../assets/images/dl-logo.gif";
import {
    AiFillLinkedin,
    AiOutlineTwitter,
    GrMail,
    GrYoutube,
    ImPhoneHangUp,
    RiFacebookFill,
    TiSocialInstagram
} from "react-icons/all";
import newsletter from "../../assets/images/landingPageAssets/newsletter.png";
import {errorToast, successToast} from "../../utils/toaster";
import {fetchUtil} from "../../utils/fetchUtils";

const date = new Date();
const year = date.getFullYear();

class WebFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsletterEmail :'',
            newsletterEmailLoading : false
        };

    }


    newsLetterSubmitHandler = (e) =>{
        e.preventDefault();
        const {newsletterEmail} = this.state
        if(!newsletterEmail){
            errorToast('Please enter a email address');
            return false;
        }
        else if(!this.validateEmail(newsletterEmail)){
            errorToast('Please enter a valid email address');
            return false;
        }
        else {
            // console.log('send')
            this.setState({newsletterEmailLoading: true});
            fetchUtil({
                url: `info/newsletter`,
                method: "POST",
                body : JSON.stringify({email : newsletterEmail})
            })
                .then(res => {
                    successToast('Thank you for subscribing to our newsletter!');
                    this.setState({newsletterEmail : ''})
                    this.setState({newsletterEmailLoading: false});
                })
                .catch(err => {
                    // console.log('err', err);
                    errorToast(err.payload.message);

                    this.setState({newsletterEmailLoading: false});
                })
        }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    onChangeEmail = (e) => {
        let { value } = e.target;
        this.setState({newsletterEmail: value})
    }

    render() {
        return (
            <>
                <section className="lp-newsletter">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 newletter-img">
                                <img className="w-100" src={newsletter} alt="news"/>
                            </div>
                            <div className="col-12 col-lg-6 lp-newsletter-content d-flex align-items-center">
                                <div className="w-100 pl-0 px-lg-4">
                                    <h3 className="lp-primary-heading text-white">Sign up for the Newsletter</h3>
                                    <p className="text-white">Subscribe to our newsletter to learn new and trendy moving tips, ideas, and discounts.</p>
                                    <form>
                                        <input type="email" className="rounded-pill" value={this.state.newsletterEmail} onChange={this.onChangeEmail} placeholder="EMAIL ADDRESS ..."/>
                                        <button className="btn btn-dark rounded-pill" onClick={this.newsLetterSubmitHandler}>Sign up Now</button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="web-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="web-footer__wrapper d-flex justify-content-center align-items-center flex-column"
                                    style={{backgroundImage: `url(${map})`}}
                                >
                                    <a href="/">
                                        <img className="footer-logo" src={logo} alt="footer-logo"/>
                                    </a>
                                    <div
                                        className="web-footer__contact d-flex flex-column flex-lg-row justify-content-between">
                                        <div className="web-footer__contact__phone">
                                            <ImPhoneHangUp color="#000" size={20}/>
                                            <a href="tel:+1 800 455 4555 & 66">
                                                +1 800 455 4555 & 66
                                            </a>
                                        </div>
                                        <div className="web-footer__contact__mail mt-3 mt-lg-0">
                                            <GrMail size={20}/>
                                            <a href="mailto:support@dynamicliving.com">
                                                support@dynamicliving.com
                                            </a>
                                        </div>
                                    </div>
                                    <div className="web-footer__socials d-flex">
                                        <a
                                            href="http://www.facebook.com"
                                            target="_blank"
                                            className="web-footer__socials__icons mx-2"
                                        >
                                            <RiFacebookFill color="#fff" size={20}/>
                                        </a>
                                        <a
                                            href="http://www.twitter.com"
                                            target="_blank"
                                            className="web-footer__socials__icons mx-2"
                                        >
                                            <AiOutlineTwitter color="#fff" size={20}/>
                                        </a>
                                        <a
                                            href="http://www.youtube.com"
                                            target="_blank"
                                            className="web-footer__socials__icons mx-2"
                                        >
                                            <GrYoutube color="#fff" size={20}/>
                                        </a>
                                        <a
                                            href="http://www.instagram.com"
                                            target="_blank"
                                            className="web-footer__socials__icons mx-2"
                                        >
                                            <TiSocialInstagram color="#fff" size={20}/>
                                        </a>
                                        <a
                                            href="http://www.linkedin.com"
                                            target="_blank"
                                            className="web-footer__socials__icons mx-2"
                                        >
                                            <AiFillLinkedin color="#fff" size={20}/>
                                        </a>
                                    </div>
                                    <p className="web-footer__copyrights text-center">Dynamic Living LLC® All Rights
                                        Reserved. ©{year} <a href="/">Dynamicliving.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>

        );
    }
}



const mapStateToProps = state =>({
    auth: state.auth,
    golbal: state.golbal,

});

export default connect(
    mapStateToProps,
    null
)(WebFooter);
