import React, { Component } from "react";
import "./App.scss";
import Home from "./app/views/Home/Home";
import Header from "./app/components/Header/Header";
import Footer from "./app/components/Footer/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Account from "./app/views/Account/Account";
import { ToastContainer } from "react-toastify";
import dl from "../src/app/assets/images/dl-logo.gif";

import "react-toastify/dist/ReactToastify.css";
import LicenseChange from "./app/views/LicenseChange/LicenseChange";
import { connect } from "react-redux";
import { isLogin, logout } from "./app/store/actions";
import VehicleRegistrationChange from "./app/views/VehicleRegistrationChange/VehicleRegistrationChange";
import LandingPage from "./app/views/LandingPage/LandingPage";
import PrivacyPolicy from "./app/views/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./app/views/TermsAndCondition/TermsAndCondition";
import AboutUs from "./app/views/AboutUs/AboutUs";
import PartnerWithUs from "./app/views/PartnerWithUs/PartnerWithUs";
import JunkRemovalCompanies from "./app/views/JunkRemovalCompanies/JunkRemovalCompanies";
import MovingCompanies from "./app/views/MovingCompanies/MovingCompanies";
import Relators from "./app/views/Relators/Relators";
import Journey from "./app/views/Journey/Journey";
import BookFlight from "./app/views/BookFlight";
import "antd/dist/antd.css";
import PassengerDetails from "./app/views/Journey/PassengerDetails/PassengerDetails";
import Root from "./app/views/Root/Root";
import ScrollToTop from "./ScrollToTop";
import MyBookings from "./app/views/MyBookings/MyBookings";

class App extends Component {
  constructor(props) {
    super(props);
    // this.handleTabClose = this.handleTabClose.bind(this);
  }
  state = { loading: true };

  // handleTabClose = (event) => {
  //   event.preventDefault();

  //   this.props.logout();
  // };

  // componentWillMount() {
  //   this.props.isLogin();
  // }
  // componentDidMount() {
  //   window.addEventListener("beforeunload", this.handleTabClose);

  //   setTimeout(() => {
  //     this.setState({ loading: false });
  //   }, 6000);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("beforeunload", this.handleTabClose);
  // }

  render() {
    const { landingPage } = this.props.golbal;
    const mainClass = landingPage ? "landing-page" : "main";
    return (
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          {!landingPage ? <Header /> : null}
          <main className={mainClass}>
            <ScrollToTop>
              <Switch>
                <Route
                  path="/license-change"
                  name="license-change"
                  component={LicenseChange}
                />
                <Route
                  path="/vehicle-registration-change"
                  name="vehicle-registration-change"
                  component={VehicleRegistrationChange}
                />
                <Route
                  path="/privacy-policy"
                  name="privacy-policy"
                  component={PrivacyPolicy}
                />
                <Route
                  path="/terms-and-condition"
                  name="terms-and-condition"
                  component={TermsAndCondition}
                />
                <Route path="/account" name="account" component={Account} />
                <Route path="/about-us" name="about-us" component={AboutUs} />
                <Route path="/my-bookings" name="my-bookings" component={MyBookings} />
                <Route
                  path="/partner-with-us"
                  name="about-us"
                  component={PartnerWithUs}
                />
                <Route
                  path="/junk-removal-companies"
                  name="JunkRemovalCompanies"
                  component={JunkRemovalCompanies}
                />
                <Route
                  path="/moving-companies"
                  name="MovingCompanies"
                  component={MovingCompanies}
                />
                {/*<Route*/}
                {/*    path="/relators/:id"*/}
                {/*    name="Relators"*/}
                {/*    component={Relators}*/}
                {/*/>*/}
                <Route path="/journey" name="journey" component={Journey} />

                <Route
                  path="/book-flight"
                  name="bookFlight"
                  component={BookFlight}
                />
                <Route
                  path="/passenger-details"
                  name="passenger-details"
                  component={PassengerDetails}
                />
                <Route path="/home" name="home" component={Home} />
                <Route path="" name="main-route" component={Root} />
                {/*<Route></Route>*/}
                {/*<Route*/}
                {/*    exact={true}*/}
                {/*    path="/"*/}
                {/*    name="landingpage"*/}
                {/*    component={LandingPage}*/}
                {/*/>*/}
              </Switch>
            </ScrollToTop>
          </main>
          {!landingPage ? <Footer /> : null}
        </Router>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  golbal: state.golbal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    isLogin: () => dispatch(isLogin()),
    logout: () => dispatch(logout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
