import React, { Component } from "react";
import "./Hotels.scss";
import {getHotel} from "../../../store/actions";
import {connect} from "react-redux";
import Loading from "../../../components/Loading/Loading";
import {getQueryString, getFilterBY, sortByAsc, sortByDes} from "../../../utils/helper";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import HotelDetails from "./HotelDetails";

class Hotels extends Component{
    constructor(props) {
        super(props);
        this.state = {
            modalOpened : false,
            detailsId : null,
            filterBy : '',
            name: '',
            sortBy: null
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.openedDetails = this.openedDetails.bind(this);
    }


    componentDidMount() {
        const {location} = this.props;
        let filterBy = '',
            name = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            name = getQueryString(location.search, filterBy);
        }
        if (name) {
            this.props.getHotel(name);
        }
        this.setState({
            filterBy, name
        })
    }

    componentDidUpdate(nextProps) {
        const {location} = nextProps.history;
        const {name} = this.state;
        let nextName = null;
        let filterBy = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            nextName = getQueryString(location.search, filterBy);
        }
        if (nextName && name !== nextName) {
            this.props.getHotel(nextName);
            this.setState({
                filterBy,
                name : nextName
            })
        }
    }

    toggleModal(){
        const modalOpened = this.state.modalOpened;
        this.setState({
            modalOpened : !modalOpened
        })
    }

    filter(order){
        this.setState({
            sortBy : order
        })
    }
    openedDetails(id){
        this.setState({
            detailsId : id
        });
        this.toggleModal();
    }

    hotelsRender(){
        const {listing} = this.props;
        if(listing.hotels  && listing.hotels.suggestions){
            const hotels = listing.hotels.suggestions.find(group => group.group === 'HOTEL_GROUP');
            return <div className="hotel-listing__wrapper">
                {
                    (hotels && hotels.entities && hotels.entities.length) ?
                        <ul className="bullet">
                            {this.hotelMap(hotels)}
                        </ul> :
                        <div className="p-5 text-white text-center">
                            No hotel found
                        </div>
                }
            </div>;
        }
        return (
            <div className="p-5 text-white text-center">
                No hotel found
            </div>
        );
    }


    hotelMap(hotels){

        if(hotels && hotels.entities){
            let hotelsListing = hotels.entities;
            if(this.state.sortBy){
                hotelsListing = this.state.sortBy === 'asc' ? sortByAsc(hotels.entities, 'name'): sortByDes(hotels.entities, 'name');
            }
            return  hotelsListing.map(hotel =>
                <li className="hotel-name text-white pointer" key={hotel.destinationId}  onClick={ ()=> this.openedDetails(hotel.destinationId)}>
                    {
                        hotel.name
                    }
                </li>
            )
        }
        return null;

    }

    render2(){
        const {listing, city} = this.props;
        return <div className="hotel-listing">
            <h3 className="primary-heading text-white  py-3 py-md-5 heading pl-5">Hotels in {city}</h3>
            {
                listing.loading ? <Loading/> : this.hotelsRender()
            }
        </div>;
    }


    render() {
        const {listing } = this.props,
            { loading } = listing,
            modalOpened = this.state.modalOpened;
        const {name} = this.state;


        return (
            <div className="history-listing">
                <SidePanel>
                    <div className="history-listing__wrapper">
                        <div className="d-flex justify-content-between align-items-center p-4 m-3">
                            <h3 className="primary-heading text-white m-0  heading">Hotels in {name}</h3>
                            <div className="dropdown filter-dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                                        aria-expanded="false">
                                    <i className="icon-filter">&nbsp;</i>
                                </button>
                                <div className="dropdown-menu">
                                    <button className="dropdown-item" onClick={ () => this.filter('asc')} >Ascending</button>
                                    <button className="dropdown-item" onClick={ () => this.filter('des')}>Descending </button>
                                </div>
                            </div>
                        </div>
                        {
                            loading ? <Loading /> : this.hotelsRender()
                        }
                    </div>
                </SidePanel>
                {
                    modalOpened &&   <Module id="hotels" modalOpened={modalOpened} closeModal={this.toggleModal}>
                        <HotelDetails city={name} id={this.state.detailsId}/>
                    </Module>
                }
            </div>

        );
    }

}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getHotel: (city) => dispatch(getHotel(city))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hotels);

