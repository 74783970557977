import React, { Component } from "react";
import "./HotelDetails.scss";
import {getHotelDetails} from "../../../store/actions";
import {connect} from "react-redux";
import Loading from "../../../components/Loading/Loading";
import Rating from "../../../components/Rating/Rating";
import map from "../../../assets/images/icons/map.png";

import renderHTML from 'react-render-html';


class HotelDetails extends Component{

    componentDidMount() {
        const {id} = this.props;
        if(id){
            this.props.getHotelDetails(id);
        }
    }

    render(){
        const {loadingDetails} = this.props.listing;
        return  <div className="hotel-details-modal">
            { loadingDetails ? <Loading/> : this.renderDetails() }

        </div>;
    }

    renderDetails(){
        const {hotelDetails} = this.props.listing;


        if(hotelDetails && hotelDetails.data && hotelDetails.data.body){
            const description =  hotelDetails.data.body.propertyDescription;
            return <div className="hotel-details-wrapper">
                <div className="hotel-details__header d-flex flex-column ">
                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center hotel-name">
                        <h3>{description.name}</h3>
                        <div className="d-flex align-items-center">
                            <Rating rating={description.starRating}/>
                            <span>({description.starRating})</span>
                        </div>
                    </div>
                    <address className="d-flex  align-items-center">
                        <img className="pr-3" src={map} alt="map" />
                        {description.address.fullAddress}
                    </address>
                </div>
                {this.renderOverview()}
                {this.renderFacilities()}
                {this.renderPolicies()}
            </div>;
        }


        return  null;
    }
    renderOverview(){
        const {hotelDetails} = this.props.listing;
        const overview = hotelDetails.data.body.overview;
        if(overview && overview.overviewSections && overview.overviewSections.length){
            return <div className="overview">
                <h3>Overview</h3>
                {
                    overview.overviewSections.map((data)=>{
                        let info = data.content.join();
                        info = info.replace(/,/g, ", ");
                        if(data.title){
                            return <div key={data.title} className="overview__content">
                                <h4>{data.title}</h4>
                                <p>{info}</p>
                            </div>
                        }
                        return null;
                    })
                }
            </div>
        }
    }
    renderPolicies(){
        const {smallPrint} = this.props.listing.hotelDetails.data.body;
        if((smallPrint.policies && smallPrint.policies.length) || (smallPrint.optionalExtras && smallPrint.optionalExtras.length)){
            return <div className="overview">
                <h3>Policies</h3>
                {this.renderArray(smallPrint.policies)}
                {this.renderArray(smallPrint.optionalExtras)}

            </div>
        }
    }

    renderArray(array){
        if(array && array.length)
            return  array.map((data, i)=>{
                return <div className="render" key={i}>{renderHTML(data)}</div>
            });
        return null
    }

    renderFacilities(){
        const {amenities} = this.props.listing.hotelDetails.data.body;
        if(amenities.length){
            return amenities.map((facilities, i)=>{
               return <div key={`facilities-${i}`} className="facilities">
                    <h3 className="capitailze">Most popular facilities {facilities.heading}</h3>
                   <ul className="bullet">
                   {
                       facilities.listItems.map((item, i)=>{
                           return <li key={item.heading + i}>{item.heading}</li>
                       })
                   }
                   </ul>
                </div>
            });
        }
    }


}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getHotelDetails: (city) => dispatch(getHotelDetails(city))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HotelDetails);

