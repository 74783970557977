import { fetchUtil } from "../../utils/fetchUtils";
import { errorToast, successToast } from "../../utils/toaster";

export const EDUCATION_SUCCESS = "EDUCATION_SUCCESS";
export const FETCHING = "FETCHING";

export const getEducation =
  (stateCode, page = 1) =>
  (dispatch) => {
    dispatch({ type: FETCHING, payload: true });
    fetchUtil({
      url: `educations?code=${stateCode}&page=${page}`,
      method: "GET",
      showMessage: false,
    })
      .then((res) => {
        dispatch({ type: EDUCATION_SUCCESS, payload: res });
      })
      .catch((err) => {
        errorToast(err.message);
      })
      .finally(() => {
        dispatch({ type: FETCHING, payload: false });
      });
  };
