import React, { Component } from "react";
import "./FlightSearchForm.scss";
import AsyncSelect from "react-select/async";
import { errorToast } from "../../utils/toaster";
import { SwapOutlined } from  '@ant-design/icons';

import { connect } from "react-redux";
import { searchingFlight } from "../../store/actions";
import arrow from "../../assets/images/icons/circle-arrow.png";
import { fetchUtil } from "../../utils/fetchUtils";
import PassengersDropdown from "../PassengersDropdown/PassengersDropdown";
import moment from "moment";

const travelClassAvailable = [
  { id: 1, value: "ECONOMY", title: "Economy" },
  { id: 2, value: "PREMIUM_ECONOMY", title: "Premium Economy" },
  { id: 3, value: "BUSINESS", title: "Business Class" },
  { id: 4, value: "FIRST", title: "First Class" },
];

class FlightSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneWay: true,
      arrival: "",
      travelClass: "ECONOMY",
      departure: "",
      locationDeparture: "",
      locationArrival: "",
      adults: 1,
      children: 0,
      infants: 0,
      nonStop: true,
      suggestion: [],
      defaultOptions: [],
      suggestionLoading: false,
      switchSelectFields: false,
    };
    this.handleSelectorChange = this.handleSelectorChange.bind(this)
  }

  handleSelectorChange = function(){
    // console.log('there')
    this.setState({switchSelectFields: !this.state.switchSelectFields, locationDeparture: this.state.locationArrival, locationArrival: this.state.locationDeparture});
  }


  oneWayToggle = (bol) => {
    this.setState({
      oneWay: bol,
    });
  };

  adultChange = (value) => {
    this.setState({
      adult: value,
    });
  };

  fightClassChange = (value) => {
    this.setState({
      travelClass: value,
    });
  };

  componentDidMount() {
    let { searchFlight } = this.props.flight;
    this.setState(searchFlight);

    this.setState({ loading: true });
    return new Promise((resolve) => {
      fetchUtil({
        url: `flight/search?search=bos`,
        method: "GET",
      })
        .then((res) => {
          const data = res?.data.map((d) => {
            return {
              ...d,
              label: `${d?.name.toLowerCase()} | ${d?.address?.cityName}`,
              value: d.id,
            };
          });
          const result = data.reduce((unique, o) => {
            if (
              !unique.some(
                (obj) => obj.label === o.label && obj.value === o.value
              )
            ) {
              unique.push(o);
            }
            return unique;
          }, []);
          this.setState({ defaultOptions: result });
          resolve(result || []);
        })
        .catch((err) => resolve([]))
        .finally(() => this.setState({ loading: false }));
    });
  }

  change = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };

  submit = () => {
    const valid = this.formValidation();
    if (valid) {
      this.props.searchingFlight(this.state);
      // eslint-disable-next-line no-unused-expressions
      this.props?.onSubmit();
    }
  };

  formValidation() {
    const { departure, locationDeparture, locationArrival, oneWay, arrival } =
      this.state;
      // console.log('departure & arrival: ', departure, arrival)
    if (
      !departure ||
      !locationDeparture ||
      !locationArrival ||
      (!oneWay && !arrival)
    ) {
      let message = oneWay
        ? "Please fill these fill Origin, Destination and Departure Date"
        : "Please fill these fill Origin, Destination, Departure and Arrival Date ";
      errorToast(message);
      return false;
    } else if (locationDeparture === locationArrival){
      errorToast("Origin and Destination should be different");
      return false;
    }else if (arrival !== "" && departure >= arrival && !oneWay){
      errorToast("Arrival date must be after departurure date");
      return false;
    }else return !(!oneWay && !arrival);
  }

  getTravelClassName = () => {
    const { travelClass } = this.state;
    const travel = travelClassAvailable.find((t) => t.value === travelClass);
    return <>{travel?.title}</>;
  };

  promiseOptions = (inputValue) => {
    this.setState({ loading: true });
    return new Promise((resolve) => {
      fetchUtil({
        url: `flight/search?search=${inputValue}`,
        method: "GET",
      })
        .then((res) => {
          const data = res?.data.map((d) => {
            return {
              ...d,
              label: `${d?.name.toLowerCase()} | ${d?.address?.cityName}`,
              value: d.id,
            };
          });
          const result = data.reduce((unique, o) => {
            if (
              !unique.some(
                (obj) => obj.label === o.label && obj.value === o.value
              )
            ) {
              unique.push(o);
            }
            return unique;
          }, []);
          this.setState({ defaultOptions: result });
          resolve(result || []);
        })
        .catch((err) => resolve([]))
        .finally(() => this.setState({ loading: false }));
    });
  };

  onChangeHandle = (e, name) => {
    this.setState({ [name]: e });
  };
  render() {
    const {
      oneWay,
      arrival,
      travelClass,
      departure,
      locationDeparture,
      locationArrival,
      nonStop,
      suggestionLoading,
    } = this.state;
    let { getFlightLoading } = this.props.flight;
    const { isJourney } = this.props;
    const currentDate = moment().format("YYYY-MM-DD");
    const rotate = this.state.switchSelectFields ? "rotate(180deg)" : "rotate(0)";
    return (
      <div className={`flight-searching ${this.props.className}`}>
        <div className="journey-form-option d-flex ml-5">
          <button
            type="button"
            className={this.state.oneWay ? "active" : ""}
            onClick={() => this.oneWayToggle(true)}
          >
            One-way
          </button>
          <button
            type="button"
            className={this.state.oneWay ? "" : "active"}
            onClick={() => this.oneWayToggle(false)}
          >
            Round-trip
          </button>
        </div>
        <div className="journey-form">
          <div className="d-flex flex-md-row flex-column form-row-field">
            <div className="d-flex flex-md-row flex-column to-from-box bg-white">
              <div className="to-from-box-field">
                <label>From</label>
                {/*<input onChange={this.change} value={locationDeparture} name="locationDeparture" placeholder="Dallas (Any)"/>*/}
                <AsyncSelect
                  cacheOptions
                  defaultOptions={this.state.defaultOptions}
                  placeholder="Dallas (Any)"
                  value={locationDeparture}
                  loadOptions={this.promiseOptions}
                  onChange={(e) => this.onChangeHandle(e, "locationDeparture")}
                  isLoading={suggestionLoading}
                  //    menuIsOpen={true}
                />
              </div>
              <div className="to-from-box-field">
                <label>To</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions={this.state.defaultOptions}
                  placeholder="Dallas (Any)"
                  value={locationArrival}
                  loadOptions={this.promiseOptions}
                  onChange={(e) => this.onChangeHandle(e, "locationArrival")}
                  isLoading={suggestionLoading}
                />
                {/*<input onChange={this.change} value={locationArrival}  name="locationArrival" placeholder="Dallas (Any)"/>*/}
              </div>
              <div className="switch-select-icon" onClick={this.handleSelectorChange}>
              <SwapOutlined style={{ transform: rotate, transition: "all 0.2s linear" }}/>
              </div>
            </div>
            <div className="d-flex departure-date flex-md-row flex-column">
              <div className="date-field flex-grow-1">
                <label>Departure Date</label>
                <input
                  onChange={this.change}
                  min={currentDate}
                  name="departure"
                  value={departure}
                  placeholder="departure"
                  type="date"
                />
              </div>
              {!oneWay ? (
                <div className="date-field flex-grow-1">
                  <label>Arrival Date</label>
                  <input
                    onChange={this.change}
                    min={currentDate}
                    name="arrival"
                    value={arrival}
                    placeholder="arrival"
                    type="date"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between flex-column flex-md-row mt-4">
            <div className="form-check d-flex justify-content-center justify-content-md-start my-md-0 my-3 align-items-center">
              <input
                className="form-check-input custom-checkbox d-inline-block"
                checked={nonStop}
                name="nonStop"
                onChange={() => this.setState({ nonStop: !nonStop })}
                type="checkbox"
                value={nonStop}
                id="defaultCheck1"
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                Direct flight only
              </label>
            </div>
            <div className={`d-flex flex-md-row flex-column gap-3 f-14 ${isJourney && "text-white"}`}>
              <PassengersDropdown
                onChange={(e, name) => this.onChangeHandle(e, name)}
              />
              <div className="dropdown select-drop-down mx-4 sf-passenger-dropdown-btn">
                <button
                  className="btn-dropdown dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {this.getTravelClassName()}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {travelClassAvailable.map((t) => {
                    return (
                      <button
                        key={t.id}
                        type="button"
                        className={`dropdown-item ${
                          travelClass === t.value ? "active" : ""
                        }`}
                        onClick={() => this.fightClassChange(t.value)}
                      >
                        {t.title}
                      </button>
                    );
                  })}
                </div>
              </div>
              <button
                type="button"
                disabled={getFlightLoading}
                className="btn btn-primary search-button"
                onClick={this.submit}
              >
                <img src={arrow} alt="icon" />
                Search Flights
                {getFlightLoading ? (
                  <i className="icon-reload text-white loading"></i>
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  flight: state.flight,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchingFlight: (searching) => dispatch(searchingFlight(searching)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightSearchForm);
