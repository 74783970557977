import React from 'react';
import './BannerCover.scss';


const BannerCover = ({bgImg, title}) => {
    return (
        <div className="banner-cover" style={{backgroundImage:`url("${bgImg}")`}} >
            <h2 className="text-center py-3 font-secondary">{title}</h2>
        </div>
    );
};

export default BannerCover;