import React, { PureComponent } from "react";
import {
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
  DirectionsRenderer,
  Circle,
} from "react-google-maps";
import "./Map.scss";

import { connect } from "react-redux";
import { darkToast } from "../../utils/toaster";
import { Link } from "react-router-dom";
import destinationIcon from "../../assets/images/icons/pin.svg";
let originName = "",
  designationName = "",
  directionsService;

const MakerInfo = ({ text, info, stateCode }) => (
  <div className="maker-info">
    <div className="maker__popover-info">
      <ul>
        <li>
          <Link
            to={{
              pathname: "/home/crime",
              search: `?${info}=${text}`,
            }}
          > 
          { console.log(
    'ealdjfldsf: ', text, info, stateCode
  )}
            <span>
              {" "}
              <i className="icon-indeed-logo"></i> <span>Crime </span>
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/home/history",
              search: `?${info}=${text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-indeed-logo"></i> <span>History </span>
            </span>
          </Link>
        </li>
        {/* <li>
            <Link
              to={{
                pathname: "/home/weather",
                search: `?${info}=${text}`,
              }}
            >
              <span>
                {" "}
                <i className="icon-climate"></i>
                <span>Weather</span>
              </span>
            </Link>
          </li> */}
        <li>
          <Link
            to={{
              pathname: "/home/hotels",
              search: `?${info}=${text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-hotel"></i> <span>Hotels</span>
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/home/jobs",
              search: `?${info}=${text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-indeed-logo"></i> <span>Jobs</span>
            </span>
          </Link>
        </li>

        {/* <li>
            <Link
              to={{
                pathname: "/home/info",
                search: `?${info}=${text}`,
              }}
            >
              <span>
                {" "}
                <i className="icon-indeed-logo"></i> <span>Info </span>
              </span>
            </Link>
          </li> */}
        <li>
          <Link
            to={{
              pathname: "/home/salary",
              search: `?${info}=${text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-indeed-logo"></i> <span>Salaries </span>
            </span>
          </Link>
        </li>
        {/* <li>
          <Link
            to={{
              pathname: "/home/move",
              search: `?${info}=${text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-indeed-logo"></i> <span>Stats </span>
            </span>
          </Link>
        </li> */}
        <li>
          <Link
            to={{
              pathname: "/home/education",
              search: `?${info}=${stateCode ? stateCode : text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-graduate-cap"></i> <span>Education</span>
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/home/hospital",
              search: `?${info}=${text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-graduate-cap"></i> <span>Hospital</span>
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/home/entertainments",
              search: `?${info}=${text}`,
            }}
          >
            <span>
              {" "}
              <i className="icon-graduate-cap"></i> <span>Entertainments</span>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  </div>
);

const GoogleMapExample = withGoogleMap((props) => {
  const { to, from, filterBy } = props.designation;
  function mapOptionsCreator(map) {
    return {
      zoomControlOptions: {
        position: map.ControlPosition.LEFT_CENTER,
      },
    };
  }
  return (
    <GoogleMap
      options={{
        center: {
          lat: to && to.name && !from ? +to.latitude : null,
          lng: to && to.name && !from ? +to.longitude : null,
        },
        zoomControlOptions: {
          position: window?.google?.maps?.ControlPosition.LEFT_CENTER,
        },

        streetViewControlOptions: {
          position: window?.google?.maps?.ControlPosition.LEFT_TOP,
        },
        // fullscreenControl: true,
      }}
      defaultCenter={props.state.defaultCenter}
      defaultZoom={props.state.defaultZoom}
      className="myMarker"
    >
      {props.state.directions ? (
        <DirectionsRenderer
          panel={document.getElementById("sidebar")}
          directions={props.state.directions}
          options={{
            draggable: false,
            suppressInfoWindows: true,
            suppressMarkers: true,
            directionInProgress: false,
          }}
        />
      ) : null}
      {to && to.name && !from ? (
        <Marker
          animation={props.state.toMarkerInfo ? 0 : 1}
          position={{ lat: +to.latitude, lng: +to.longitude }}
          onClick={() => props.handleToggleOpen("toMarkerInfo")}
        >
          {props.state.toMarkerInfo && (
            <InfoWindow>
              <MakerInfo text={to.name} info={filterBy} stateCode={to.state} />
            </InfoWindow>
          )}
        </Marker>
      ) : null}

      {to && to.name && props.state.directionInProgress ? (
        <Marker
          position={{ lat: +to.latitude, lng: +to.longitude }}
          onClick={() => props.handleToggleOpen("toMarkerInfo")}
          animation={props.state.toMarkerInfo ? 0 : 1}
        >
          {props.state.toMarkerInfo && (
            <InfoWindow>
              <MakerInfo text={to.name} info={filterBy} stateCode={to.state} />
            </InfoWindow>
          )}
        </Marker>
      ) : null}

      {from && from.name && props.state.directionInProgress ? (
        <Marker
          animation={props.state.fromMarkerInfo ? 0 : 1}
          position={{ lat: +from.latitude, lng: +from.longitude }}
          icon={{url: destinationIcon,  anchor: { x: 15, y: 15 }, scaledSize: {width: 30, height: 30}}}
          onClick={(e) => props.handleToggleOpen("fromMarkerInfo", e)}
        >
          {props.state.fromMarkerInfo && (
            <InfoWindow>
              <MakerInfo
                text={from.name}
                info={filterBy}
                stateCode={from.state}
              />
            </InfoWindow>
          )}
        </Marker>
      ) : null}
      <div id="sidebar" className={to && to.name && !from ? "d-none" : ""} />
    </GoogleMap>
  );
});

class Map extends PureComponent {
  state = {
    directions: null,

    defaultCenter: {
      // lat: 0,
      // lng: 0,
      lat: 39.331767161718574,
      lng: -102.72005748889597,
    },
    defaultZoom: 5,
    toMarkerInfo: false,
    fromMarkerInfo: false,
    directionInProgress: false,
  };

  componentDidMount() {
    this.renderDirection();
  }

  componentWillReceiveProps(newProps, old) {
    if (
      newProps &&
      newProps.golbal &&
      newProps.golbal.designation &&
      newProps.golbal.designation.to &&
      newProps.golbal.designation.to.name &&
      (newProps.golbal.designation.to.name !== originName ||
        newProps.golbal.designation.from.name !== designationName)
    ) {
      this.renderDirection(newProps.golbal.designation);
    }
  }

  renderDirection(designation) {
    const designationRoute = designation || this.props.golbal.designation;
    const { to, from } = designationRoute;
    if (!to || !to.latitude || !from || !from.latitude) return null;
    this.setState({
      directionInProgress: true,
    });
    directionsService = new window.google.maps.DirectionsService();
    originName = to.name;
    designationName = from.name;
    const origin = { lat: +to.latitude, lng: +to.longitude };
    const destination = { lat: +from.latitude, lng: +from.longitude };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
            directionInProgress: true,
          });
        } else {
          darkToast(
            `${to.name} and ${from.name} is not available driving path `
          );
          this.setState({
            directionInProgress: true,
            directions: null,
            defaultCenter: {
              lat: 19.898682,
              lng: -155.665857,
            },
          });
        }
      }
    );
  }

  handleToggleOpen(infoWindow, marker) {
    const info = this.state[infoWindow];
    this.setState({
      [infoWindow]: !info,
    });
  }
  render() {
    return (
      <div>
        <GoogleMapExample
          containerElement={<div className="dynamic-llc-map" />}
          mapElement={<div className="dynamic-llc-map map-element" />}
          panelElement={
            <div
              id="google-sidebar"
              className="dynamic-llc-map map-element side-panel"
            />
          }
          state={this.state}
          designation={this.props.golbal.designation}
          handleToggleOpen={this.handleToggleOpen.bind(this)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  golbal: state.golbal,
});

export default connect(mapStateToProps, null)(Map);
