import React, { Component } from "react";
import "./WeatherDetails.scss";
import {getWeatherDetails} from "../../../store/actions";
import {connect} from "react-redux";
import {getTimeFormat, getDateFormat, getCentimeter, getDay} from "../../../utils/helper";
import Loading from "../../../components/Loading/Loading";

class WeatherDetails extends Component{

    componentWillMount() {
        const {city} = this.props;
        if(city){
            this.props.getWeatherDetails(city);
        }
    }

    filterForeCastList(list){
        let listing = [];
        list.forEach((data)=>{
            const day = getDay(data.dt_txt, true);
            data.day = day;
            const find = listing.find(l => l.day === day);
            if(!find){
                listing.push(data) ;
            }
        });
        if(listing.length >= 5){
            listing =  listing.slice(0, 5)
        }
        return listing
    }


    renderForeCast(){
        const {weather} = this.props.listing;
            if(weather.weatherDetails && weather.weatherDetails.list){
                const foreCastList = this.filterForeCastList(weather.weatherDetails.list);
                return foreCastList.map((list)=>{
                   return  <div className="row" key={list.day}>
                        <div className="col-12 d-flex flex-column flex-md-row align-items-center forcasting justify-content-between">
                            <div className="forcasting-left d-flex align-items-center justify-content-between">
                                <span className="day px-3 px-md-0 ">{list.day}</span>
                                <span className="centimeter">{getCentimeter(list.main.temp)}</span>
                                <span className="weather-icon">
                                <img src={`http://openweathermap.org/img/wn/${list.weather[0].icon}.png`} alt="wather-icon"/>
                            </span>
                                <span className="description text-capitalize">{list.weather[0].main}</span>
                            </div>
                            <div className="forcasting-right d-flex align-items-center justify-content-between">
                                <div className="d-flex flex-column px-3 px-md-0  key-value">
                                    <span className="key">Feels Like:</span>
                                    <span className="value">{getCentimeter(list.main.feels_like)}</span>
                                </div>
                                <div className="d-flex flex-column px-3 px-md-0 key-value">
                                    <span className="key">Humidity:</span>
                                    <span className="value">{list.main.humidity}%</span>

                                </div>
                                <div className="d-flex flex-column px-3 px-md-0 key-value">
                                    <span className="key">Wind(km/h):</span>
                                    <div className="value">{list.wind.speed}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                });

            }
            return null

    }

    renderDetails(){
        const {weather} = this.props.listing;
        const {loadingDetails} = this.props.listing;

        const sunrise = getTimeFormat( new Date(weather.sys.sunrise)) ;
        const sunset = getTimeFormat( new Date(weather.sys.sunset)) ;
        return (
            <div className="modal-content__container-weather text-white">
                <div className="modal-header text-center bg-primary border-0 text-white d-flex justify-content-center align-items-center">
                    {weather.name} Weather
                </div>
                <div className="row content-weather-details ">
                    <div className="col-12 col-md-5 d-flex justify-content-center align-items-center flex-column">
                        <span className="weather-icon">
                            <img src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`} alt="wather-icon" />
                        </span>
                        <span className="weather-temp">{parseInt(weather.main.temp -  273.15) } <sup>°C</sup></span>
                        <span className="description text-capitalize">{weather.weather[0].description}</span>

                        <div className="statistics py-5 w-100">
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Feels Like</div>
                                <div className="col-5 ml-auto">{getCentimeter(weather.main.feels_like)} </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Forecast</div>
                                <div className="col-5 ml-auto">{weather.weather[0].main}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Temp Min</div>
                                <div className="col-5 ml-auto">{getCentimeter(weather.main.temp_min)} </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Temp Max</div>
                                <div className="col-5 ml-auto">{getCentimeter(weather.main.temp_max)} </div>

                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Pressure</div>
                                <div className="col-5 ml-auto">{weather.main.pressure} mbar</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Humidity</div>
                                <div className="col-5 ml-auto">{weather.main.humidity}%</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Wind(km/h)</div>
                                <div className="col-5 ml-auto">{weather.wind.speed}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Sunrise</div>
                                <div className="col-5 ml-auto">{sunrise}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-5 ml-auto">Sunset</div>
                                <div className="col-5 ml-auto">{sunset}</div>
                            </div>

                        </div>

                    </div>
                    <div className="col-12 col-md-7 py-5 left-side">
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <h3>5 Days Forecast</h3>
                            <h4>{getDateFormat()}</h4>
                        </div>
                        { loadingDetails ? <Loading/> : this.renderForeCast() }

                    </div>
                </div>
            </div>
        );
    }

    render(){
        const {weather} = this.props.listing;
        return  weather.name ? this.renderDetails() : null;
    }


}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getWeatherDetails: (city) => dispatch(getWeatherDetails(city))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WeatherDetails);

