import { fetchUtil } from "../../utils/fetchUtils";

export const FETCHING_DVM = "FETCHING_DVM";
export const DVM_SUCCESS_TO = "DVM_SUCCESS_TO";
export const DVM_SUCCESS_FROM = "DVM_SUCCESS_FROM";

export const dvm = (city, to) => dispatch => {
    dispatch({ type: FETCHING_DVM, payload: true });
    fetchUtil({
        url: `dvm/getby?search=${city}`,
        method: "GET",
    })
    .then(res => {
        // console.log('there in actions then  ')
        if(to)
            dispatch({ type: DVM_SUCCESS_TO, payload: res });
        else
        dispatch({ type: DVM_SUCCESS_FROM, payload: res });
    })
    .catch(err => {
        // console.log('there in actions   ', err)
        const msg = {error_is : `Information for ${city} is not available.` };
        if(to)
            dispatch({ type: DVM_SUCCESS_TO, payload: msg });
        else
            dispatch({ type: DVM_SUCCESS_FROM, payload: msg });
    }).finally(() => {
    dispatch({ type: FETCHING_DVM, payload: false });
    });
};
