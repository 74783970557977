import React, { Component } from "react";
import "./AboutUs.scss";
import roody from "../../assets/images/about-us.png";

class AboutUs extends Component {
  render() {
    return (
      <div className="about-us">
        <div className="container py-5">
          {/* <div className="row">
            <div className="col-12 col-lg-6"> */}
              <div className="lp-services-row-img  position-relative">
                <img className="w-100" src={roody} alt="dvm" />
              </div>
            {/* </div>*/}
            <div className="mx-auto  full-content-height mt-0 text-white py-5"> 
              <h2 className="text-left primary-heading mb-5">About us</h2>
              <p className="text-left mb-4">
                Hi and welcome to Dynamic Living!
              </p>
              <p className="text-left mb-0">
                Headquartered in Fredericksburg, Virginia, Dynamic Living is an
                online platform that aims to be a one-stop solution for all your
                moving needs and requirements.
              </p>
              <p className="text-left mb-0">
                My name is Roody Labaze, the founder and CEO of Dynamic Living.
                As a child, I moved around quite frequently and never really
                stayed in a place longer than a year. Every time we moved, I had
                to re-embrace new friends, a new neighborhood, and a new school.
                This was a lifestyle I had come to accept. Years later, when I
                was operating as a licensed insurance agent, I realized just how
                many people were also dealing with the struggle and complexity
                of moving and relocating. I decided to help.
              </p>
              <p className="text-left">
                I started{" "}
                <b>
                  <i>Dynamic Living LLC</i>
                </b>{" "}
                to ease the process of moving for every individual and their
                family going forward.
              </p>
              <p className="text-left">
                At Dynamic Living, our <b>mission</b> is to make the whole
                moving in and out process less stressful while also saving time
                for those who need to relocate. Our goal is to become a
                customer-centric enterprise where everyone receives the finest
                user quality experience.
              </p>
              <p className="text-left">
                To us, every relocation and client is unique; thus, our ultimate{" "}
                <b>vision</b> is to provide a top user-based journey. This is by
                providing fast routes to the information you may be searching
                for on the website, making it easy and quick to find what you're
                looking for. This is also by providing easy access to the other
                businesses you will end up finding yourself in need of. We
                strive to be trailblazers in the moving market by providing a
                complete and satisfying user experience.
              </p>
            </div>
          </div>
        </div>
      // </div>
    );
  }
}

export default AboutUs;
