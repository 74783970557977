import {
    SEARCH_FLIGHT,
    SEARCH_SUGGESTION_LOADING,
    SEARCH_SUGGESTION,
    FLIGHTS_LISTING,
    GET_FLIGHTS_LOADING,
    FLIGHT_OFFERS,
    FLIGHTS_BOOKING_SUCCESS,
    FLIGHTS_BOOKING_LOADING,
} from "../actions";

const initialState = {
    searchFlight : {
        oneWay : true,
        arrival : '',
        travelClass : 'ECONOMY',
        departure : '',
        locationDeparture : '',
        locationArrival : '',
        adults : 1,
        children : 0,
        infants  : 0,
        nonStop: true,
        suggestion : [],
        defaultOptions : [],
        suggestionLoading : false
    },
    suggestionLoading : false,
    suggestion : [],
    getFlightLoading : false,
    flightListing : [],
    flightOffers : null,
    bookingLoading: false,
    bookingSuccess :null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_FLIGHT:
            return {
                ...state,
                searchFlight: action.payload
            }; case FLIGHT_OFFERS:
            return {
                ...state,
                flightOffers: action.payload
            };
        case SEARCH_SUGGESTION:
            return {
                ...state,
                suggestionLoading:false,
                suggestion: action.payload
            };
        case SEARCH_SUGGESTION_LOADING:
            return {
                ...state,
                suggestionLoading: action.payload
            };
        case GET_FLIGHTS_LOADING:
            return {
                ...state,
                getFlightLoading: action.payload
            };
        case FLIGHTS_LISTING:
            return {
                ...state,
                getFlightLoading : false,
                flightListing:  action.payload,
            };
            case FLIGHTS_BOOKING_LOADING:
            return {
                ...state,
                bookingLoading:  action.payload,
            };
        case FLIGHTS_BOOKING_SUCCESS:
            return {
                ...state,
                bookingLoading : false,
                bookingSuccess:  action.payload,
                flightOffers : null
            };
        default:
            return state;
    }
};
