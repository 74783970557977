import { fetchUtil } from "../../utils/fetchUtils";
import { errorToast } from "../../utils/toaster";
import States from "../../constant/states";

export const FETCHING = "FETCHING";
export const FETCHING_DETAILS = "FETCHING_DETAILS";
export const HOTEL_SUCCESS = "HOTEL_SUCCESS";
export const HOTEL_DETAILS_SUCCESS = "HOTEL_DETAILS_SUCCESS";
export const WEATHER_SUCCESS = "WEATHER_SUCCESS";
export const WEATHER_DETAILS_SUCCESS = "WEATHER_DETAILS_SUCCESS";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";
export const JOB_SUCCESS = "JOB_SUCCESS";
export const CRIME_SUCCESS = "CRIME_SUCCESS";
export const EDUCATION_SUCCESS = "EDUCATION_SUCCESS";
export const SALARY_SUCCESS = "SALARY_SUCCESS";
export const LEFT_PANEL_OPEN = "LEFT_PANEL_OPEN";
export const INFO_SUCCESS = "INFO_SUCCESS";
export const MOVES_SUCCESS = "MOVES_SUCCESS";
export const HOSPITAL_SUCCESS = "HOSPITAL_SUCCESS";
export const ENTERTAINMENTS_SUCCESS = "ENTERTAINMENTS_SUCCESS";
export const COMPANIES_LISTING_SUCCESS = "COMPANIES_LISTING_SUCCESS";
export const RESET_COMPANIES_LISTING_SUCCESS =
  "RESET_COMPANIES_LISTING_SUCCESS";
export const REALTORS_LISTING_SUCCESS = "REALTORS_LISTING_SUCCESS";
export const RESET_REALTORS_LISTING_SUCCESS = "RESET_REALTORS_LISTING_SUCCESS";
export const HISTORICAL_WEATHER_REQUEST = "HISTORICAL_WEATHER_REQUEST";
export const HISTORICAL_WEATHER_SUCCESS = "HISTORICAL_WEATHER_SUCCESS";
export const OCCUPATIONS_REQUEST = "OCCUPATIONS_REQUEST";
export const OCCUPATIONS_SUCCESS = "OCCUPATIONS_SUCCESS";

export const getWeather = (city) => (dispatch) => {
  dispatch({ type: FETCHING, payload: true });
  fetchUtil({
    url: `/weather?state=${city}`,
    method: "GET",
  })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const getWeatherDetails = (city) => (dispatch) => {
  dispatch({ type: FETCHING_DETAILS, payload: true });
  fetchUtil({
    url: `/weather/detail?state=${city}`,
    method: "GET",
  })
    .then((res) => {
      dispatch({ type: WEATHER_DETAILS_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING_DETAILS, payload: false });
    });
};

export const getHistoricalWeather = (city, season, end) => (dispatch) => {
  dispatch({ type: HISTORICAL_WEATHER_REQUEST, payload: true });
  fetchUtil({
    // url: `weather/history?access_key=fb5c6dd1f851a738aedbc4c02fcf028d&state_name=${city}&historical_date_start=${start}&historical_date_end=${end}`,
    url: `weather/fiveYearRecord?state_name=${city}&season=${season}`,
    method: "GET",
  })
    .then((res) => {
      dispatch({ type: HISTORICAL_WEATHER_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: HISTORICAL_WEATHER_REQUEST, payload: false });
    });
};

export const getHotel = (city) => (dispatch) => {
  dispatch({ type: FETCHING, payload: true });
  fetchUtil({
    url: `hotels?state=${city}`,
    method: "GET",
    showMessage: false,
  })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const getHotelDetails = (id) => (dispatch) => {
  dispatch({ type: FETCHING_DETAILS, payload: true });
  fetchUtil({
    url: `hotel/detail/?id=${id}`,
    method: "GET",
  })
    .then((res) => {
      dispatch({ type: HOTEL_DETAILS_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING_DETAILS, payload: false });
    });
};

export const getHistory = (name, filterBy) => (dispatch) => {
  let queryString = "";
  if (filterBy === "city") {
    States.find((states) => {
      const state = states.cities.find((city) => city.name === name);
      if (state) {
        queryString = `?stateCode=${states.state}&city=${state.name}`;
        return states;
      }
    });
  } else {
    const state = States.find((state) => state.name === name);
    if (state) {
      queryString = `?stateCode=${state.state}`;
    }
  }
  dispatch({ type: FETCHING, payload: true });
  fetchUtil({
    url: `history/get_by_state_code${queryString}`,
    method: "GET",
    showMessage:false,
  })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const getSalary = (city) => (dispatch) => {
  dispatch({ type: FETCHING, payload: true });
  fetchUtil({
    url: `salary/getby?search=${city}`,
    method: "GET",
    showMessage: false,
  })
    .then((res) => {
      dispatch({ type: SALARY_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

// export const getJobs = (city) => (dispatch) => {
//   dispatch({ type: FETCHING, payload: true });
//   fetchUtil({
//     url: `jobs?state=${city}`,
//     method: "GET",
//   })
//     .then((res) => {
//       dispatch({ type: JOB_SUCCESS, payload: res });
//     })
//     .catch((err) => {
//       errorToast(err.message);
//     })
//     .finally(() => {
//       dispatch({ type: FETCHING, payload: false });
//     });
// };

export const getJobs =
  (location, keywords, page = 1) =>
  (dispatch) => {
    dispatch({ type: FETCHING, payload: true });
    fetchUtil({
      url: `jobs?location=${location}${
        keywords ? `&keywords=${keywords}` : ""
      }&page=${page}`,
      method: "GET",
      showMessage: false,
    })
      .then((res) => {
        dispatch({ type: JOB_SUCCESS, payload: res });
      })
      .catch((err) => {
        errorToast(err.message);
      })
      .finally(() => {
        dispatch({ type: FETCHING, payload: false });
      });
  };

export const getCrime = (name, filterBy) => (dispatch) => {
  let state = "";
  if (filterBy === "city") {
    States.find((states) => {
      state = states.cities.find((city) => city.name === name);
      if (state) return states;
    });
  } else {
    state = States.find((state) => state.name === name);
  }

  if (!state && !state.latitude) return null;

  dispatch({ type: FETCHING, payload: true });
  fetchUtil({
    url: `crime?lat=${state.latitude}&lon=${state.longitude}`,
    method: "GET",
    showMessage: false,
  })
    .then((res) => {
      dispatch({ type: CRIME_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const leftPanelToggle = (bol) => (dispatch) => {
  dispatch({ type: LEFT_PANEL_OPEN, payload: bol });
};

export const getInfo = (city) => (dispatch) => {
  dispatch({ type: FETCHING, payload: true });
  fetchUtil({
    url: `info/getby?search=${city}`,
    method: "GET",
  })
    .then((res) => {
      dispatch({ type: INFO_SUCCESS, payload: res });
    })
    .catch((err) => {
      dispatch({ type: INFO_SUCCESS, payload: {} });
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const getMoves = (city) => (dispatch) => {
  dispatch({ type: FETCHING, payload: true });
  fetchUtil({
    url: `migrate/getby?search=${city}`,
    method: "GET",
  })
    .then((res) => {
      dispatch({ type: MOVES_SUCCESS, payload: res });
    })
    .catch((err) => {
      dispatch({ type: INFO_SUCCESS, payload: {} });
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

// export const getHospitals = (name, filterBy) => dispatch => {
//     let state = '';
//     if(filterBy === 'city'){
//         States.find((states) => {
//             state =  states.cities.find(city => city.name === name)
//             if(state)
//                 return states;
//         } );
//     }
//     else {
//         state = States.find(state => state.name === name);
//     }
//
//     if (!state && !state.state)
//         return null;
//
//     dispatch({ type: FETCHING, payload: true });
//     let url = ''
//     if(state.state){
//         url =   `hospitals?state=${state.state}`
//     }
//     else {
//         url =   `hospitals?city=${state.name}`
//     }
//     fetchUtil({
//         url,
//         method: "GET"
//     })
//         .then(res => {
//             console.log('getHopital res..........', res);
//             dispatch({ type: HOSPITAL_SUCCESS, payload: res });
//         })
//         .catch(err => {
//             errorToast(err.message);
//         }).finally(() => {
//         dispatch({ type: FETCHING, payload: false });
//     });
//
// };

export const getHospitals = (name, filterBy) => (dispatch) => {
  let state = "";
  if (filterBy === "city") {
    States.find((states) => {
      state = states.cities.find((city) => city.name === name);
      if (state) return states;
    });
  } else {
    state = States.find((state) => state.name === name);
  }

  if (!state && !state.state) return null;

  dispatch({ type: FETCHING, payload: true });
  let url = "";
  if (state.state) {
    url = `entertainments?state=${state.state}&type=Hospital`;
  } else {
    url = `entertainments?city=${state.name}&type=Hospital`;
  }
  fetchUtil({
    url,
    method: "GET",
    showMessage: false,
  })
    .then((res) => {
      dispatch({ type: HOSPITAL_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};

export const getEntertainments =
  (name, filterBy, categories = "Amusement Park") =>
  (dispatch) => {
    let state = "";
    if (filterBy === "city") {
      States.find((states) => {
        state = states.cities.find((city) => city.name === name);
        if (state) return states;
      });
    } else {
      state = States.find((state) => state.name === name);
    }

    if (!state && !state.state) return null;

    dispatch({ type: FETCHING, payload: true });
    let url = "";
    if (state.state) {
      url = `entertainments?state=${state.state}&type=${categories}`;
    } else {
      url = `entertainments?city=${state.name}&type=${categories}`;
    }
    fetchUtil({
      url,
      method: "GET",
      showMessage: false,
    })
      .then((res) => {
        dispatch({ type: ENTERTAINMENTS_SUCCESS, payload: res });
      })
      .catch((err) => {
        errorToast(err.message);
      })
      .finally(() => {
        dispatch({ type: FETCHING, payload: false });
      });
  };

export const getCompaniesListing = (type = "junk", filterBy, name, page = 1, limit = 30, cursor= 0) =>
  (dispatch) => {
    let url = `${type}?curser=${cursor}&limit=${limit}`;
    // console.log(url, "....url");
    if (filterBy === "city") {
      States.find((states) => {
        const state = states.cities.find((city) => city.name === name);
        if (state) {
          url = `${url}&city=${state?.name}`;
          return states;
        }
      });
    } else {
      const state = States.find((state) => state.name === name);
      url = `${url}&state=${state?.state}`;
    }

    dispatch({ type: FETCHING, payload: true, page });

    fetchUtil({
      url,
      method: "GET",
    showMessage: false,
    })
      .then((res) => {
        dispatch({ type: COMPANIES_LISTING_SUCCESS, payload: res, page });
      })
      .catch((err) => {
        errorToast(err.message);
      })
      .finally(() => {
        dispatch({ type: FETCHING, payload: false });
      });
  };
export const resetCompaniesListing = () => (dispatch) => {
  dispatch({ type: RESET_COMPANIES_LISTING_SUCCESS });
};

export const getRealtors = (name, filterBy) => (dispatch) => {
  let queryString = "";
  if (filterBy === "city") {
    States.find((states) => {
      const state = states.cities.find((city) => city.name === name);
      if (state) {
        queryString = `?stateCode=${states.state}&city=${state.name}`;
        return states;
      }
    });
  } else {
    const state = States.find((state) => state.name === name);
    if (state) {
      queryString = `?stateCode=${state.state}`;
    }
  }

  dispatch({ type: FETCHING, payload: true });
  let baseUrl = `realtor/getBy/${queryString}`;
  fetchUtil({
    url: baseUrl,
    method: "GET",
  })
    .then((res) => {
      // console.log("res..........", res);
      dispatch({ type: REALTORS_LISTING_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: FETCHING, payload: false });
    });
};
export const resetRealtors = () => (dispatch) => {
  dispatch({ type: RESET_REALTORS_LISTING_SUCCESS });
};

export const getAllOccupations = () => (dispatch) => {
  dispatch({ type: OCCUPATIONS_REQUEST, payload: true });
  fetchUtil({
    url: "occupations/getAll",
    method: "GET",
  })
    .then((res) => {
      dispatch({ type: OCCUPATIONS_SUCCESS, payload: res });
    })
    .catch((err) => {
      errorToast(err.message);
    })
    .finally(() => {
      dispatch({ type: OCCUPATIONS_REQUEST, payload: false });
    });
};
