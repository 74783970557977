import React, { Component } from "react";
import {connect} from "react-redux";
import "./HistroyDetails.scss";


class HistroyDetails extends Component{

    historyMap(key, value){
        return value ?
            <div className="description-line py-3 row">
                <div className='col-6  key'>{key}:</div>
                <div className='col-6  value'>
                    {
                        key === 'Flag' ?
                            <img className="flag" src={value} alt={key} /> :
                            value

                    }
                </div>
            </div>
            :
            null;
    }


    render(){
        const {listing, city} = this.props;
        const history = listing.history;
        if(history.objectId){
            return <div className="history-listing-details-modal text-white">
                    <div className="modal-header-heading  bg-primary d-flex justify-content-center align-items-center">
                        History about "{city}"
                    </div>

                <div className="px-0 px-md-5 mx-5 my-4 font-secondary">
                    {this.historyMap('Name', history?.name)}
                    {this.historyMap('Flag', history?.flag)}
                    {this.historyMap('Capital', history?.capital)}
                    {this.historyMap('City', history?.city)}
                    {this.historyMap('Country Name', history?.county_name)}
                    {this.historyMap('Largest Name', history?.largestCity)}
                    {this.historyMap('Density', history?.density)}
                    {this.historyMap('Established', history?.established)}
                    {this.historyMap('In Corporated', history?.incorporated)}
                    {this.historyMap('Military', history?.military)}
                    {this.historyMap('Number Representatives', history?.numberRepresentatives)}
                    {this.historyMap('Population', history?.population)}
                    {this.historyMap('Ranking', history?.ranking)}
                    {this.historyMap('State Id', history?.state_id)}
                    {this.historyMap('State Name', history?.state_name)}
                    {this.historyMap('State Population', history?.state_population)}
                    {this.historyMap('land Area Square Kilometers', history?.landAreaSquareKilometers)}
                    {this.historyMap('total Area Square Kilometers', history?.totalAreaSquareKilometers)}
                    {this.historyMap('Water Area Square Kilometers', history?.waterAreaSquareKilometers)}
                    {this.historyMap('Zips', history?.zips)}

                </div>
            </div>;
        }
        return null;

    }
}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});


export default connect(
    mapStateToProps,
    null
)(HistroyDetails);

