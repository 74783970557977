import React, { Component } from "react";
import "./Salary.scss";
import Loading from "../../../components/Loading/Loading";
import { getAllOccupations, getSalary } from "../../../store/actions";
import { currency, getQueryString, getFilterBY } from "../../../utils/helper";
import { connect } from "react-redux";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import SalaryDetails from "./SalaryDetails";
import { Select } from "antd";

const { Option } = Select;
class Salary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      detailsId: null,
      filterBy: "",
      name: "",
      occupations: "",
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    const { location, listing } = this.props;
    if (!listing.occupationsListing?.length) {
      // console.log('listing...........', listing);
      this.props.getAllOccupations();
    }
    let filterBy = "",
      name = "";
    if (location.search) {
      filterBy = getFilterBY(location.search);
      name = getQueryString(location.search, filterBy);
    }
    // TODO  temporary check  filter by city
    // if (name && filterBy === 'state') {
    //     this.props.getSalary(name);
    // }
    this.setState({
      filterBy,
      name,
    });
  }

  componentDidUpdate(nextProps) {
    const { location } = nextProps.history;
    const { name } = this.state;
    let nextName = null;
    let filterBy = "";
    if (location.search) {
      filterBy = getFilterBY(location.search);
      nextName = getQueryString(location.search, filterBy);
    }
    if (nextName && name !== nextName) {
      // if(filterBy === 'state'){
      //     this.props.getSalary(nextName);
      // }
      if (this.state?.occupations) {
        this.getData(this.state.occupations);
      }

      this.setState({
        filterBy,
        name: nextName,
      });
    }
  }

  toggleModal() {
    const modalOpened = this.state.modalOpened;
    this.setState({
      modalOpened: !modalOpened,
    });
  }

  onChangeOccupations = (e) => {
    // console.log("e................", e);
    this.setState({
      occupations: e,
    });
    this.getData(e);
  };

  getData = (value) => {
    this.props.getSalary(value, this.state.name, this.state.filterBy);
  };

  salaryDetails() {
    // TODO temporary check
    const { name, filterBy } = this.state;
    //
    // if(filterBy === 'city'){
    //     return <h5 className="p-5 text-white display-4">{name} will be soon data</h5>
    // }

    const { salary } = this.props.listing;
    let data = null;
    if (salary?.salary?.length) {
      data = salary?.salary[0];
    }

    // console.log("salary.............", salary);
    if (!data)
      return <p className="text-center my-4 text-white">No Data Found</p>;

    return (
      <div className="info-listing__wrapper mt-4">
        <ul className="bullet">
          <li className="text-white d-flex">
            <div className="w-50">OccupationCode:</div>
            <div className="w-50">{data.OccupationCode}</div>
          </li>
          <li className="text-white d-flex">
            <div className="w-50">Average Annual Salary:</div>
            <div className="w-50">{currency(data.AverageAnnualSalary)}</div>
          </li>
          <li className="text-white d-flex">
            <div className="w-50">Total Employees:</div>
            <div className="w-50">{data.TotalEmployees}</div>
          </li>
        </ul>

        {/*<div className="d-flex justify-content-center mt-4">*/}
        {/*    <button className="btn btn-primary" onClick= {this.toggleModal}>More Details</button>*/}
        {/*</div>*/}
      </div>
    );
  }

  render() {
    const { listing } = this.props,
      { loading, occupationsLoading, occupationsListing } = listing,
      modalOpened = this.state.modalOpened;
    const { name } = this.state;

    // console.log("occupationsListing........", occupationsListing);
    return (
      <div className="info-details">
        <SidePanel>
          <div className="info-listing__wrapper">
            <h3 className="primary-heading text-white m-0 py-3 py-md-5 heading pl-5">
              Salary Information
            </h3>

            {occupationsLoading ? (
              <Loading />
            ) : occupationsListing?.rows?.length ? (
              <div className="text-center w-100 salary-select">
                <Select
                  showSearch
                  className="m-auto select-occupations"
                  // value={this.state.occupations}
                  defaultActiveFirstOption={true}
                  optionFilterProp="children"
                  showArrow={true}
                  // filterOption={false}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // filterSort={(optionA, optionB) =>
                  //   optionA.children
                  //     .toLowerCase()
                  //     .localeCompare(optionB.children.toLowerCase())
                  // }
                  onChange={this.onChangeOccupations}
                  placeholder="Please select Occupations"
                >
                  <Option hidden>Please select Occupations</Option>
                  {occupationsListing.rows.map((o) => {
                    return (
                      <Option key={o.Code} value={o.Code}>
                        {o.Name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : (
              <p>Not found Occupations listing </p>
            )}

            {this.state.occupations ? (
              loading ? (
                <Loading />
              ) : (
                this.salaryDetails()
              )
            ) : null}
          </div>
        </SidePanel>
        {modalOpened && (
          <Module
            id="salary"
            modalOpened={modalOpened}
            closeModal={this.toggleModal}
          >
            <SalaryDetails city={name} />
          </Module>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listing: state.listing,
  golbal: state.golbal,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalary: (code, city, filterBy) =>
      dispatch(getSalary(code, city, filterBy)),
    getAllOccupations: () => dispatch(getAllOccupations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Salary);
