let States = [
    {
        "state":"AK",
        "latitude":"64.2008",
        "longitude":"-149.4937",
        "name":"Alaska",
        "cities":[
            {
                "name":"Juneau",
                "latitude":"58.305801",
                "longitude":"-134.433304"
            }
        ]
    },
    {
        "state":"AL",
        "latitude":"32.318231",
        "longitude":"-86.902298",
        "name":"Alabama",
        "cities":[
            {
                "name":"Montgomery",
                "latitude":"32.3792",
                "longitude":"-86.279167."
            }
        ]
    },
    {
        "state":"AR",
        "latitude":"35.20105",
        "longitude":"-91.831833",
        "name":"Arkansas",
        "cities":[
            {
                "name":"Little Rock",
                "latitude":"34.7465",
                "longitude":"-92.2896"
            }
        ]
    },
    {
        "state":"AZ",
        "latitude":"34.048928",
        "longitude":"-111.093731",
        "name":"Arizona",
        "cities":[
            {
                "name":"Phoenix",
                "latitude":"33.4484",
                "longitude":"-112.0740"
            }
        ]
    },
    {
        "state":"CA",
        "latitude":"36.778261",
        "longitude":"-119.417932",
        "name":"California",
        "cities":[
            {
                "name":"Sacramento",
                "latitude":"38.5816",
                "longitude":"-121.4944"
            }
        ]
    },
    {
        "state":"CO",
        "latitude":"39.550051",
        "longitude":"-105.782067",
        "name":"Colorado",
        "cities":[
            {
                "name":"Denver",
                "latitude":"39.7392",
                "longitude":"-104.9903"
            }
        ]
    },
    {
        "state":"CT",
        "latitude":"41.603221",
        "longitude":"-73.087749",
        "name":"Connecticut",
        "cities":[
            {
                "name":"Hartford",
                "latitude":"41.7658",
                "longitude":"-72.6734"
            }
        ]
    },
    {
        "state":"DC",
        "latitude":"38.905985",
        "longitude":"-77.033418",
        "name":"District of Columbia",
        "cities":[
            {
                "name":"District of Columbia",
                "latitude":"38.905985",
                "longitude":"-77.033418"
            }
        ]
    },
    {
        "state":"DE",
        "latitude":"38.910832",
        "longitude":"-75.52767",
        "name":"Delaware",
        "cities":[
            {
                "name":"Dover",
                "latitude":"39.1582",
                "longitude":"-75.5244"
            }
        ]
    },
    {
        "state":"FL",
        "latitude":"27.664827",
        "longitude":"-81.515754",
        "name":"Florida",
        "cities":[
            {
                "name":"Tallahassee",
                "latitude":"30.4383",
                "longitude":"-84.2807"
            }
        ]
    },
    {
        "state":"GA",
        "latitude":"32.157435",
        "longitude":"-82.907123",
        "name":"Georgia",
        "cities":[
            {
                "name":"Atlanta",
                "latitude":"33.7490",
                "longitude":"-84.3880"
            }
        ]
    },
    {
        "state":"HI",
        "latitude":"19.898682",
        "longitude":"-155.665857",
        "name":"Hawaii",
        "cities":[
            {
                "name":"Honolulu",
                "latitude":"21.3069",
                "longitude":"-157.8583"
            }
        ]
    },
    {
        "state":"IA",
        "latitude":"41.878003",
        "longitude":"-93.097702",
        "name":"Iowa",
        "cities":[
            {
                "name":"Des Moines",
                "latitude":"41.5868",
                "longitude":"-93.6250"
            }
        ]
    },
    {
        "state":"ID",
        "latitude":"44.068202",
        "longitude":"-114.742041",
        "name":"Idaho",
        "cities":[
            {
                "name":"Boise",
                "latitude":"43.6150",
                "longitude":"-116.2023"
            }
        ]
    },
    {
        "state":"IL",
        "latitude":"40.633125",
        "longitude":"-89.398528",
        "name":"Illinois",
        "cities":[
            {
                "name":"Springfield",
                "latitude":"39.7817",
                "longitude":"-89.6501"
            }
        ]
    },
    {
        "state":"IN",
        "latitude":"40.551217",
        "longitude":"-85.602364",
        "name":"Indiana",
        "cities":[
            {
                "name":"Indianapolis",
                "latitude":"39.7684",
                "longitude":"-86.1581"
            }
        ]
    },
    {
        "state":"KS",
        "latitude":"39.011902",
        "longitude":"-98.484246",
        "name":"Kansas",
        "cities":[
            {
                "name":"Topeka",
                "latitude":"39.0473",
                "longitude":"-95.6752"
            }
        ]
    },
    {
        "state":"KY",
        "latitude":"37.839333",
        "longitude":"-84.270018",
        "name":"Kentucky",
        "cities":[
            {
                "name":"Frankfort",
                "latitude":"38.2009",
                "longitude":"-84.8733"
            }
        ]
    },
    {
        "state":"LA",
        "latitude":"31.244823",
        "longitude":"-92.145024",
        "name":"Louisiana",
        "cities":[
            {
                "name":"Baton Rouge",
                "latitude":"30.4515",
                "longitude":"-91.1871"
            }
        ]
    },
    {
        "state":"MA",
        "latitude":"42.407211",
        "longitude":"-71.382437",
        "name":"Massachusetts",
        "cities":[
            {
                "name":"Boston",
                "latitude":"42.3601",
                "longitude":"-71.0589"
            }
        ]
    },
    {
        "state":"MD",
        "latitude":"39.045755",
        "longitude":"-76.641271",
        "name":"Maryland",
        "cities":[
            {
                "name":"Annapolis",
                "latitude":"38.9784",
                "longitude":"-76.4922"
            }
        ]
    },
    {
        "state":"ME",
        "latitude":"45.253783",
        "longitude":"-69.445469",
        "name":"Maine",
        "cities":[
            {
                "name":"Augusta",
                "latitude":"33.4735",
                "longitude":"-82.0105"
            }
        ]
    },
    {
        "state":"MI",
        "latitude":"44.314844",
        "longitude":"-85.602364",
        "name":"Michigan",
        "cities":[
            {
                "name":"Lansing",
                "latitude":"42.7325",
                "longitude":"-84.5555"
            }
        ]
    },
    {
        "state":"MN",
        "latitude":"46.729553",
        "longitude":"-94.6859",
        "name":"Minnesota",
        "cities":[
            {
                "name":"St. Paul",
                "latitude":"44.9537",
                "longitude":"-93.0900"
            }
        ]
    },
    {
        "state":"MO",
        "latitude":"37.964253",
        "longitude":"-91.831833",
        "name":"Missouri",
        "cities":[
            {
                "name":"Jefferson City",
                "latitude":"38.5767",
                "longitude":"-92.1735"
            }
        ]
    },
    {
        "state":"MS",
        "latitude":"32.354668",
        "longitude":"-89.398528",
        "name":"Mississippi",
        "cities":[
            {
                "name":"Jackson",
                "latitude":"32.2988",
                "longitude":"-90.1848"
            }
        ]
    },
    {
        "state":"MT",
        "latitude":"46.879682",
        "longitude":"-110.362566",
        "name":"Montana",
        "cities":[
            {
                "name":"Helena",
                "latitude":"46.5891",
                "longitude":"-112.0391"
            }
        ]
    },
    {
        "state":"NC",
        "latitude":"35.759573",
        "longitude":"-79.0193",
        "name":"North Carolina",
        "cities":[
            {
                "name":"Raleigh",
                "latitude":"35.7796",
                "longitude":"-78.6382"
            }
        ]
    },
    {
        "state":"ND",
        "latitude":"47.551493",
        "longitude":"-101.002012",
        "name":"North Dakota",
        "cities":[
            {
                "name":"Bismarck",
                "latitude":"46.8083",
                "longitude":"-100.7837"
            }
        ]
    },
    {
        "state":"NE",
        "latitude":"41.492537",
        "longitude":"-99.901813",
        "name":"Nebraska",
        "cities":[
            {
                "name":"Lincoln",
                "latitude":"53.2307",
                "longitude":"-0.5406"
            }
        ]
    },
    {
        "state":"NH",
        "latitude":"43.193852",
        "longitude":"-71.572395",
        "name":"New Hampshire",
        "cities":[
            {
                "name":"Concord",
                "latitude":"37.9780",
                "longitude":"-122.0311"
            }
        ]
    },
    {
        "state":"NJ",
        "latitude":"40.058324",
        "longitude":"-74.405661",
        "name":"New Jersey",
        "cities":[
            {
                "name":"Trenton",
                "latitude":"40.2206",
                "longitude":"-74.7597"
            }
        ]
    },
    {
        "state":"NM",
        "latitude":"34.97273",
        "longitude":"-105.032363",
        "name":"New Mexico",
        "cities":[
            {
                "name":"Santa Fe",
                "latitude":"35.6870",
                "longitude":"-105.9378"
            }
        ]
    },
    {
        "state":"NV",
        "latitude":"38.80261",
        "longitude":"-116.419389",
        "name":"Nevada",
        "cities":[
            {
                "name":"Carson City",
                "latitude":"39.1638",
                "longitude":"-119.7674"
            }
        ]
    },
    {
        "state":"NY",
        "latitude":"43.299428",
        "longitude":"-74.217933",
        "name":"New York",
        "cities":[
            {
                "name":"Albany",
                "latitude":"42.6526",
                "longitude":"-73.7562"
            }
        ]
    },
    {
        "state":"OH",
        "latitude":"40.417287",
        "longitude":"-82.907123",
        "name":"Ohio",
        "cities":[
            {
                "name":"Columbus",
                "latitude":"39.9612",
                "longitude":"-82.9988"
            }
        ]
    },
    {
        "state":"OK",
        "latitude":"35.007752",
        "longitude":"-97.092877",
        "name":"Oklahoma",
        "cities":[
            {
                "name":"Oklahoma City",
                "latitude":"35.4676",
                "longitude":"-97.5164"
            }
        ]
    },
    {
        "state":"OR",
        "latitude":"43.804133",
        "longitude":"-120.554201",
        "name":"Oregon",
        "cities":[
            {
                "name":"Salem",
                "latitude":"11.6643",
                "longitude":"-78.1460"
            }
        ]
    },
    {
        "state":"PA",
        "latitude":"41.203322",
        "longitude":"-77.194525",
        "name":"Pennsylvania",
        "cities":[
            {
                "name":"Harrisburg",
                "latitude":"40.2732",
                "longitude":"-76.8867"
            }
        ]
    },
    {
        "state":"PR",
        "latitude":"18.220833",
        "longitude":"-66.590149",
        "name":"Puerto Rico",
        "cities":[
            {
                "name":"San Juan",
                "latitude":"18.4655",
                "longitude":"-66.1057"
            }
        ]
    },
    {
        "state":"RI",
        "latitude":"41.580095",
        "longitude":"-71.477429",
        "name":"Rhode Island",
        "cities":[
            {
                "name":"Providence",
                "latitude":"41.8240",
                "longitude":"-71.4128"
            }
        ]
    },
    {
        "state":"SC",
        "latitude":"33.836081",
        "longitude":"-81.163725",
        "name":"South Carolina",
        "cities":[
            {
                "name":"Columbia",
                "latitude":"4.5709",
                "longitude":"-74.2973"
            }
        ]
    },
    {
        "state":"SD",
        "latitude":"43.969515",
        "longitude":"-99.901813",
        "name":"South Dakota",
        "cities":[
            {
                "name":"Pierre",
                "latitude":"46.7758",
                "longitude":"-56.1806"
            }
        ]
    },
    {
        "state":"TN",
        "latitude":"35.517491",
        "longitude":"-86.580447",
        "name":"Tennessee",
        "cities":[
            {
                "name":"Nashville",
                "latitude":"36.1627",
                "longitude":"-86.7816"
            }
        ]
    },
    {
        "state":"TX",
        "latitude":"31.968599",
        "longitude":"-99.901813",
        "name":"Texas",
        "cities":[
            {
                "name":"Austin",
                "latitude":"30.2672",
                "longitude":" -97.7431"
            }
        ]
    },
    {
        "state":"UT",
        "latitude":"39.32098",
        "longitude":"-111.093731",
        "name":"Utah",
        "cities":[
            {
                "name":"Salt Lake City",
                "latitude":"40.7608",
                "longitude":"-111.8910"
            }
        ]
    },
    {
        "state":"VA",
        "latitude":"37.431573",
        "longitude":"-78.656894",
        "name":"Virginia",
        "cities":[
            {
                "name":"Richmond",
                "latitude":"37.5407",
                "longitude":"-77.4360"
            }
        ]
    },
    {
        "state":"VT",
        "latitude":"44.558803",
        "longitude":"-72.577841",
        "name":"Vermont",
        "cities":[
            {
                "name":"Montpelier",
                "latitude":"44.2601",
                "longitude":"-72.5754"
            }
        ]
    },
    {
        "state":"WA",
        "latitude":"47.751074",
        "longitude":"-120.740139",
        "name":"Washington",
        "cities":[
            {
                "name":"Olympia",
                "latitude":"47.0379",
                "longitude":"-122.9007"
            }
        ]
    },
    {
        "state":"WI",
        "latitude":"43.78444",
        "longitude":"-88.787868",
        "name":"Wisconsin",
        "cities":[
            {
                "name":"Madison",
                "latitude":"43.0731",
                "longitude":"-89.4012"
            }
        ]
    },
    {
        "state":"WV",
        "latitude":"38.597626",
        "longitude":"-80.454903",
        "name":"West Virginia",
        "alternateName" : "Webster Springs",
        "cities":[
            {
                "name":"Charleston",
                "latitude":"32.7765",
                "longitude":"-79.9311"
            }
        ]
    },
    {
        "state":"WY",
        "latitude":"43.075968",
        "longitude":"-107.290284",
        "name":"Wyoming",
        "cities":[
            {
                "name":"Cheyenne",
                "latitude":"41.1400",
                "longitude":"-104.8202"
            }
        ]
    }
];

export default States;
