import React, { Component } from "react";
import Loading from "../../../components/Loading/Loading";
import {getMoves} from "../../../store/actions";
import {getQueryString, isMillion, getFilterBY} from "../../../utils/helper";
import {connect} from "react-redux";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import MoveDetails from "./MoveDetails";


class Move extends Component{

    constructor(props) {
        super(props);
        this.state = {
            modalOpened : false,
            filterBy : '',
            name: ''
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        let filterBy = '',
            name = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            name = getQueryString(location.search, filterBy);
        }
        // TODO  temporary check  filter by city
        if (name && filterBy === 'state') {
            this.props.getMoves(name);
        }
        this.setState({
            filterBy, name
        })
    }

    componentDidUpdate(nextProps) {
        const {location} = nextProps.history;
        const {name} = this.state;
        let nextName = null;
        let filterBy = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            nextName = getQueryString(location.search, filterBy);
        }
        if (nextName && name !== nextName) {
            if(filterBy === 'state'){
                this.props.getMoves(nextName);
            }
            this.setState({
                filterBy,
                name : nextName
            })
        }
    }

    toggleModal(){
        const modalOpened = this.state.modalOpened;
        this.setState({
            modalOpened : !modalOpened
        })
    }

    renderBoundTr(array){
        return array.map((obj)=>{
            return (
                <tr>
                    <td>{obj.INBOUND}</td>
                    <td>{obj.TYPE}</td>
                    <td>{obj.OUTBOUND}</td>
                </tr>
            )
        })

    }

    renderTableBound(key, title){
        return ( <table>
                <thead>
                <tr>
                    <th>Inbound</th>
                    <th>{title}</th>
                    <th>Outbound</th>
                </tr>
                </thead>
                <tbody>
                {this.renderBoundTr(key)}
                </tbody>
            </table>
        )
    }


    renderCard(card, heading, title,  id){
        return card && card.length ?
            <div className="card">
                <div className="card-header">
                    <a className="card-link d-flex justify-content-between collapsed" data-toggle="collapse" href={`#${id}`}>
                        {heading}
                        <i className="icon-plus-circle center-icon"></i>
                        <i className="icon-minus-circle center-icon"></i>
                    </a>
                </div>
                <div id={id} className="collapse" data-parent="#accordion">
                    <div className="card-body">
                        {this.renderTableBound(card, title)}
                    </div>
                </div>
            </div>
            : null;

    }


    infoDetails(){
        // TODO temporary check
        const { name, filterBy } = this.state;

        if(filterBy === 'city'){
            return <h5 className="p-5 text-white display-4">{name} will be soon data</h5>
        }

       const {moves} = this.props.listing;
        if(!moves || !moves.migrate)
            return null;

        const migrate = moves.migrate ;
        return (
            <div className="info-listing__wrapper">
                <ul className="bullet">
                    <li className="text-white d-flex" >
                        <div className="w-50">
                            State Name:
                        </div>
                        <div className="w-50">
                            {migrate.stateName}
                        </div>
                    </li>
                    <li className="text-white d-flex" >
                        <div className="w-50">
                            City Code:
                        </div>
                        <div className="w-50">
                            {migrate.cityCode}
                        </div>
                    </li>
                  {/*  <li className="text-white d-flex"  >
                        <div className="w-50">
                            Population:
                        </div>
                        <div className="w-50">
                            {isMillion(migrate.population)}
                        </div>
                    </li>
                    <li className="text-white d-flex" >
                        <div className="w-50">
                            Moves In:
                        </div>
                        <div className="w-50">
                            {isMillion(migrate.in)}
                        </div>
                    </li>
                    <li className="text-white d-flex" >
                        <div className="w-50">
                            Moves Out:
                        </div>
                        <div className="w-50">
                            {isMillion(migrate.out)}
                        </div>
                    </li>*/}

                </ul>

                    <div className=" px-3 px-md-4">
                        <div id="accordion" className="bound-accordion mb-0">
                            {this.renderCard(migrate.Primary_Reason_for_Moving, 'Primary Reason for Moving', 'Reason',  'primary-reason')}
                            {this.renderCard(migrate.Age_Ranges, 'Age Ranges', 'Age','age-ranges')}
                            {this.renderCard(migrate.Income, 'Income', 'Income', 'income')}
                        </div>
                    </div>


                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-primary" onClick= {this.toggleModal}>More Details</button>
                </div>
            </div>
        )
    }

    render() {

        const {listing } = this.props,
            { loading} = listing,
            modalOpened = this.state.modalOpened;
        const { name } = this.state;


        return (
            <div>
                <SidePanel>
                    <div className="info-details">
                        <h3 className="primary-heading text-white m-0 py-3 py-md-5 heading pl-5">Stats {name}</h3>
                        {
                            loading ? <Loading /> : this.infoDetails()
                        }
                    </div>
                </SidePanel>
                {
                    modalOpened &&   <Module id="move" modalOpened={modalOpened} closeModal={this.toggleModal}>
                        <MoveDetails city={name}/>
                    </Module>
                }
            </div>



        );
    }
}




const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});
const mapDispatchToProps = dispatch => {
    return {
        getMoves: (city) => dispatch(getMoves(city))
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Move);

