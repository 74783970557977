import React, { Component } from "react";
import "./WebHeader.scss";
import { connect } from "react-redux";
import logo from "../../assets/images/dl-logo.gif";

import { Link, NavLink } from "react-router-dom";
import { designation, landingPageToggle, logout } from "../../store/actions";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

class WebHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
      modal: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  logout = () => {
    this.props.logout();
  };

  render() {
    const { loggedIn } = this.props.auth;
    const { menuActive } = this.state;

    const isGuest = localStorage.getItem("user");

    return (
      <>
        <header
          id="landing-header"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="header-container  align-items-center d-flex justify-content-center">
            <NavLink to="/home" className=" d-flex justify-content-start logo">
              <img src={logo} alt="dynamic" className="w-100" />
            </NavLink>
            <div
              className={`d-flex justify-content-center align-items-center nav-link ${
                menuActive ? "active" : ""
              }`}
            >
              <ul className="m-0 main-menu">
                <li>
                  <NavLink exact to="/" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                {isGuest !== "guest" && (
                  <li>
                    DMV
                    <ul className="nested">
                      <li>
                        <NavLink to="/license-change">license change</NavLink>
                      </li>
                      <li>
                        <NavLink to="/vehicle-registration-change">
                          vehicle registration change
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}

                <li onClick={() => this.props.landingPageToggle(true)}>
                  <NavLink exact to="/realtors" activeClassName="active">
                    Realtors
                  </NavLink>
                </li>
                {/* <ul className="nested">
                                        <li><Link to="/relators/home" >Home</Link></li>
                                        <li><Link to="/relators/condo" >Condo</Link></li>
                                        <li><Link to="/relators/apartments" >Apartments</Link></li>

                                    </ul>
                                </li> */}
                <li>
                  <NavLink to="/partner-with-us" rel="nofollow">
                    Partner with us
                  </NavLink>
                </li>
                {isGuest !== "guest" && (
                  <>
                    <li>
                      <Link to="/junk-removal-companies" rel="nofollow">
                        Junk Removal Companies
                      </Link>
                    </li>
                    <li>
                      <Link to="/moving-companies" rel="nofollow">
                        Moving Companies
                      </Link>
                    </li>
                  </>
                )}

                <li>
                  <NavLink exact to="/about-us" activeClassName="active">
                    About Us
                  </NavLink>
                </li>
                {isGuest !== "guest" && isGuest && (
                  <>
                    <li>
                      <NavLink exact to="/my-bookings" activeClassName="active">
                        My Bookings
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className=" d-flex justify-content-end user-action-btns">
              {loggedIn ? (
                <button onClick={this.toggle} className="btn-contact">
                  <i className="icon-logout text-white"></i>
                  <span>LogOut</span>
                </button>
              ) : (
                <NavLink to="/account/login" className="btn-contact">
                  <i className="icon-login text-white"></i>
                  <span> Login</span>
                </NavLink>
              )}

              <button
                onClick={this.handleNavigation}
                className={`menu-toggle bg-primary d-flex justify-content-center align-items-center ${
                  menuActive ? "active" : ""
                }`}
              >
                <div className="nav-icon">
                  <span>&nbsp;</span>
                </div>
              </button>
            </div>
          </div>
        </header>
        <ConfirmationModal
          modal={this.state.modal}
          text="Are you sure you want to logout ?"
          onClickNo={() => this.setState({ modal: false })}
          onClickYes={() => {
            this.setState({ modal: false });
            this.logout();
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  golbal: state.golbal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    landingPageToggle: (bol) => dispatch(landingPageToggle(bol)),
    designation: (designations) => dispatch(designation(designations)),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebHeader);
