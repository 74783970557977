import { fetchUtil } from "../../utils/fetchUtils";
import {errorToast, successToast} from "../../utils/toaster";

export const PARTNER_WITH_US_REQUEST = "PARTNER_WITH_US_REQUEST";
export const PARTNER_WITH_US = "PARTNER_WITH_US";

export const partnerFormSubmit = (partner) => dispatch => {
    const body = JSON.stringify(partner);
    dispatch({ type: PARTNER_WITH_US_REQUEST, payload: true });
   return  new Promise((resolve, reject) => {
        fetchUtil({
            url: `partner/create`,
            method: "POST",
            body
        })
            .then(res => {
                successToast('Your information has been sent successfully.');
                dispatch({ type: PARTNER_WITH_US, payload: res });
                resolve(res);
            })
            .catch(err => {
    // console.log('first catch', err)
                errorToast(err?.payload?.message);
                reject(err);
            }).finally(() => {
            dispatch({ type: PARTNER_WITH_US_REQUEST, payload: false });
        });
    })

};
