import React, { Component } from "react";
import { connect } from "react-redux";
import { forgotPassword } from "../../../store/actions";
import { emailValidation } from "../../../utils/helper";

import "../Account.scss";
import { Link } from "react-router-dom";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
      },
      fieldError: {
        email: "Email must be required",
      },
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleChange(event) {
    let { name, value } = event.target;
    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
    if (this.state.submitted) {
      this.formValidation();
    }
  }

  handleBlur() {
    if (this.state.submitted) {
      this.formValidation();
    }
  }

  submit(event) {
    event.preventDefault();
    if (!this.state.submitted) {
      this.setState({ submitted: true });
    }
    const formValid = this.formValidation();
    if (formValid) {
      this.props.forgotPassword(this.state.user);
    }
  }

  formValidation() {
    let isValid = false;
    const { email } = this.state.user;
    const fieldError = this.state.fieldError;

    if (email) {
      const validEmail = emailValidation(email);
      fieldError.email = validEmail ? false : "Please enter a valid Email";
      isValid = validEmail;
    }

    this.setState({ fieldError: fieldError });
    return isValid;
  }

  render() {
    const { loading } = this.props.auth;
    const { email } = this.state.user;
    const emailError = this.state.fieldError.email;
    const submitted = this.state.submitted;
    return (
      <div className="login w-100">
        <h3 className="text-center primary-heading mb-4">
          Forgot Your Password?
        </h3>
        <p className="text-center f-14 px-0 px-lg-5 mb-5">
          Don't worry! Just fill in your email and we'll send you a link to
          reset your password.
        </p>
        <form className="w-100" onSubmit={this.submit}>
          <div className="input-field">
            <input
              className={submitted && emailError ? "error" : ""}
              type="text"
              name="email"
              placeholder="email"
              value={email}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            {submitted && emailError ? <small>{emailError}</small> : null}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <button className="btn btn-primary">
              Reset Password
              {loading ? (
                <i className="icon-reload text-white loading"></i>
              ) : null}
            </button>

            <p className="f-14 mt-5">
              You have already account? <Link to="/account/login">Login</Link>{" "}
            </p>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(forgotPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
