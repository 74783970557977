import React, { PureComponent } from "react";
import {connect} from "react-redux";
import {withScriptjs} from "react-google-maps";
import Map from "./DynamicMap";
class GoogleMap extends PureComponent {
    render() {
        const MapLoader = withScriptjs(Map);
        return (
                <MapLoader
                    // Client  key
                    // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAdT2cwgmQWwSOvtyxBfkDT2ejaPdMS5mA"
                     googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBNpN9FJAfzshqfgktdov_T5ppyGqahwGI"


                   // Developer  key
                  // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDJBaclq9kIe5DnJcScEUCvt-9atb6epls"

                    loadingElement={<div style={{ height: `100%` }} />}
                />

        );
    }

}


export default connect(
    null,
    null
)(GoogleMap);

