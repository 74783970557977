import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './BannerSlider.scss'
import bannerSliderBg1 from "../../assets/images/banner/1.jpg";
import bannerSliderBg2 from "../../assets/images/banner/2.jpg";
import bannerSliderBg3 from "../../assets/images/banner/3.jpg";
import SocialLinks from "../SocialLinks/SocialLinks";


const BannerSlider = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay : true,
    };

    const slides = [
        {  bg : bannerSliderBg1,
        },
        {  bg : bannerSliderBg2,
        },
        {  bg : bannerSliderBg3,
        }
    ]

    return (
        <div className="banner-slider">
            <Slider {...settings}>
                {
                    slides.map((slide, index)=>{
                        return  <div key={`slide-${index}`} >
                            <div className="banner-slide d-flex justify-content-center align-items-center"  style={{backgroundImage: `url(${slide.bg})`,}}>
                            </div>
                        </div>
                    })
                }
            </Slider>
            <SocialLinks/>
        </div>
    );
}

export default BannerSlider;
