import React, { Component } from "react";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";
import "./PartnerWithUs.scss";
import { errorToast } from "../../utils/toaster";
import { partnerFormSubmit } from "../../store/actions";
import { connect } from "react-redux";

class PartnerWithUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  handleSubmit(event, errors, values) {
    let self = this;
    if (Object.keys(errors).length > 0) {
      return errorToast("Please fill all required fields");
    } else {
      self.form && self.form.reset();
      this.props.partnerFormSubmit(values).then(() => {
        self.form && self.form.reset();
      });
    }
  }

  validateEmail(value) {
    let errors = {};
    if (!value) {
      errors.emptyEmail = "The email field is empty";
    } else if (!/^\S+@\S+\.\S+$/.test(value)) {
      errors.invalidEmail = "Invalid email pattern";
    }
    return errors;
  }

  handleChange(event) {
    const { name, value } = event.target;
    const errors = { ...this.state.errors };

    if (name === "email") {
      if (!value.trim()) {
        errors.email = "This field is required.";
      } else if (!this.validateEmail(value)) {
        errors.email = "Invalid email address.";
      } else {
        delete errors.email;
      }
    }

    this.setState({ errors });
  }

  render() {
    const { loading } = this.props.partner;
    const { errors } = this.state;

    return (
      <div className="partner-with-us">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto  full-content-height mt-5 d-flex flex-column justify-content-center align-items-center text-white py-5">
              <h2 className="primary-heading text-center text-primary my-5 pb-3">
                Partner with us
              </h2>

              <AvForm
                onSubmit={this.handleSubmit.bind(this)}
                ref={(c) => (this.form = c)}
              >
                <div className="row mb-3">
                  <div className="col-12 radio-option">
                    <AvRadioGroup inline name="type" label="" required>
                      <AvRadio label="Are you a business?" value="business" />
                      <AvRadio
                        label="Are you an individual?"
                        value="individual"
                      />
                    </AvRadioGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <AvField
                      name="firstName"
                      placeholder="First Name"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <AvField
                      name="lastName"
                      placeholder="Last Name"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <AvField
                      name="organization"
                      placeholder="Organization / Business Name"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <AvField
                      name="email"
                      placeholder="Email Address"
                      type="email"
                      onChange={this.handleChange.bind(this)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "The field is empty",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          errorMessage: "Invalid email address",
                        },
                      }}
                    />
                    {/* {errors.email && (
                      <div className="error-message">{errors.email}</div>
                    )} */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <AvField
                      name="address"
                      placeholder="Office Address"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <AvField
                      name="phone"
                      placeholder="Contact Number"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <AvField
                      name="country"
                      placeholder="Country"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <AvField
                      name="state"
                      placeholder="State"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <AvField
                      name="city"
                      placeholder="City"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <AvField
                      name="zipCode"
                      placeholder="Zip Code"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <AvField
                      name="service"
                      placeholder="What type of Services / Product you provide"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <AvField
                      name="recieveHelpQuestion"
                      placeholder="How are you going to help us?"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <AvField
                      name="sendHelpQuestion"
                      placeholder="How we are going to help you?"
                      errorMessage="The field is empty"
                      required
                    />
                  </div>
                </div>

                <FormGroup>
                  <Button className="f-14 text-uppercase font-weight-bold  mb-5">
                    Submit Now!
                    {loading ? (
                      <i className="icon-reload text-white loading"></i>
                    ) : null}
                  </Button>
                </FormGroup>
              </AvForm>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  partner: state.partner,
});

const mapDispatchToProps = (dispatch) => {
  return {
    partnerFormSubmit: (user, remember) =>
      dispatch(partnerFormSubmit(user, remember)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerWithUs);
