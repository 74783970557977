import React, { Component } from "react";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="home">
        <div className="container">
          <div className="row">
            <div className="col-12 my-5 py-5  terms-and-condition">
              <h1 className="primary-heading mb-5">
                DYNAMIC LIVING LLC – PRIVACY POLICY
              </h1>
              {/* <p>Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and
                                may be used for informational purposes only. Because the Terms and Conditions contain
                                legal obligations, please read them carefully.</p> */}
              <h2>1. INTRODUCTION</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                is dedicated to safeguarding its customers' personal
                information. We’ve built and managed a data security
                architecture that permits our customers to understand and
                exercise their privacy rights.In accordance with its sponsors
                <b>(we), (us),</b>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                has created the protective policy statement in front of you,
                which is fully committed to safeguarding and maintaining the
                confidentiality of your <b>(Site User).</b>
                <p>
                  Both you (the "User") and{" "}
                  <b>
                    <i>DYNAMIC LIVING LLC</i>
                  </b>{" "}
                  are bound by the following{" "}
                  <b>Terms of Privacy ("Terms") ("Company," "we," or "us").</b>{" "}
                  Its purpose is to keep track of and govern your access to and
                  usage of
                  <a href="https://www.dynamicliving.com/">
                    Dynamic Living
                  </a>{" "}
                  (the "Website"), irrespective of whether you're a registered
                  user or use our platform in any other manner.
                </p>
              </p>
              {/* <blockquote>
                PLEASE NOTE: We reserve the right, at our sole discretion, to
                change, modify or otherwise alter these Terms and Conditions at
                any time. Unless otherwise indicated,
              </blockquote> */}
              <h2>2. CONSENT </h2>
              <p>
                Please read and comprehend the supplied{" "}
                <b>
                  <i>"Privacy Policy"</i>
                </b>{" "}
                before browsing, accessing, or purchasing from the Website. By
                using the Service and this site, you agree to the
                <a href="https://www.dynamicliving.com/">Dynamic Living</a>{" "}
                Privacy Policy.
              </p>
              <h2>3. GENERAL DATA</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                collects general information to optimize the functioning and
                structure of the Website (including to help us evaluate content
                of interest to visitors). We obtain data for various reasons,
                such as assessing the Website's technological and organizational
                requirements, providing information, evaluating trends,
                assisting customers, or examining how the Web page is often used
                - none of which would openly expose our visitor's data to the
                public.
              </p>
              <h2>4. LOGIN/REGISTRATION</h2>
              <p>
                The user must login/submit private information on our Website to
                use our online services or book movers, get discounts, and more.
                To complete your booking for a mover, we'll need your username,
                contact information, residence information, and email address.
              </p>
              <h2>5. TERMINATION OF REGISTRATION</h2>
              <p>
                If the Company's rights are breached,{" "}
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                has the authority to limit or cancel a customer's data or seize
                registration access at any time.
              </p>
              {/* <p>
                B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES,
                INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE
                FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE
              </p> */}
              <h2>6. PERSONAL DATA</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                acquires Personal Data for various purposes, including account
                setup (e.g., your identification, contact number, and email
                account), payment evidence, and information about your
                interests, demographics, engagements, and communication
                preferences. We gather your data to keep in touch with you as
                mandatory or link you with users who are relevant to you.
              </p>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                can keep a backup or copy of such an engagement and guarantees
                that your personally recognizable information is never conveyed,
                misused, or provided to the public.
              </p>
              <h2>7. DATA PRIVACY</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                guarantees the accuracy of the information provided. Any
                personal data, particularly your Private Details, is never used
                or mistreated by us. In accordance with the guidelines,{" "}
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                does not expose or trade personal information with other
                authorities, businesses, or customers.
              </p>
              {/* <p>
                The framing, mirroring, scraping or data mining of the Site or
                any of its content in any form and by any method is expressly
                prohibited.
              </p> */}
              <h2>8. SECURITY</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                values the privacy of the information provided to us. We employ
                all necessary safeguards to secure the information or use of
                your Identification Information. However, we cannot assure the
                security of any information you (the user) submit, transfer, or
                reveal over our Website or email apart from the required
                details.
              </p>
              <h2>9. THIRD-PARTY LINKS</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                has a variety of external links to our products. However, we are
                not accountable for the privacy policies of third-party
                companies.{" "}
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                does not respond to third-party service/product claims,
                disputes, concerns, or inquiries. They must be forwarded to a
                third party.
              </p>
              <h2>10. COOKIE POLICY</h2>
              <p>
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                , like any other business, makes use of cookies. These cookies
                are used simply to maintain the statistics, such as visitor
                numbers and web resource IDs. The information will assess and
                forecast trends, run the Website, track user behavior, and
                compile demographic data.
              </p>
              <p>
                Please keep in mind that third-party marketing cookies are
                outside the control of{" "}
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                .
              </p>
              <h2>11. AMENDMENTS TO THE TERMS OF SERVICE</h2>
              <p>
                Our User Agreement and Terms of Use, which contain a list of
                relevant regulations, control your use of the{" "}
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                Service.{" "}
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                has the right to revise, change, or alter the Policy at any
                time. Any amendments will be applied as soon as{" "}
                <b>
                  <i>DYNAMIC LIVING LLC</i>
                </b>{" "}
                considers them necessary.We highly advise that you read our
                Policy regularly so that you are aware of and can comply with
                any adjustments we make.
              </p>
              <h2>12. CONTACT US</h2>
              <p>
                If you have any unanswered inquiries about our Privacy Policy,
                feel free to email our support personnel at
                <a href="mailto:support@dynamicliving.com">
                  {" "}
                  support@dynamicliving.com
                </a>
                , and we will shortly answer
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
