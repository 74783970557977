import React, { Component } from "react";
import collapse from "../../assets/images/icons/collapas.png";
import "./DestinationForm.scss";
import States from "../../constant/states";
import { errorToast } from "../../utils/toaster";

import { connect } from "react-redux";
import { designation } from "../../store/actions";
import { sortedStateByCityAlphabetic } from "../../utils/helper";
const citiesSort = sortedStateByCityAlphabetic();

function getRandomNumber() {
  const min = 0;
  const max = States.length - 1;
  return Math.floor(Math.random() * (max - min + 1) + min);
}

class DestinationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formCollapse: true,
      to: { name: "" },
      from: { name: "" },
      filterBy: "state",
    };
  }

  componentDidMount() {
    let { to, from, filterBy } = this.props.golbal.designation;
    filterBy = this.props.hideState ? "state" : filterBy;
    if (to && to.name && from && from.name) {
      this.setState({ to });
      this.setState({ from });
      this.setState({ filterBy });
    } else if (to && to.name) {
      this.setState({ to });
      this.setState({ filterBy });
    } else {
      const toIndex = getRandomNumber();
      const fromIndex =
        toIndex === States.length - 1 ? toIndex - 1 : toIndex + 1;
      this.props.designation({
        to: States[toIndex],
        from: States[fromIndex],
        filterBy: "state",
      });
      this.setState({ to: States[toIndex] });
      this.setState({ from: States[fromIndex] });
      this.setState({ filterBy: "state" });
    }
  }

  collapseHandler = () => {
    const formCollapse = this.state.formCollapse;
    this.setState({ formCollapse: !formCollapse });
  };

  change = (event) => {
    const { filterBy } = this.state;
    let { name, value } = event.target;
    if (name === "filterBy") {
      this.setState({ filterBy: value });
    } else {
      if (filterBy === "city") {
        for (let i = 0; i < States.length; i++) {
          if (States[i].cities[0].name === value) {
            this.setState({ [name]: States[i].cities[0] });
            break;
          }
        }
      } else {
        const state = States.find((state) => state.name === value);
        this.setState({ [name]: state });
      }
    }
  };

  submit = () => {
    const { to, from, filterBy } = this.state;

    if (!to.name && !from.name) {
      errorToast("To and From Fields both are required");
      return false;
    } else if (!to.name) {
      errorToast("To Field are required");
      return false;
    } else if (to.name && !from.name) {
      this.props.designation({
        to,
        filterBy,
      });

      if (this.props.onClickHandler) {
        this.props.onClickHandler();
      }
    } else if (to.name === from.name) {
      errorToast("To and From both location is same");
      return false;
    } else {
      this.props.designation({
        to,
        from,
        filterBy,
      });

      if (this.props.onClickHandler) {
        this.props.onClickHandler();
      }
    }
  };

  renderOptions() {
    const filterBy = this.state.filterBy;

    return filterBy === "city"
      ? citiesSort.map((item) => {
          return (
            <option key={item.state} value={item.cities[0].name}>
              {item.cities[0].name}
            </option>
          );
        })
      : States.map((item) => {
          return (
            <option key={item.state} value={item.name}>
              {item.name}
            </option>
          );
        });
  }
  render() {
    const formCollapse = this.state.formCollapse;
    let className = formCollapse
      ? "active destination-form"
      : "destination-form";
    className = this.props.className
      ? className + " " + this.props.className
      : className;
    return (
      <div className={className}>
        <form>
          <button
            type="button"
            className="btn-transparent destination-form-close"
            onClick={this.collapseHandler}
          >
            <img src={collapse} alt="close" />
          </button>
          <h3 className="text-white destination-form__title">
            Choose Your Destination
          </h3>
          <div className="destination-form__container d-flex  justify-content-between  align-items-lg-center align-items-start">
            <div className="field-group d-flex justify-content-between align-items-center">
              {!this.props.hideState && (
                <select
                  className={
                    this.state.from && this.state.from.name ? "" : "single"
                  }
                  name="filterBy"
                  onChange={this.change}
                  value={this.state.filterBy}
                >
                  <option value="state">State</option>
                  <option value="city">City</option>
                </select>
              )}
              <select
                name="to"
                onChange={this.change}
                value={this.state.to.name}
                className={
                  this.state.from && this.state.from.name ? "" : "single"
                }
              >
                <option value="0">To</option>
                {this.renderOptions()}
              </select>
              {this.state.from && this.state.from.name ? (
                <select
                  name="from"
                  onChange={this.change}
                  value={this.state.from.name}
                >
                  <option value="0">From</option>
                  {this.renderOptions()}
                </select>
              ) : null}
            </div>

            <button
              type="button"
              className="search-form-btn d-flex justify-content-center align-items-center"
              onClick={this.submit}
            >
              <i className="icon-searching-magnifying-glass"></i>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  golbal: state.golbal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    designation: (designations) => dispatch(designation(designations)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DestinationForm);
