import {FETCHING_DVM, DVM_SUCCESS_TO, DVM_SUCCESS_FROM} from "../actions";

const initialState = {
    loading: false,
    movingTo: {},
    movingFrom: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_DVM:
            return {
                ...state,
                loading: action.payload
            };
        case DVM_SUCCESS_TO:
            return {
                ...state,
                movingTo: action.payload,
            };   case DVM_SUCCESS_FROM:
            return {
                ...state,
                movingFrom: action.payload,
            };
        default:
            return state;
    }
};
