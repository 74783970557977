import { combineReducers } from "redux";


import authReducer from "./authReducers";
import golbalReducers from "./golbalReducers";
import listingsReducers from "./listingsReducers";
import dvmReducers from "./dvmReducers";
import partnerWithUsReducers from "./partnerWithUsReducers";
import flightReducers from "./flightReducers";


const appReducer = combineReducers({
    auth: authReducer,
    golbal: golbalReducers,
    listing : listingsReducers,
    dvm : dvmReducers,
    partner : partnerWithUsReducers,
    flight : flightReducers,
});

const rootReducer = (state, action) => {


    return appReducer(state, action);
};

export default rootReducer;
