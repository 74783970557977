import React, { Component } from "react";
import './Hospital.scss'
import {getHospitals} from "../../../store/actions";
import { connect } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import {getQueryString, getFilterBY, sortByAsc, sortByDes} from "../../../utils/helper";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import HospitalDetails from "./HospitalDetails";

class Hospitals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpened : false,
            detailsId : null,
            filterBy : '',
            name: '',
            sortBy : null
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.openedDetails = this.openedDetails.bind(this);
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        let filterBy = '',
            name = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            name = getQueryString(location.search, filterBy);
        }
        if (name) {
            this.props.getHospitals(name, filterBy);
        }
        this.setState({
            filterBy, name
        })
    }

    componentDidUpdate(nextProps) {
        const {location} = nextProps.history;
        const {name} = this.state;

        let nextName = null;
        let filterBy = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            nextName = getQueryString(location.search, filterBy);
        }
        if (nextName && name !== nextName) {
            this.props.getHospitals(nextName, filterBy);
            this.setState({
                filterBy,
                name : nextName
            })
        }
    }

    toggleModal(){
        const modalOpened = this.state.modalOpened;
        this.setState({
            modalOpened : !modalOpened
        })
    }

    filter(order){
        this.setState({
            sortBy : order
        })
    }

    openedDetails(id){
        this.setState({
            detailsId : id
        });
        this.toggleModal();
    }
    hospitalRender(hospital) {
        if (hospital) {
            return (
                <div className="crime-listing__wrapper">
                    <ul className="bullet">
                        {this.hospitalMap(hospital)}
                    </ul>
                </div>
            )
        }
        else {
            return (
                <div className="py-5 text-center text-white">
                    Not hospital found
                </div>
            );
        }
    }
/*    hospitalMap(hospitals) {
        let hospitalListing  = hospitals;
        if(this.state.sortBy){
            hospitalListing = this.state.sortBy === 'asc' ? sortByAsc(hospitals, 'name'): sortByDes(hospitals, 'name');
        }

        if (hospitalListing)
            return hospitalListing.map((hospital, i) =>{
                return (
                    <li key={`hospital-${i}`} className="crime-name text-white pointer" onClick={() => this.openedDetails(hospital.hospital_id)}>
                        <span className="d-block">{hospital.name}</span>
                        <small className="d-block">{hospital.street_address} {hospital.hospital_id}</small>
                    </li>
                )
            })
    }*/

    hospitalMap(hospitals) {
        let hospitalslListing  = hospitals?.candidates;

        if(!hospitalslListing){
            return  null
        }


        let  hospitalsArray = [];
        if(hospitalslListing && hospitalslListing.length){
            hospitalsArray =  hospitalslListing.map( e  => {
                return  e.attributes ;
            })
        }

        if(this.state.sortBy){
            hospitalsArray = this.state.sortBy === 'asc' ? sortByAsc(hospitalsArray, 'placeName'): sortByDes(hospitalsArray, 'placeName');
        }

        if (hospitalsArray)
            return hospitalsArray.map((hospital, i) =>{
                return (
                    <li key={`ent-${i}`} className="crime-name text-white pointer"  onClick={() => this.openedDetails(i)}>
                        <span className="d-block">{hospital.placeName}</span>
                        <small className="d-block">{hospital.place_addr}</small>
                    </li>
                )
            })
    }

    render() {
        const {listing } = this.props,
            { loading, hospital } = listing,
            modalOpened = this.state.modalOpened;
        const {name} = this.state;

        return (
            <div>
                <SidePanel>
                    <div className="crime">
                        <div className="d-flex justify-content-between align-items-center p-4 m-3">
                            <h3 className="primary-heading text-white m-0  heading">Hospitals in {name}</h3>
                            <div className="dropdown filter-dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                                        aria-expanded="false">
                                    <i className="icon-filter">&nbsp;</i>
                                </button>
                                <div className="dropdown-menu">
                                    <button className="dropdown-item" onClick={ () => this.filter('asc')} >Ascending</button>
                                    <button className="dropdown-item" onClick={ () => this.filter('des')}>Descending </button>
                                </div>
                            </div>
                        </div>
                        {
                            loading ? <Loading /> : this.hospitalRender(hospital)
                        }
                    </div>
                </SidePanel>
                {
                    modalOpened &&   <Module id="hospital-modal" modalOpened={modalOpened} closeModal={this.toggleModal}>
                        <HospitalDetails city={name}  id={this.state.detailsId}/>
                    </Module>
                }
            </div>

        );
    }
}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getHospitals: (city, filterBy) => dispatch(getHospitals(city, filterBy))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hospitals);

