import React, { Component } from "react";
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import lines from "../../../assets/images/icons/left-right-line.png";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "./PassengerDetails.scss";
import { flightsBooking, searchingFlight } from "../../../store/actions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
const infantsMaxDate = moment().format('YYYY-MM-DD');
const infantsMinDate = moment().subtract(2, "years").format("YYYY-MM-DD");
const childrenMinDate = moment().subtract(12, "years").format("YYYY-MM-DD");
const adultMinDate = moment().subtract(200, "years").format("YYYY-MM-DD");
let passengers = [];

const fields = {
  idType: 1,
  dateOfBirth: "",
  gender: "",
  nationality: "",
  name: {
    firstName: "",
    lastName: "",
    fatherName: "",
  },
  contact: {
    emailaddress: "",
    phones: [
      {
        number: "",
        countryCallingCode: "",
        deviceType: "",
      },
    ],
  },
};
class PassengerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneWay: true,
      dobFocus: false,
      dob: "",
      flightOffers: null,
      phoneNumbers: [],
    };
  }

  componentDidMount() {
    let { flightOffers, searchFlight } = this.props.flight;
    this.setState({ flightOffers });
    const count = searchFlight.adults + searchFlight.children + searchFlight.infants;
    const phoneNumbers = new Array(count).fill({});
    this.setState({ phoneNumbers });
  }

  onDobFocus = () => {
    this.setState({ dobFocus: true });
  };
  onDobBlur = () => {
    this.setState({ dobFocus: false });
  };

  onChangeHandler = (e) => {
    this.setState({ dob: e.target.value });
  };

  onPhoneChange = (state, value, code, index) => {
    const phoneNumbers = this.state.phoneNumbers;
    phoneNumbers[index] = {
      error: !state,
      value: value,
      dialCode: code.dialCode,
    };
    this.setState({
      phoneNumbers,
    });
  };

  handleSubmit = (e, errors, values) => {
    e.preventDefault();
    // console.log(errors);
    const phoneNumbers = this.state.phoneNumbers;
    let { flightOffers } = this.props.flight;

    phoneNumbers.forEach((p) => {
      if(!p.error && !p.value){
        p.error = true
      }
    });
    this.setState({phoneNumbers});
    const phoneNumberValidation = phoneNumbers.find((p) => p.error);

    if (!errors.length && !phoneNumberValidation) {
      let temp = [];
      passengers.forEach((_, i) => {
        const obj = {
          id: i + 1,
          dateOfBirth: values[`dateOfBirth_${i}`],
          gender: values[`gender_${i}`],
          name: {
            firstName: values[`firstName_${i}`],
            lastName: values[`lastName_${i}`],
            fatherName: values[`fatherName_${i}`],
          },
          contact: {
            emailAddress: values[`emailAddress_${i}`],
            phones: [
              {
                number: this.getValidPhoneNumber(phoneNumbers[i]),
                countryCallingCode: phoneNumbers[i]?.dialCode,
                deviceType: "MOBILE",
              },
            ],
          },
          passport: values[`passport_${i}`]
        };
        temp.push(obj);
      });
      const payload = {
        flightOffers: flightOffers,
        travlers: temp,
      };

      this.props.flightsBooking(payload);
    }
    else {
      toast.error(`Please fill all required fields`);
    }
  };

  getValidPhoneNumber = (phoneNum) => {
    // console.log('phoneNum', phoneNum);
    const number = phoneNum?.value;
    const dialCode = phoneNum?.dialCode;
    if(number && dialCode){
      const num =  number.replace(/[^0-9]/g, '');
      const dialLength  = dialCode?.length;
      const isDialCode = num.substring(0, dialLength);
      const realNum = dialCode === isDialCode ? num.substring(dialLength, num?.length) : num
      // console.log('realNum', realNum);
      return realNum;
    }
  }
  render() {
    const { loggedIn } = this.props.auth;
    if (!loggedIn )
      return <Redirect to="/account/login" />;

    const { flightOffers, searchFlight, bookingLoading } = this.props.flight;
    if (!flightOffers)
      return <Redirect to="/book-flight" />;

    let adult = new Array(searchFlight.adults).fill({});
    let child = new Array(searchFlight.children).fill({});
    let infants = new Array(searchFlight.infants).fill({});
    adult = adult.map((a, i) => {
      return { title: `Adult ${i + 1}`, type : 'adult', minDate: adultMinDate , maxDate: childrenMinDate,  ...fields };
    });
    child = child.map((a, i) => {
      return { title: `Children ${i + 1}`, type : 'children', minDate: childrenMinDate , maxDate: infantsMinDate,  ...fields };
    });
    infants = infants.map((a, i) => {
      return { title: `Infants ${i + 1}`, type : 'infants', minDate: infantsMinDate , maxDate: infantsMaxDate,  ...fields };
    });

    passengers = adult.concat(child).concat(infants);
    const count = searchFlight.adults + searchFlight.children + searchFlight.infants;
    const travelerPricings = {
      adult: 0,
      adultPrice: 0,
      children: 0,
      childrenPrice: 0,
      infants: 0,
      infantsPrice: 0,
    };

    // eslint-disable-next-line no-unused-expressions
    flightOffers?.travelerPricings.forEach((t) => {
      const isAdult = t.travelerType === "ADULT";
      const isChildren = t.travelerType === "CHILD";
      const isInfants = t.travelerType === "HELD_INFANT";
      if (isAdult) {
        travelerPricings.adult = travelerPricings.adult + 1;
        travelerPricings.adultPrice = t.price;
      }
      if (isChildren) {
        travelerPricings.children = travelerPricings.children + 1;
        travelerPricings.childrenPrice = t.price;
      }
      if (isInfants) {
        travelerPricings.infants = travelerPricings.infants + 1;
        travelerPricings.infantsPrice = t.price;
      }
    });
    const phoneNumbers = this.state.phoneNumbers;
    // console.log('render phoneNumbers', phoneNumbers);
    return (
        <div className="passenger-details font-secondary">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <AvForm className="passenger-details-form" onSubmit={this.handleSubmit} noValidate>
                  <div className="passenger-details-form-header bg-primary text-white">
                    <h3 className="mb-0">Passenger details</h3>
                  </div>
                  <div className="passenger-alert">Enter the names exactly as they appear in your passport/ID to avoid check-in complications.</div>
                  <div className="form-content">
                    <div className="dynamic-control">
                      {passengers.map((item, index) => (
                          <div key={`p-${index}`} className="pb-5">
                            <h4 className="f-15 capitalized">{item.title}</h4>
                            <h5 className="f-13">Main Traveler</h5>
                            <p className="f-13 my-5">All fields are mandatory</p>
                            <div className="form-group">
                              <AvField type="text" className="form-control" placeholder="First Name" name={`firstName_${index}`} required />
                            </div>
                            <div className="form-group">
                              <AvField type="text" className="form-control" placeholder="Last Name" name={`lastName_${index}`} required />
                            </div>
                            <div className="form-group">
                              <AvField type="text" className="form-control" placeholder="Father Name" name={`fatherName_${index}`} required />
                            </div>
                            <div className="form-group">
                              <AvField type="email" className="form-control" placeholder="Email Address" name={`emailAddress_${index}`} required />
                            </div>
                            <AvRadioGroup name={`gender_${index}`} className="d-flex align-items-center my-5 gender-field-row" required>
                              <span className="mr-5 f-15 gender-label">Gender</span>

                              <div className="custom-control form-radio custom-radio custom-control-inline mr-5">
                                <AvRadio type="radio" id="male" value="MALE" />
                                <label className="f-13 m-0 gender-radio-label" htmlFor="male">
                                  Male
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <AvRadio type="radio" id="female" value="FEMALE" />
                                <label className="f-13 m-0 gender-radio-label" htmlFor="female">
                                  Female
                                </label>
                              </div>
                            </AvRadioGroup>
                            <div className={`intel-input-wrapper ${phoneNumbers[index]?.error ? "error" : ""}`}>
                              <IntlTelInput
                                  name={`number_${index}`}
                                  className={`intel-input-container`}
                                  allowDropdown="false"
                                  preferredCountries={["us"]}
                                  onPhoneNumberChange={(e, v, c) => this.onPhoneChange(e, v, c, index)}
                              />
                            </div>
                            <div className="form-group">
                              {/*<div className="dob" onFocus={this.onDobFocus} onBlur={this.onDobBlur}>*/}
                              {/*<div className="dob-label" onFocus={this.onDobFocus} onBlur={this.onDobBlur}>*/}
                              {/*  Date of Birth*/}
                              {/*</div>*/}
                              <label className="f-13 mb-2">Date of Birth</label>
                              <AvField  
                              min={item.minDate}  
                              max={item.maxDate}  
                              name={`dateOfBirth_${index}`} 
                              type="date" 
                              className="form-control " 
                              required 
                              />
                            </div>
                            {/*</div>*/}
                            <div className="form-group">
                              <AvField name={`nationality_${index}`} type="text" className="form-control" placeholder="Nationality" required />
                            </div>

                            <div className="form-group passport">
                              <AvField name={`passport_${index}`}  type="text" className="form-control" placeholder="Passport Number"  validate={{
                                required: {value: true, errorMessage: 'Please enter passport number'},
                                pattern: {value: '^[A-Za-z0-9]+$', errorMessage: 'Passport must be composed only with letter and numbers'},
                                minLength: {value: 8, errorMessage: 'Passport must be between 8 and 12 characters'},
                                maxLength: {value: 12, errorMessage: 'Passport must be between 8 and 12 characters'}
                              }} />
                              {/*<AvField name={`passport_${index}`} minlength="8" maxlength="12" type="text" className="form-control" placeholder="Passport Number" required />*/}
                            </div>
                          </div>
                      ))}
                    </div>

                    <AvCheckboxGroup className="form-check d-flex align-items-center checkbox-field" required name="test">
                      <AvCheckbox className="form-check-input d-inline-block" id="defaultCheck1" />
                      <label className="form-check-label" htmlFor="defaultCheck1">
                        I'd like to receive exclusive deals, promotions and updates from Dynamic Living.
                      </label>
                    </AvCheckboxGroup>

                    <button className="btn btn-primary mt-5" disabled={bookingLoading}>
                      Save & Continue
                      {bookingLoading ? <i className="icon-reload text-white loading"></i> : null}
                    </button>
                  </div>
                </AvForm>
              </div>
              <div className="col-12 col-lg-4">
                <div className="white-box flight-details">
                  <div className="d-flex align-items-center direction">
                    <span className="fd-location-label">{searchFlight?.locationDeparture?.label}</span>
                    <img src={lines} alt="line" />
                    <span className="fd-location-label">{searchFlight?.locationArrival?.label}</span>
                  </div>
                  <p className="mb-2">
                    {moment(searchFlight?.departure || new Date()).format("ddd, DD MMM")} - {moment(searchFlight.arrival || new Date()).format("ddd, DD MMM")} - {count} Passenger
                  </p>
                  <p className="success mb-0">Baggage & Cancellation Policy</p>
                </div>

                <div className="white-box price-breakdown mt-4">
                  <h4 className="mb-4">Price Breakdown</h4>
                  <div className="price-breakdown-row">
                    <div className="d-flex justify-content-between">
                                        <span className="d-flex flex-column">
                                            <span>
                                                {travelerPricings.adult} Adult, {searchFlight.travelClass}
                                            </span>
                                            <span className="f-13">
                                                {travelerPricings.adult} x ${travelerPricings.adultPrice?.total}
                                            </span>
                                        </span>
                      <span>${travelerPricings.adult * travelerPricings.adultPrice?.total}</span>
                    </div>
                  </div>
                  {travelerPricings?.children ? (
                      <div className="price-breakdown-row">
                        <div className="d-flex justify-content-between">
                                            <span className="d-flex flex-column">
                                                <span>
                                                    {travelerPricings.children} Adult, {searchFlight.travelClass}
                                                </span>
                                                <span className="f-13">
                                                    {travelerPricings.children} x ${travelerPricings.childrenPrice?.total}
                                                </span>
                                            </span>
                          <span>${travelerPricings.children * travelerPricings.childrenPrice?.total}</span>
                        </div>
                      </div>
                  ) : null}

                  {travelerPricings?.infants ? (
                      <div className="price-breakdown-row">
                        <div className="d-flex justify-content-between">
                                            <span className="d-flex flex-column">
                                                <span>
                                                    {travelerPricings.infants} Infants, {searchFlight.travelClass}
                                                </span>
                                                <span className="f-13">
                                                    {travelerPricings.infants} x ${travelerPricings.infantsPrice?.total}
                                                </span>
                                            </span>
                          <span>${travelerPricings.infants * travelerPricings.infantsPrice?.total}</span>
                        </div>
                      </div>
                  ) : null}
                  {/*  <div className="price-breakdown-row">
                    <div className="d-flex justify-content-between">
                      <span>Total Tax</span>
                      <span>$15.00</span>
                    </div>
                  </div>
                  <div className="price-breakdown-row">
                    <div className="d-flex justify-content-between">
                      <span className="f-13">Payment Processing Fee</span>
                      <span className="f-13">$0.00</span>
                    </div>
                  </div>*/}
                  <div className="price-breakdown-row">
                    <div className="d-flex justify-content-between">
                      <span className="text-danger">Total Fare in USD</span>
                      <span>${flightOffers?.price?.total}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  flight: state.flight,
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchingFlight: (searching) => dispatch(searchingFlight(searching)),
    flightsBooking: (data) => dispatch(flightsBooking(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PassengerDetails);
