import {LOGIN_FAIL, FETCHING, LOGIN_SUCCESS, LOGOUT_REQUEST , REGISTRATION_SUCCESS, REGISTRATION_FAIL, IS_LOGIN} from "../actions";

const initialState = {
    loggedIn: false,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_FAIL:
        case FETCHING:
            return {
                ...state,
                loading: action.payload
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            };
        case IS_LOGIN:
            return {
                ...state,
                loggedIn: action.payload
            };
            case LOGOUT_REQUEST:
            return {
                ...state,
                loggedIn: false
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            };
        case REGISTRATION_FAIL:
            return {
                ...state,
                loggedIn: false
            };
        default:
            return state;
    }
};
