import React, { Component } from "react";
import "./Rating.scss";
class Rating extends Component {
    render() {
        const rating = this.props.rating;
        let array = new Array(5).fill(1);
        return (
            <div className="rating d-inline-flex justify-content-center align-items-center">
                {
                    array.map((a, i)=>{
                        const index = i + 1;
                        if(rating >= index){
                            return <i key={i} className="icon-star text-primary"></i>;
                        }
                        else if(rating > (i) && rating < index) {
                            return <i key={i} className="icon-star-half-o text-primary"></i>;
                        }
                        else{
                            return <i key={i} className="icon-star-o text-primary"></i>;
                        }
                    })
                }
            </div>
        );
    }
}
export default Rating;
