import React, { Component } from "react";
import './Entertainments.scss'
import { connect } from "react-redux";

class EntertainmentsDetails extends Component {
    render() {
        const { listing, id } = this.props;
        const {  entertainments } = listing;
        const entertainmentDetails = entertainments?.candidates[id];
        const attributes = entertainmentDetails?.attributes
        return (
            <div className="modal-content__container-job text-white">
                <div className="modal-header text-center bg-primary border-0 py-4 text-white d-flex justify-content-center align-items-center font-secondary font-weight-bold">
                    {attributes.placeName}
                </div>
                <div className="content-job-details px-0 px-md-5 mx-5 my-4 font-secondary">
                    {
                        Object.keys(attributes).map(function(key, idx) {
                            if(attributes[key]){
                                let val = key.replace(/_/g, " ");
                                return  <div className="description-line py-3 row" key={idx}>
                                    <div className='col-12 col-md-5 key text-capitalize'>{val}</div>
                                    <div className='col-12 col-md-7 value text-capitalize'>{attributes[key]}</div>
                                </div>
                            }
                            else {
                                return null;
                            }
                        })
                    }
                </div>
            </div>
        );
    }
}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});



export default connect(
    mapStateToProps,
    null
)(EntertainmentsDetails);

