import React, { Component } from "react";
import {
  about,
  apartments,
  commercial,
  home,
  dvm,
  movingTips,
  propertyRent,
  propertySale,
  realtors,
  statistics,
  contact,
} from "../../assets/images/menu";

import calendar from "../../assets/images/menu/calendar.png";
import { Link, NavLink } from "react-router-dom";
import "./SideBarMenu.scss";
import { connect } from "react-redux";
import { toggleMenu } from "../../store/actions";

class SideBarMenu extends Component {
  handleNavigation = () => {
    const { menuOpen } = this.props.golbal;
    this.props.toggleMenu(!menuOpen);
  };

  menuButtonRender() {
    const { menuOpen } = this.props.golbal;
    return (
      <button
        // onClick={this.handleNavigation}
        className="menu bg-primary d-flex justify-content-center align-items-center active"
      >
        <div className={menuOpen ? "nav-icon active" : "nav-icon"}>
          <span>&nbsp;</span>
        </div>
      </button>
    );
  }

  render() {
    const { menuOpen } = this.props.golbal;
    const isGuest = localStorage.getItem("user");
    return (
      <aside className={menuOpen ? "side-bar-menu opened" : "side-bar-menu"}>
        {this.menuButtonRender()}
        <ul className="menus">
          <li>
            <NavLink
              exact
              to="/"
              className="first-one"
              activeClassName="active"
              onClick={this.props.onClick}
            >
              <span className="icon">
                <img src={home} alt="about" />
              </span>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/about-us"
              activeClassName="active"
              onClick={this.props.onClick}
            >
              <span className="icon">
                <img src={about} alt="about" />
              </span>
              About us
            </NavLink>
          </li>
          {isGuest !== "guest" && (
            <li>
              <span className="menu-name">
                <span className="icon">
                  <img src={dvm} alt="dmv" />
                </span>
                dmv
              </span>
              <ul className="nested-menu">
                <li>
                  <Link to="/license-change" onClick={this.props.onClick}>
                    <span className="icon">
                      <img src={home} alt="home" />
                    </span>
                    license change
                  </Link>
                </li>
                <li>
                  <Link
                    to="/vehicle-registration-change"
                    onClick={this.props.onClick}
                  >
                    <span className="icon">
                      <img src={commercial} alt="commercial" />
                    </span>
                    vehicle registration change.
                  </Link>
                </li>
              </ul>
            </li>
          )}

          <li>
            <NavLink
              exact
              to="/realtors"
              activeClassName="active"
              onClick={this.props.onClick}
            >
              <span className="icon">
                <img src={realtors} alt="realtors" />
              </span>
              realtor's
            </NavLink>
          </li>
          <li>
            <Link to="/partner-with-us" onClick={this.props.onClick}>
              <span className="icon">
                <img src={commercial} alt="commercial" />
              </span>
              Partner With us
            </Link>
          </li>
          {isGuest !== "guest" && (
            <>
              <li>
                <Link to="/junk-removal-companies" onClick={this.props.onClick}>
                  <span className="icon">
                    <img src={statistics} alt="statistics" />
                  </span>
                  Junk Removal Companies
                </Link>
              </li>
              <li>
                <Link to="/moving-companies" onClick={this.props.onClick}>
                  <span className="icon">
                    <img src={movingTips} alt="moving-tips" />
                  </span>
                  Moving Companies
                </Link>
              </li>
            </>
          )}
          {isGuest !== "guest" && isGuest && (
            <>
              <li className="my-booking-icon">
                <NavLink
                  exact
                  to="/my-bookings"
                  activeClassName="active"
                  onClick={this.props.onClick}
                >
                  <span className="icon">
                    <img
                      src={calendar}
                      alt="my-bookings"
                      style={{ width: "38px" }}
                    />
                  </span>
                  My Bookings
                </NavLink>
              </li>
            </>
          )}
          <li>
            <a>
              <span className="icon">
                <img src={contact} alt="contact" />
              </span>
              contact us
            </a>
            <ul className="nested-menu">
              <li>
                <a href="tel:+1 800 455 4555" onClick={this.props.onClick}>
                  <span className="icon-telephone icon "></span>
                  +1 800 455 4555 & 66
                </a>
              </li>
              <li>
                <a
                  href="mailto:support@dynamicliving.com"
                  onClick={this.props.onClick}
                >
                  <span className="icon icon-envelope-o"></span>
                  SUPPORT@DYNAMICLIVING.COM
                </a>
              </li>
            </ul>
          </li>

          {/* <ul className="nested-menu">
                            <li>
                                <Link to="/relators/home" onClick={this.props.onClick}>
                                  <span className="icon">
                                    <img src={home} alt="home" />
                                  </span>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/relators/condo" onClick={this.props.onClick}>
                                  <span className="icon">
                                    <img src={commercial} alt="condo" />
                                  </span>
                                    Condo
                                </Link>
                            </li>
                            <li>
                                <Link to="/relators/apartments" onClick={this.props.onClick}>
                                  <span className="icon">
                                    <img src={apartments} alt="apartments" />
                                  </span>
                                    Apartments
                                </Link>
                            </li>
                         {/*   <li>
                                <Link to="/property-rent" >
                                  <span className="icon">
                                    <img src={propertyRent} alt=" Property Rent" />
                                  </span>
                                  Property Rent
                                </Link>
                            </li>
                            <li>
                                <Link to="/property-for-sale" >
                                  <span className="icon">
                                    <img src={propertySale} alt="Property For Sale" />
                                  </span>
                                    Property For Sale
                                </Link>
                            </li>
                        </ul> */}
        </ul>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  golbal: state.golbal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: (bol) => dispatch(toggleMenu(bol)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarMenu);
