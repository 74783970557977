let Config = {
  PERSIST_SECRET_KEY: "Dynamic living",
  ENVIRONMENT: "DL_ENV",
  API_URL: "https://api.thedynamicliving.com/v1/",
  // API_URL: "https://dynamiclivebk.appnofy.com/v1/",
  // API_URL: "http://3.16.172.190:3000/v1/",
  // API_URL: "https://api.thedynamicliving.com/v1/",
  // API_URL: "http://localhost:3000/v1/",
  GOOGLE_MAP_API_KEY: "AIzaSyDJBaclq9kIe5DnJcScEUCvt-9atb6epls",
};

// Config.env = () => {
//     return Config.ENVIRONMENTS[Config.ENVIRONMENT.DEVELOPMENT];
// };

export default Config;
