import { fetchUtil } from "../../utils/fetchUtils";
import { DVM_SUCCESS_FROM, DVM_SUCCESS_TO, FETCHING_DVM } from "./dvmActions";

export const MENU_OPEN = "MENU_OPEN";
export const DESIGNATION = "DESIGNATION";
export const DESIGNATION_FORM_HIDE = "DESIGNATION_FORM_HIDE";
export const LANDING_PAGE = "LANDING_PAGE";
export const SEARCH_FLIGHT = "SEARCH_FLIGHT";

export const toggleMenu = (bol) => (dispatch) => {
  dispatch({ type: MENU_OPEN, payload: bol });
};

export const designation = (designation) => (dispatch) => {
  dispatch({ type: DESIGNATION, payload: designation });
};

export const designationFormHide = (designation) => (dispatch) => {
  dispatch({ type: DESIGNATION_FORM_HIDE, payload: designation });
};

export const landingPageToggle = (bol) => (dispatch) => {
  dispatch({ type: LANDING_PAGE, payload: bol });
};

export const searchingFlight = (data) => (dispatch) => {
  dispatch({ type: SEARCH_FLIGHT, payload: data });
};
