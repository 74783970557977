import React, { Component } from "react";
import CompaniesListing from "../../components/CompaniesListing/CompaniesListing";
class JunkRemovalCompanies extends Component {

    render() {
        return  <CompaniesListing type="junk"/>
    }
}



export default  JunkRemovalCompanies;
