import React, { Component, useState, useEffect } from "react";
import "./MyBookings.css";
import { fetchUtil } from "../../utils/fetchUtils";
import moment from "moment";

const MyBookings = () => {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       booking: "",
  //     };
  //   }

  //   async getBooking() {
  //     const bookingData = await fetchUtil({
  //       url: "flight/booking/list?offset=0&limit=30",
  //     });
  //     if (bookingData) {
  //       this.setState({ booking: bookingData?.data?.list || [] });
  //     }
  //   }
  //   componentDidMount() {
  //     this.getBooking();
  //   }

  //     const { booking } = this.state;
  //     console.log(booking);

  const [booking, setBooking] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("DLToken");

    const getBooking = async () => {
      let result = await fetch(
        "https://api.thedynamicliving.com/v1/flight/booking/list?offset=0&limit=30",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      result = await result.json();
      const bookings = result.data.list || []; // Extract the array of bookings or use an empty array as fallback
      setBooking(bookings);
      console.log("result", result);
    };
    getBooking();
  }, []);

  console.log("booking", booking);

  // const carriers = {
  //   KL: "KLM ROYAL DUTCH AIRLINES",
  //   AC: "AIR CANADA",
  //   SG: "SPICEJET",
  //   FZ: "FLYDUBAI",
  //   AF: "AIR FRANCE",
  //   EK: "EMIRATES",
  //   UK: "VISTARA",
  //   TK: "TURKISH AIRLINES",
  //   DL: "DELTA AIR LINES",
  //   LH: "LUFTHANSA",
  //   VS: "VIRGIN ATLANTIC",
  //   "6E": "INDIGO",
  // };

  return (
    <section className="book-flight-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="book-flight">
                <h2 className="my-bookings my-5">My Bookings</h2>
              <>
                {booking.length != 0
                  ? booking?.map((item, index) => (
                      <div className="w-100 ticket-card mb-5">
                        <div className="row p-5">
                          <div className="col-9">
                            {item?.flightData.flightOffers[0].itineraries.map((ri) =>
                              ri?.segments.map((s, idx) => (
                                <div className="row mb-5 flight-card-con">
                                  <div className="col-4 d-flex justify-content-center align-items-center flight-card-left">
                                    {item?.flightData.flightOffers[0].airlineNames[s.carrierCode]}
                                  </div>
                                  <div className="col-8">
                                    <div className="d-flex justify-content-between align-items-end mb-2">
                                      <p className="m-0 f-13 font-secondary d-flex flex-column justify-content-center align-items-center">
                                        <span>{moment(s.departure.at).format('DD-MM-YYYY')}</span>
                                        <span>{moment(s.departure.at).format('HH MM')}</span>
                                      </p>
                                      <p className="m-0 d-flex flex-column">
                                        <span className="m-0 text-center f-13 font-secondary">
                                          Total
                                        </span>
                                        <span className="m-0 f-13 font-secondary">
                                        {s.duration}
                                        </span>
                                      </p>
                                      <p className="m-0 f-13 font-secondary d-flex flex-column justify-content-center align-items-center">
                                        <span>{moment(s.departure.at).format('DD-MM-YYYY')}</span>
                                        <span>{moment(s.arrival.at).format('HH MM')}</span>
                                      </p>
                                    </div>
                                    <div className="line-bar"></div>
                                    <div className="d-flex justify-content-between align-items-start mt-2 flight-details-locations">
                                      <p className="m-0 f-13 font-secondary">{s.departure.iataCode}</p>
                                      <p className="m-0 f-13 font-secondary">
                                        Direct Flight
                                      </p>
                                      <p className="m-0 f-13 font-secondary">{s.arrival.iataCode}</p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                          <div className="col-3">
                            <div className="selection-box text-center py-5">
                                <p className="font-secondary font-15 m-0">Total price</p>
                                <h2 className="text-black f-20 font-secondary">
                                $ {item?.flightData.flightOffers[0]?.price?.total}
                                </h2>
                            </div>
                            </div>
                        </div>
                      </div>
                    ))
                  : null}
              </>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyBookings;
