import React, { Component } from "react";
import "./ConfirmationModal.scss";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
class ConfirmationModal extends Component {

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.toggle} className="confirmation-modal mx-auto" centered>
                <ModalBody>
                    <p className="py-4 px-3 f-14">
                        {this.props.text}
                    </p>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-end py-2">
                        <button className="btn btn-dark btn-small mr-3" onClick={this.props.onClickNo}>No</button>
                        <button className="btn btn-primary btn-small" onClick={this.props.onClickYes}>Yes</button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ConfirmationModal;
