import React, { Component } from "react";
import "./jobs.scss";
import { getJobs } from "../../../store/actions";
import { connect } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";
import {
  getQueryString,
  getFilterBY,
  sortByAsc,
  sortByDes,
} from "../../../utils/helper";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import JobDetails from "./JobDetails";
import { Pagination } from "antd";

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      detailsId: null,
      filterBy: "",
      name: "",
      keywords: "",
      location: "",
      currentPage: 1,
      sortBy: null,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.openedDetails = this.openedDetails.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    let filterBy = "",
      name = "";
    if (location.search) {
      filterBy = getFilterBY(location.search);
      name = getQueryString(location.search, filterBy);
    }
    if (name) {
      this.props.getJobs(name);
    }
    this.setState({
      filterBy,
      name,
    });
  }

  componentDidUpdate(nextProps) {
    const { location } = nextProps.history;
    const { name } = this.state;
    let nextName = null;
    let filterBy = "";
    if (location.search) {
      filterBy = getFilterBY(location.search);
      nextName = getQueryString(location.search, filterBy);
    }
    if (nextName && name !== nextName) {
      this.props.getJobs(nextName);
      this.setState({
        filterBy,
        name: nextName,
      });
    }
  }

  toggleModal() {
    const modalOpened = this.state.modalOpened;
    this.setState({
      modalOpened: !modalOpened,
    });
  }

  filter(order) {
    this.setState({
      sortBy: order,
    });
  }

  openedDetails(id) {
    this.setState({
      detailsId: id,
    });
    this.toggleModal();
  }

  pageChange(e) {
    this.props.getJobs(this.state.name, this.state.keywords, e);
    this.setState({
      currentPage: e,
    });
  }

  jobsRender() {
    const { listing } = this.props;
    const jobs = listing.jobs;
    if (jobs) {
      // if (0) {
      if (
        // jobs.SearchResult &&
        // jobs.SearchResult.SearchResultItems &&
        // jobs.SearchResult.SearchResultItems.length
        jobs.jobs &&
        jobs.jobs.length
      ) {
        // const jobData = jobs.SearchResult.SearchResultItems;
        const jobData = jobs.jobs;
        return (
          <>
            <div className="job-listing__wrapper">
              <ul className="bullet">{this.jobMap(jobData)}</ul>
            </div>
            <div className="pagination-container">
              <Pagination
                defaultCurrent={1}
                current={this.state.currentPage}
                onChange={(e) => this.pageChange(e)}
                defaultPageSize={20}
                // total={1180}
                total={jobs.totalCount}
                hideOnSinglePage={true}
                showSizeChanger={false}
              />
            </div>
          </>
        );
      }
    } else {
      return (
        <div className="job-listing__wrapper p-5 text-white text-center">
          No jobs found
        </div>
      );
    }
  }

  handleSubmit(event, errors, values) {
    // let self = this;
    const { location } = this.props;
    const filterBy = getFilterBY(location.search);
    const locationName = getQueryString(location.search, filterBy);
    const { keywords } = values;
    this.props.getJobs(locationName, keywords);
    this.setState({
      currentPage: 1,
      keywords,
    });
  }

  jobMap(jobs) {
    let jobsListing = [];
    if (jobs.length) {
      jobsListing = jobs;
    }
    if (this.state.sortBy) {
      jobsListing.forEach((r) => {
        r.sortName = r.title;
      });
      jobsListing =
        this.state.sortBy === "asc"
          ? sortByAsc(jobsListing, "sortName")
          : sortByDes(jobsListing, "sortName");
    }

    return jobsListing.map((job, i) => {
      return (
        <li
          key={`jobs-map-${i}`}
          className="job-name text-white pointer"
          onClick={() => this.openedDetails(job.id)}
        >
          <div>{job.title}</div>
          <div className="organization">
            <span className="d-inline-block f-15"> {job.company}</span>
            <span className="ml-3 d-inline-block">
              ({`Source: ${job.source}`})
            </span>
          </div>
        </li>
      );
    });
  }

  render() {
    const { listing } = this.props,
      { loading } = listing,
      modalOpened = this.state.modalOpened;
    const { name } = this.state;

    return (
      <div>
        <SidePanel>
          <div className="crime">
            <div className="d-flex justify-content-between align-items-center p-4 m-3">
              <h3 className="primary-heading text-white m-0  heading">
                Jobs in {name}
              </h3>
              <div className="dropdown filter-dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="icon-filter">&nbsp;</i>
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    onClick={() => this.filter("asc")}
                  >
                    Ascending
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => this.filter("des")}
                  >
                    Descending{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <AvForm
                onSubmit={this.handleSubmit.bind(this)}
                ref={(c) => (this.form = c)}
              >
                <div className="row m-0">
                  <div className="col-12 col-md-8">
                    <AvField
                      className="jobs-search"
                      name="keywords"
                      placeholder="Search here"
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FormGroup className="d-flex justify-content-center">
                      <Button
                        className="f-14 text-uppercase font-weight-bold btn-primary"
                        style={{ height: "50px" }}
                      >
                        Search
                        {loading ? (
                          <i className="icon-reload text-white loading"></i>
                        ) : null}
                      </Button>
                    </FormGroup>
                  </div>
                </div>
              </AvForm>
            </div>
            {loading ? <Loading /> : this.jobsRender()}
          </div>
        </SidePanel>
        {modalOpened && (
          <Module
            id="crime"
            modalOpened={modalOpened}
            closeModal={this.toggleModal}
          >
            <JobDetails city={name} id={this.state.detailsId} />
          </Module>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listing: state.listing,
  golbal: state.golbal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getJobs: (keywords, location, page) =>
      dispatch(getJobs(keywords, location, page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
