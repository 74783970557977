import React, { Component } from "react";
import "./Module.scss";

class Module extends Component {
    render() {
        const {id = 'empty', modalOpened} = this.props;
        const isActive = modalOpened ? 'active' : '';
        return (
            <div id={`modal-${id}`} className={`dl-modal ${isActive}`}>
                <div className="dl-modal__content">
                    <button onClick={this.props.closeModal} className="text-white close-modal">Close X</button>
                    <div className="dl-modal__content-container fancy-scroll">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
export default Module;
