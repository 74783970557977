import React, { Component } from "react";
import './RealtorsListing.scss'
import { connect } from "react-redux";
import {getQueryString, getFilterBY, sortByAsc, sortByDes} from "../../utils/helper";
import RealtorsDetails from "./RealtorsDetails";
import Module from "../Module/Module";
import {getRealtors, resetRealtors} from "../../store/actions";
import Loading from "../Loading/Loading";
import {Redirect, withRouter} from "react-router-dom";
import SelectDestination from "../SelectDestination/SelectDestination";

class RealtorsListing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalOpened : false,
      detailsId : null,
      filterBy : '',
      name: '',
      sortBy : null,
      pageId : 'home'
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.openedDetails = this.openedDetails.bind(this);
    this.filter = this.filter.bind(this);
  }

  componentDidMount() {
    const pageId =  this.getType();
    this.setState({pageId})
    this.resetState();
  }

  formSubmit = (values) => {
    this.setState({
      filterBy: values.filterBy,
      name: values.destination
    });
    setTimeout(() => {
      this.props.getRealtors(values.destination, values.filterBy, this.state.pageId);
    });
  };

  componentDidUpdate(nextProps) {
    const pageId =  this.getType();
    if(pageId && pageId !== this.state.pageId){
      this.setState({pageId});
      this.resetState();
    }
  }

  resetState(){
    this.props.resetRealtors();
  }

  getType(){
    const pageId = this.props?.match?.params?.id;
    let val = 'Single Family';
    switch(pageId) {
      case 'apartments':
        val = 'Apartments'
        break;
      case 'condo':
        val = 'Condo';
        break;
      case 'home':
        val = 'Single Family';
        break;
      default:
        val = 'Single Family';
    }
    return val;
  }

  toggleModal(){
    const modalOpened = this.state.modalOpened;
    this.setState({
      modalOpened : !modalOpened
    })
  }

  filter(order){
    this.setState({
      sortBy : order
    })
  }

  openedDetails(id){
    this.setState({
      detailsId : id
    });
    this.toggleModal();
  }

  realtorRender() {
    const { listing } = this.props;
    if (listing.realtorsListing?.length) {
        return (
            <div className="crime-listing__wrapper">
              <ul className="bullet">
                {this.realtorMap(listing.realtorsListing)}
              </ul>
            </div>
        )
      }
    else if(listing.realtorsListingMessage) {
      return (
          <div className="py-5 text-center text-white">
            {listing.realtorsListingMessage}
          </div>
      );
    }
  }
  realtorMap(listing) {
    if (listing?.length)
      return listing.map(item =>
          <li key={item._id} className="crime-name text-white pointer d-block mb-3" onClick={() => this.openedDetails(item._id)}>
            <span>{item.formattedAddress}</span>
            <span className="d-flex align-items-center">
              <small>Square Footage : {item.squareFootage}</small>
              <small  className="ml-5 pl-5">Price : $ {item.price}</small>
            </span>
          </li>
      )
  }



  realtorsListingRender() {
    const modalOpened = this.state.modalOpened;
    const {name} = this.state;
    return (
        <div>
            <div>{this.realtorRender()}</div>
          {
            modalOpened &&   <Module id="realtor-listing" modalOpened={modalOpened} closeModal={this.toggleModal}>
              <RealtorsDetails city={name}  id={this.state.detailsId}/>
            </Module>
          }
        </div>
    );
  }


  render() {
    const { loggedIn } = this.props.auth;
    const { loading } = this.props.listing;
    if (!loggedIn) return <Redirect to="/account/login" />;

    return (
        <div className="realtor-listing">
          <div className="container list-change__container">
            <div className="row">
              <div className="col-12 pb-5">
                <div className="form d-flex justify-content-center">
                  <SelectDestination
                      onClickHandler={(values) => this.formSubmit(values)}
                      className="dvm"
                  />
                </div>
                <div className="my-5">
                  {loading ? <Loading /> : this.realtorsListingRender()}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  listing: state.listing,
  golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
  return {
    getRealtors: (city, filterBy, category) => dispatch(getRealtors(city, filterBy, category)),
    resetRealtors: () => dispatch(resetRealtors()),

  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RealtorsListing));

