import React, { Component } from "react";
import "./Info.scss";
import Loading from "../../../components/Loading/Loading";
import {getInfo} from "../../../store/actions";
import {getQueryString, isMillion, getFilterBY} from "../../../utils/helper";
import {connect} from "react-redux";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import InfoDetails from "./InfoDetails";


class Info extends Component{

    constructor(props) {
        super(props);
        this.state = {
            modalOpened : false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        let filterBy = '',
            name = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            name = getQueryString(location.search, filterBy);
        }
        // TODO  temporary check  filter by city
        if (name && filterBy === 'state') {
            this.props.getInfo(name);
        }
        this.setState({
            filterBy, name
        })
    }

    componentDidUpdate(nextProps) {
        const {location} = nextProps.history;
        const {name} = this.state;
        let nextName = null;
        let filterBy = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            nextName = getQueryString(location.search, filterBy);
        }
        if (nextName && name !== nextName) {
            if(filterBy === 'state'){
                this.props.getInfo(nextName);
            }
            this.setState({
                filterBy,
                name : nextName
            })
        }
    }

    toggleModal(){
        const modalOpened = this.state.modalOpened;
        this.setState({
            modalOpened : !modalOpened
        })
    }

    infoDetails(){
        // TODO temporary check
        const { name, filterBy } = this.state;
        if(filterBy === 'city'){
            return <h5 className="p-5 text-white display-4">{name} will be soon data</h5>
        }

       const {info} = this.props.listing;
        if(!info || !info.info)
            return null;

       return (
            <div className="info-listing__wrapper">
                <ul className="bullet">
                    <li className="text-white d-flex" >
                        <div className="w-50 w-">
                            City Code :
                        </div>
                        <div className="w-50">
                            {info.info.cityCode}
                        </div>
                    </li>
                    <li className="text-white d-flex"  >
                        <div className="w-50">
                            Population:
                        </div>
                        <div className="w-50">
                            {isMillion(info.info.population)}
                        </div>
                    </li>
                    <li className="text-white d-flex" >
                        <div className="w-50">
                            Children Population:
                        </div>
                        <div className="w-50">
                            {isMillion(info.info.childPopulation)}
                        </div>
                    </li>
                    <li className="text-white d-flex" >
                        <div className="w-50">
                            Married:
                        </div>
                        <div className="w-50">
                            {info.info.married}%
                        </div>
                    </li>
                    <li className="text-white d-flex" >
                        <div className="w-50">
                            Average Age:
                        </div>
                        <div className="w-50">
                            {info.info.avgAge}%
                        </div>
                    </li>
                </ul>

                <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-primary" onClick= {this.toggleModal}>More Details</button>
                </div>
            </div>
        )
    }

    render() {
        const {listing } = this.props,
            { loading, crime } = listing,
            modalOpened = this.state.modalOpened;
        const {name} = this.state;

        return (
            <div>
                <SidePanel>
                    <div className="info-details">
                        <h3 className="primary-heading text-white m-0 py-3 py-md-5 heading pl-5">Information {name}</h3>
                        {
                            loading ? <Loading /> : this.infoDetails(crime)
                        }
                    </div>
                </SidePanel>
                {
                    modalOpened &&   <Module id="info" modalOpened={modalOpened} closeModal={this.toggleModal}>
                        <InfoDetails city={name}/>
                    </Module>
                }
            </div>
        );
    }
}




const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});
const mapDispatchToProps = dispatch => {
    return {
        getInfo: (city) => dispatch(getInfo(city))
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Info);

