import React, { Component } from "react";
import "./Journey.scss";

import FlightSearchForm from "../../components/FlightSearchForm/FlightSearchForm";
import { Redirect } from "react-router-dom";
import { designationFormHide } from "../../store/actions";
import { connect } from "react-redux";


class Journey extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  submitHandler = () => {
    this.setState({
      redirect: true,
    });
  };

  render() {
    const { loggedIn } = this.props.auth;

    if (!loggedIn) return <Redirect to="/account/login" />;
    if (this.state.redirect) return <Redirect to="/book-flight" />;

    return (
      <div className="let-start-journey font-secondary">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-11 mx-auto  full-content-height mt-0 d-flex align-items-center py-5">
              <div className="w-100">
                <h2 className="mb-3 text-white">Let the journey begin</h2>
                <h3 className="mb-4 mb-lg-5 text-white">
                  Fly anywhere. Fly everywhere.
                </h3>
                <FlightSearchForm isJourney={true} onSubmit={this.submitHandler} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  golbal: state.golbal,
});

export default connect(mapStateToProps, null)(Journey);
