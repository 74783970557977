import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./Dvm.scss";
import DestinationForm from "../../components/DestinationForm/DestinationForm";
import { designationFormHide, dvm } from "../../store/actions";
import Loading from "../../components/Loading/Loading";
import renderHTML from "react-render-html";

class Dvm extends Component {
  componentDidMount() {
    this.requestData();
  }

  formSubmit = () => {
    setTimeout(() => {
      this.requestData();
    });
  };

  requestData = () => {
    setTimeout(() => {
      const { to, from } = this.props.golbal.designation;
      if (to && to.name && from && from.name) {
        this.props.dvm(to.name, true);
        this.props.dvm(from.name, false);
      } else if (to && to.name) {
        this.props.dvm(to.name, true);
      }
    });
  };

  renderQA(data) {
    if (!data || !data.length) return null;

    return data.map((q, i) => {
      return (
        <div key={`q-${i}`}>
          <p className="bold">{q.Q}</p>
          <p className="ans">
            {q.A.map((a, ai) => {
              return <span key={`a-${i}-${ai}`}>{renderHTML(a)}</span>;
            })}
          </p>
        </div>
      );
    });
  }

  renderDvmData() {
   
    const { dvmData, golbal } = this.props,
    { to, from } = golbal.designation,
    { movingTo, movingFrom } = dvmData,
    url = window.location.pathname,
    isLicense = url === "/license-change",
    keyName = isLicense
      ? "DRIVERS_LICENSE_CHANGE_OF_ADDRESS"
      : "VEHICLE_REGISTRATION_CHANGE_OF_ADDRESS_INFORMATION",
    movingToData =
      movingTo?.moves && movingTo?.moves?.movingTo
        ? movingTo?.moves?.movingTo[keyName]
        : "",
    movingFromData =
      movingFrom?.moves && movingFrom?.moves.movingFrom
        ? movingFrom?.moves.movingFrom[keyName]
        : "";
  
    return (
      <div>
        <div className="dvm my-3 my-md-5">
          <h1 className="text-white text-center display-4">
            To begin with, below are the steps for DMV
          </h1>
        </div>
        <div className="row px-0 justify-content-center">
          <div className="col-12 px-0">
            <div id="accordion">
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link d-flex justify-content-between collapsed"
                    data-toggle="collapse"
                    href="#collapseOne"
                  >
                    For {to.name}
                    <i className="icon-plus-circle center-icon"></i>
                    <i className="icon-minus-circle center-icon"></i>
                  </a>
                </div>
                <div
                  id="collapseOne"
                  className="collapse"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <h1 className="text-uppercase lc-steps-header">
                      {movingTo?.error_is ? (
                        <span>{movingTo?.error_is}</span>
                      ) : (
                        <span>
                          {" "}
                          {to.name} {isLicense ? "License" : "Vehicle"} Change
                          of Address
                        </span>
                      )}
                    </h1>
                    {this.renderQA(movingToData)}
                  </div>
                </div>
              </div>

              {from && from.name ? (
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link d-flex justify-content-between collapsed"
                      data-toggle="collapse"
                      href="#collapseTwo"
                    >
                      For {from.name}
                      <i className="icon-plus-circle center-icon"></i>
                      <i className="icon-minus-circle center-icon"></i>
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <h1 className="text-uppercase lc-steps-header">
                        {movingFrom?.error_is ? (
                          <span>{movingFrom?.error_is}</span>
                        ) : (
                          <span>
                            {" "}
                            {from.name} {isLicense ? "License" : "Vehicle"}{" "}
                            Change of Address
                          </span>
                        )}
                      </h1>
                      {this.renderQA(movingFromData)}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDvm() {
    const { loading } = this.props.dvmData;
    return loading ? <Loading /> : this.renderDvmData();
  }

  render() {
    const { loggedIn } = this.props.auth;
    const { to, from } = this.props.golbal.designation;
    if (!loggedIn) return <Redirect to="/account/login" />;

    return (
      <div className="list-change">
        <div className="container list-change__container">
          <div className="row">
            <div className="col-12">
              <div className="form d-flex justify-content-center">
                <DestinationForm
                  hideState={true}
                  onClickHandler={this.formSubmit}
                  className="dvm"
                />
              </div>
              {to && to.name ? this.renderDvm() : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  golbal: state.golbal,
  auth: state.auth,
  dvmData: state.dvm,
});

const mapDispatchToProps = (dispatch) => {
  return {
    designationFormHide: (bol) => dispatch(designationFormHide(bol)),
    dvm: (city, to) => dispatch(dvm(city, to)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dvm);
