import React, { Component } from "react";
import "./HistoricalWeatherDetails.scss";
import {getHistoricalWeather, getWeatherDetails} from "../../../store/actions";
import {connect} from "react-redux";
import moment from "moment";
import {getCengarde} from "../../../utils/helper";
import Loading from "../../../components/Loading/Loading";

const currentDate = moment().format('YYYY-MM-DD');
// console.log('currentDate.............', currentDate);
class HistoricalWeatherDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {
            startDate : '',
            endDate : '',
            submitted : false,
            activeWheather: 'summer'
        };
    }
    componentWillMount() {
        const {city} = this.props;
        if(city){
            console.log('city.........', city);
        }
    }

    componentDidMount() {
        this.setState({
            submitted : true
        })
        this.props.getHistoricalWeather(this.props.city, this.state.activeWheather);
    }

    onChange = (e) =>{
        let {name, value } = e.target;
        if(name === 'startDate' && !!this.state.endDate){
            const given = moment(value, "YYYY-MM-DD");
            const current = moment(this.state.endDate).startOf('day');
            const different =  moment.duration(current.diff(given)).asDays();
            if(different > 60){
                this.setState({
                    startDate : value,
                    endDate : ''
                })
            }
            this.setState({[name]: value});
        }else {
            this.setState({[name]: value});

        }
    }
   
    submit = (e) =>{
        e.preventDefault();
        const {startDate, endDate} = this.state;
        this.setState({
            submitted : true
        })
        this.props.getHistoricalWeather(this.props.city, startDate, endDate);
    }

    renderForeCast = () =>{
        const {historicalWeather} = this.props.listing;
        // console.log('historicalWeather', historicalWeather);
        let historical = [];
        if(historicalWeather?.data){
            for (let key in historicalWeather.data) {
                var temp = Object.keys(historicalWeather?.data[key]?.historical)[0];
                historical.push(historicalWeather?.data[key]?.historical[temp])
            }
        }
        // console.log("historical", historical)
        if(historical?.length){
            return historical.map((list)=>{
                return  <div className="row m-0" key={list?.date} >
                    <div className="col-12 d-flex flex-column flex-md-row align-items-center forecasting justify-content-between">
                        <div className="d-flex align-items-center justify-content-between w-100 forecasting-container">
                            <div className="d-flex flex-column px-3 px-md-0  key-value">
                                <span className="key">Date:</span>
                                <span className="value">{list?.date}</span>
                            </div>
                            <div className="d-flex flex-column px-3 px-md-0 key-value">
                                <span className="key">Max Temp:</span>
                                <span className="value">{getCengarde(list?.maxtemp)}</span>
                            </div>
                            <div className="d-flex flex-column px-3 px-md-0 key-value">
                                <span className="key">Min Temp:</span>
                                <span className="value">{getCengarde(list?.mintemp)}</span>
                            </div>
                            <div className="d-flex flex-column px-3 px-md-0 key-value">
                                <span className="key">Moon phase:</span>
                                <span className="value d-flex">
                                    {list?.astro?.moon_phase}</span>
                            </div>
                            <div className="d-flex flex-column px-3 px-md-0 key-value">
                                <span className="key">Moon Timing:</span>
                                <span className="value">{list?.astro?.moonrise} - {list?.astro?.moonset}</span>
                            </div>
                            <div className="d-flex flex-column px-3 px-md-0 key-value">
                                <span className="key">Sun Timing:</span>
                                <span className="value">{list?.astro?.sunrise} - {list?.astro?.sunset}</span>
                            </div>
                        </div>
                    </div>
                </div>
            });
        }
        else {
            return <div className="d-flex justify-content-center align-items-center py-5">
                <p>No Data Found</p>
            </div>
        }

    }
    weatherHandler = (season) => {
        this.setState({activeWheather: season})
        this.props.getHistoricalWeather(this.props.city, season);
    }


    render(){
        const {historicalWeatherLoading} = this.props.listing;
        const {startDate, endDate, submitted} = this.state;
        let minDate, maxDate;
      
        if(startDate){
            minDate = moment(startDate).format('YYYY-MM-DD');
            const given = moment(minDate, "YYYY-MM-DD");
            const current = moment().startOf('day');
            const different =  moment.duration(current.diff(given)).asDays();
            if(different <= 60){
                maxDate = currentDate
            }
            else {
                maxDate = moment(startDate).add(60, "days").format("YYYY-MM-DD");
            }
        }


        return <div className="modal-content__container-weather text-white">
            <div className="modal-header text-center bg-primary border-0 text-white d-flex justify-content-center align-items-center overflow-hidden">
               Weather
            </div>
            <div>

           
            <div className=" content-weather-details d-flex justify-content-around flex-column flex-md-row py-4">

                    <button className={`btn ${this.state.activeWheather === "summer" ? `active` : ""}`} onClick={()=> this.weatherHandler("summer")}>
                        Summer
                    </button>
                    <button className={`btn ${this.state.activeWheather === "spring" ? `active` : ""}`} onClick={()=> this.weatherHandler("spring")}>
                        Spring
                    </button>
                    <button className={`btn ${this.state.activeWheather === "winter" ? `active` : ""}`} onClick={()=> this.weatherHandler("winter")}>
                        Winter
                    </button>
                    <button className={`btn ${this.state.activeWheather === "fall" ? `active` : ""}`} onClick={()=> this.weatherHandler("fall")}>
                        Fall
                    </button>
                    </div>
                {/* <form className="historical-date-form my-3" onSubmit={this.submit}>
                    <div className="d-flex align-items-end historical-date-form-container">
                        <div className="input-row">
                            <label htmlFor="startDate">Start Date</label>
                            <input type="date" name="startDate" value={startDate} max={currentDate} required onChange={this.onChange} className="form-control" id="startDate"/>
                        </div>
                        <div className="input-row">
                            <label htmlFor="endDate">End Date</label>
                            <input type="date" name="endDate"  disabled={!startDate} value={endDate} required min={minDate}  max={maxDate}   onChange={this.onChange}  className="form-control" id="endDate"/>
                        </div>
                        <button type="submit" className="btn btn-primary btn-sm">Submit</button>

                    </div>
                </form> */}
                {
                    submitted ?
                        ( historicalWeatherLoading ? <Loading/> : this.renderForeCast() )
                        : null
                }
            </div>
        </div>
            ;
    }


}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getHistoricalWeather: (city, season) => dispatch(getHistoricalWeather(city, season))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoricalWeatherDetails);

