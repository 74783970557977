import React, { Component } from "react";
import './Crime.scss'
import { getCrime } from "../../../store/actions";
import { connect } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import {getQueryString, getFilterBY, sortByAsc, sortByDes} from "../../../utils/helper";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import CrimeDetails from "./CrimeDetails";

class Crime extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpened : false,
            detailsId : null,
            filterBy : '',
            name: '',
            sortBy : null
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.openedDetails = this.openedDetails.bind(this);
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        let filterBy = '',
            name = '';
        if(location.search) {
             filterBy = getFilterBY(location.search);
             name = getQueryString(location.search, filterBy);
        }
        if (name) {
            this.props.getCrime(name, filterBy);
        }
        this.setState({
            filterBy, name
        })
    }

    componentDidUpdate(nextProps) {
        const {location} = nextProps.history;
        const {name} = this.state;

        let nextName = null;
        let filterBy = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            nextName = getQueryString(location.search, filterBy);
        }
        if (nextName && name !== nextName) {
                this.props.getCrime(nextName, filterBy);
            this.setState({
                filterBy,
                name : nextName
            })
        }
    }

    toggleModal(){
        const modalOpened = this.state.modalOpened;
        this.setState({
            modalOpened : !modalOpened
        })
    }

    filter(order){
        this.setState({
            sortBy : order
        })
    }

    openedDetails(id){
        this.setState({
            detailsId : id
        });
        this.toggleModal();
    }
    crimeRender(crime) {
        const { listing } = this.props;
        if (listing.crime) {
            if(crime.incidents){
                return (
                    <div className="crime-listing__wrapper">
                        <ul className="bullet">
                            {this.crimeMap(crime.incidents)}
                        </ul>
                    </div>
                )
            }
            else if(crime.message){
                return <h2 className="py-5 text-center text-white">
                    {crime.message}
                </h2>
            }
        }
        else {
            return (
                <div className="py-5 text-center text-white">
                    Not Crime found
                </div>
            );
        }
    }
    crimeMap(crime) {
        let crimeListing  = crime;
        if(this.state.sortBy){
            crimeListing = this.state.sortBy === 'asc' ? sortByAsc(crime, 'incident_address'): sortByDes(crime, 'incident_address');
        }

        if (crimeListing)
            return crimeListing.map(crime =>
                <li key={crime.incident_code} className="crime-name text-white pointer" onClick={() => this.openedDetails(crime.incident_code)}>
                    {crime.incident_address} , {crime.incident_offense}
                </li>
            )
    }



    render() {
        const {listing } = this.props,
            { loading, crime } = listing,
            modalOpened = this.state.modalOpened;
        const {name} = this.state;

        return (
            <div>
                <SidePanel>
                    <div className="crime">
                        <div className="d-flex justify-content-between align-items-center p-4 m-3">
                            <h3 className="primary-heading text-white m-0  heading">Crime in {name}</h3>
                            <div className="dropdown filter-dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                                        aria-expanded="false">
                                    <i className="icon-filter">&nbsp;</i>
                                </button>
                                <div className="dropdown-menu">
                                    <button className="dropdown-item" onClick={ () => this.filter('asc')} >Ascending</button>
                                    <button className="dropdown-item" onClick={ () => this.filter('des')}>Descending </button>
                                </div>
                            </div>
                        </div>
                        {
                            loading ? <Loading /> : this.crimeRender(crime)
                        }
                    </div>
                </SidePanel>
                {
                    modalOpened &&   <Module id="crime" modalOpened={modalOpened} closeModal={this.toggleModal}>
                        <CrimeDetails city={name}  id={this.state.detailsId}/>
                    </Module>
                }
            </div>

        );
    }
}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getCrime: (city, filterBy) => dispatch(getCrime(city, filterBy))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Crime);

