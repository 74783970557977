import States from "../constant/states";

export const emailValidation = email => {
    const mail =  email.trim();
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase());
};

export const getQueryString = (queryString, query) => {
    if(!queryString)
        return null;

    const params = new URLSearchParams(queryString);
    return params.get(query);
};

export const getFilterBY = (queryString) => {
    if(!queryString)
        return null;

    let query = queryString.split("=");
    if(!query?.length)
        return null;

    if(query[0].includes('city'))
        return 'city';

    if(query[0].includes('state'))
        return 'state';

};

export const getTimeFormat = (time) => {
    const date =  time || new Date();
    let hr = date.getHours();
    let min =  date.getMinutes();
    const format =  hr < 12 ? 'am' : 'pm';

    hr = hr % 12 || 12;
    min = min < 10 ? `0${min}` : min;
    return `${hr}:${min} ${format}`
};


export const getDateFormat = (d) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months= ["January","February","March","April","May","June","July", "August","September","October","November","December"];

    const date =  d || new Date();
    let  day = date.getDay();
    let  month = date.getMonth();
    let  isDate = date.getDate();
    day = days[day];
    month = months[month];
    isDate = isDate < 10 ? `0${isDate}` : isDate;
    return `${day},${month} ${isDate}`
};


export const getCentimeter  = (val) =>{
   const centi =  parseInt(val - 273.15) ;
        return `${centi}°C`;
};
export const getCengarde  = (val) =>{
        return `${val}°C`;
};
export const getDay  = (d, short) =>{
    const date =  d ? new Date(d) : new Date();
    const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let  day = date.getDay();
    return short ? shortDays[day] : days[day];
};

export const isMillion   = (val) =>{
    if(val >= 1000000){
        const m =  (val/1000000).toFixed(2);
        return m + ' M';
    }
    return val
};

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
});

export const currency   = (val) =>{
   const check =  isNaN(val);
   if(check)
       return val;

    return formatter.format(val); /* $2,500.00 */
};


export const sortedStateByCityAlphabetic   = () =>{
   const state = [...States];
    state.sort(function(a, b){
        if(a.cities[0].name < b.cities[0].name) { return -1; }
        if(a.cities[0].name > b.cities[0].name) { return 1; }
        return 0;
    })
    return state
};


export const sortByAsc = (array, key) =>{
   if(array && array.length){
     return   array.sort(function(a, b){
           if(a[key] < b[key]) { return -1; }
           if(a[key] > b[key]) { return 1; }
           return 0;
       })
   }
   return array
};

export const sortByDes = (array, key) =>{
    if(array && array.length){
        return   array.sort(function(a, b){
            if(a[key] > b[key]) { return -1; }
            if(a[key] < b[key]) { return 1; }
            return 0;
        })
    }
    return array
};