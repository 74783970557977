import {
    FETCHING,
    HOTEL_SUCCESS,
    HOTEL_DETAILS_SUCCESS,
    WEATHER_SUCCESS,
    WEATHER_DETAILS_SUCCESS,
    FETCHING_DETAILS,
    HISTORY_SUCCESS,
    JOB_SUCCESS,
    CRIME_SUCCESS,
    EDUCATION_SUCCESS,
    SALARY_SUCCESS,
    LEFT_PANEL_OPEN,
    INFO_SUCCESS,
    MOVES_SUCCESS,
    HOSPITAL_SUCCESS,
    ENTERTAINMENTS_SUCCESS,
    COMPANIES_LISTING_SUCCESS,
    RESET_COMPANIES_LISTING_SUCCESS,
    REALTORS_LISTING_SUCCESS,
    RESET_REALTORS_LISTING_SUCCESS,
    HISTORICAL_WEATHER_SUCCESS,
    HISTORICAL_WEATHER_REQUEST, OCCUPATIONS_SUCCESS, OCCUPATIONS_REQUEST
} from "../actions";

const initialState = {
    weather: [],
    hospital: [],
    hotels: [],
    loading: false,
    hotelDetails: {},
    history: {},
    salary: {},
    education: {},
    jobs: {},
    crime: {},
    info:{},
    moves : {},
   entertainments : [],
    companiesListing : [],
    realtorsListing : [],
    realtorsListingMessage : '',
    loadingDetails: false,
    leftPanelLeft: true,
    historicalWeatherLoading : false,
    historicalWeather : [],
    occupationsListing: [],
    occupationsLoading: false,

};

export default (state = initialState, action) => {

    switch (action.type) {
        case FETCHING:
            const companies  =  (action.page === 1 && action.payload) ?  [] : state.companiesListing;
            return {
                ...state,
                loading: action.payload,
                companiesListing : companies
            };
        case FETCHING_DETAILS:
            return {
                ...state,
                loadingDetails: action.payload
            };
        case WEATHER_SUCCESS:
            return {
                ...state,
                weather: action.payload,
            };
        case WEATHER_DETAILS_SUCCESS:
            return {
                ...state,
                weather: {
                    ...state.weather,
                    weatherDetails: action.payload
                },
            };
        case HOTEL_SUCCESS:
            return {
                ...state,
                hotels: action.payload,
            };
        case HOTEL_DETAILS_SUCCESS:
            return {
                ...state,
                hotelDetails: action.payload,
            };
        case HISTORY_SUCCESS:
            return {
                ...state,
                history: action.payload,
            };
        case EDUCATION_SUCCESS:
            return {
                ...state,
                education: action.payload,
            };
        case SALARY_SUCCESS:
            return {
                ...state,
                salary: action.payload,
            };
        case JOB_SUCCESS:
            return {
                ...state,
                jobs: action.payload,
            };
        case CRIME_SUCCESS:
            return {
                ...state,
                crime: action.payload,
            };
        case LEFT_PANEL_OPEN:
            return {
                ...state,
                leftPanelLeft: action.payload,
            };
            case INFO_SUCCESS:
            return {
                ...state,
                info: action.payload,
            };
        case MOVES_SUCCESS:
            return {
                ...state,
                moves: action.payload,
            };
        case HOSPITAL_SUCCESS:
            return {
                ...state,
                hospital: action.payload,
            };
        case ENTERTAINMENTS_SUCCESS:
            return {
                ...state,
                entertainments: action.payload,
            };
        case COMPANIES_LISTING_SUCCESS:
            const companiesListing = action.payload;
            if(action.page > 1){
                companiesListing.businesses = state.companiesListing?.businesses?.concat(companiesListing.businesses)
            }
            return {
                ...state,
                companiesListing: companiesListing,
            };
        case RESET_COMPANIES_LISTING_SUCCESS:
            return {
                ...state,
                companiesListing:[],
            };
        case REALTORS_LISTING_SUCCESS:
            let realtorsListing = action.payload;
            return {
                ...state,
                realtorsListing: realtorsListing,
                realtorsListingMessage : realtorsListing?.length ? '' : 'Not Data Found'
            };
        case RESET_REALTORS_LISTING_SUCCESS:
            return {
                ...state,
                realtorsListing: [],
                realtorsListingMessage : ''
            };
        case HISTORICAL_WEATHER_REQUEST:
            return {
                ...state,
                historicalWeatherLoading: action.payload,
            };
        case HISTORICAL_WEATHER_SUCCESS:
            return {
                ...state,
                historicalWeather: action.payload,
                historicalWeatherLoading: false,
            };
            case OCCUPATIONS_REQUEST:
            return {
                ...state,
                occupationsLoading: action.payload,
            };
        case OCCUPATIONS_SUCCESS:
            return {
                ...state,
                occupationsListing: action.payload,
                occupationsLoading: false,
            };
        default:
            return state;
    }
};
