import React, { Component } from "react";
import { connect } from "react-redux";
import { login } from "../../../store/actions";
import { emailValidation } from "../../../utils/helper";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import "../Account.scss";
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        password: "",
      },
      fieldError: {
        email: "Email must be required",
        password: "Password must be required",
      },
      submitted: false,
      isChecked: false,
      isPasswordTypeOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.submit = this.submit.bind(this);
    this.handlePasswordIconType = this.handlePasswordIconType.bind(this);
  }

  componentDidMount() {
    let remember = localStorage.getItem("__dynamic_remember");
    if (remember) {
      remember = JSON.parse(atob(remember));
      remember.password = atob(remember.password);
      this.setState({
        isChecked: true,
        user: remember,
      });
    }
  }

  handlePasswordIconType(){
    this.setState({
      isPasswordTypeOpen : !this.state.isPasswordTypeOpen
    })
  }
  handleChange(event) {
    let { name, value } = event.target;
    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
    if (this.state.submitted) {
      this.formValidation();
    }
  }

  handleBlur() {
    if (this.state.submitted) {
      this.formValidation();
    }
  }

  submit(event) {
    event.preventDefault();
    const { isChecked } = this.state;
    if (!this.state.submitted) {
      this.setState({ submitted: true });
    }
    const formValid = this.formValidation();
    if (formValid) {
      this.props.login(this.state.user, isChecked);
    }
  }

  formValidation() {
    let isValid = false;
    const { email, password } = this.state.user;
    const fieldError = this.state.fieldError;

    if (email) {
      const validEmail = emailValidation(email);
      fieldError.email = validEmail ? false : "Please enter a valid Email";
      isValid = validEmail;
    }
    if (!password && isValid) {
      isValid = false;
    }
    if (password) {
      fieldError.password =
        password.length >= 5 ? false : "Password must be greater then 5";
      if (isValid) {
        isValid = password.length >= 5;
      }
    }
    this.setState({ fieldError: fieldError });
    return isValid;
  }

  handleRememberMeCheckbox = (e) => {
    this.setState({ isChecked: e.target.checked });
  };

  render() {
    const { loading } = this.props.auth;
    const { email, password } = this.state.user;
    const emailError = this.state.fieldError.email;
    const passwordError = this.state.fieldError.password;
    const { submitted, isChecked } = this.state;
    return (
      <div className="login w-100">
        <form className="w-100" onSubmit={this.submit}>
          <h3 className="text-center primary-heading mb-5">Login</h3>

          <div className="input-field ">
            <input
              className={submitted && emailError ? "error" : ""}
              type="text"
              name="email"
              placeholder="email"
              value={email}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            {submitted && emailError ? <small>{emailError}</small> : null}
          </div>
          <div className="input-field password-input-con">
            <input
              className={submitted && passwordError ? "error" : ""}
              type={!this.state.isPasswordTypeOpen ? "password" : "text"}
              name="password"
              placeholder="password"
              value={password}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <span onClick={this.handlePasswordIconType}>
            {!this.state.isPasswordTypeOpen ? (<EyeOutlined style={{ fontSize: '20px', color: '#5b5b5b' }}/>):(<EyeInvisibleOutlined style={{ fontSize: '20px', color: '#5b5b5b' }}/>)}
            </span>
            {submitted && passwordError ? <small>{passwordError}</small> : null}
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-center  mb-5 f-14">
            <div className="check-box d-inline-flex align-items-center mb-3 mb-md-0">
              <input
                type="checkbox"
                id="remember"
                name="remember"
                checked={isChecked}
                onChange={this.handleRememberMeCheckbox}
              />
              <label htmlFor="remember" className="m-0 pl-4">
                {" "}
                Remember me
              </label>
            </div>
            <Link to="/account/forgot-password">Forgot Your password?</Link>
          </div>
          <button className="btn btn-primary">
            Submit
            {loading ? (
              <i className="icon-reload text-white loading"></i>
            ) : null}
          </button>
        </form>
        <div className="login__register">
          <span className="f-14">Don't have an account? </span>
          <span>
            <Link to="/account/registration" className="f-14">
              Click here
            </Link>
          </span>
          <span className="f-14"> to register.</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    login: (user, remember) => dispatch(login(user, remember)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
