import React, { Component } from "react";
import "./Weather.scss";
import {getWeather} from "../../../store/actions";
import {connect} from "react-redux";
import {getQueryString, getTimeFormat, getFilterBY} from "../../../utils/helper";
import Loading from "../../../components/Loading/Loading";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import WeatherDetails from "./WeatherDetails";
import HistoricalWeatherDetails from "./HistoricalWeatherDetails";

let filterBy = '';
let name = '';
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class Weather extends Component{
    constructor(props) {
        super(props);
        this.state = {
            modalOpened : false,
            detailsId : null,
            historicalDataOpened: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleHistoricalModal = this.toggleHistoricalModal.bind(this);
        this.openedDetails = this.openedDetails.bind(this);
    }


    componentDidMount() {
        const {location} = this.props;
        if(location.search) {
            filterBy = getFilterBY(location.search);
            name = getQueryString(location.search, filterBy);
        }

        if (name) {
            this.props.getWeather(name);
        }
    }

    componentDidUpdate(nextProps) {
        const {location} = nextProps.history;
        let nextName = null;
        if(location.search) {
            filterBy = getFilterBY(location.search);
            nextName = getQueryString(location.search, filterBy);
        }
        if (nextName && name !== nextName) {
            name = nextName
            this.props.getWeather(name);
        }
    }

    toggleModal(){
        const modalOpened = this.state.modalOpened;
        this.setState({
            modalOpened : !modalOpened
        })
    }

    toggleHistoricalModal(){
        const isOpened = this.state.historicalDataOpened;
        this.setState({
            historicalDataOpened : !isOpened
        })
    }

    openedDetails(id){
        this.setState({
            detailsId : id
        });
        this.toggleModal();
    }

    weatherDetails(){
        const {weather} = this.props.listing;
        const d = new Date();
        const dayName = days[d.getDay()];
        if(!weather.name){
            return  null;
        }
        return (
            <div className="d-flex justify-content-center align-items-center flex-column">
                <span className="city-name primary-heading ">{weather.name}</span>
                <span className="date">{dayName} {getTimeFormat(d)}</span>
                <span className="weather-icon">
                    <img src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`} alt="weather-icon" /></span>
                <span className="weather-temp">{parseInt(weather.main.temp -  273.15) } <sup>°C</sup></span>
                <span className="description text-capitalize">{weather.weather[0].description}</span>
                <div className="d-flex justify-content-between align-items-center feels my-5 ">
                    <div className="d-flex justify-conten-center align-items-center like pr-3 pr-md-4 border-right">
                        Feels Like: {parseInt(weather.main.feels_like - 273.15)} °C
                    </div>
                    <div className="d-flex justify-conten-center align-items-center pressure  pl-3 pl-md-4">
                        Pressure: {weather.main.pressure} mbar
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <button className="btn btn-primary" onClick= {this.toggleModal}>5 Days Forecast</button>
                    <button className="btn btn-primary mt-3" onClick= {this.toggleHistoricalModal}>Monthly/Yearly Data</button>
                </div>
            </div>
        )
    }



    render() {
        const {listing } = this.props,
            { loading } = listing,
            modalOpened = this.state.modalOpened,
            historicalDataOpened = this.state.historicalDataOpened;


        return (
            <div className="weather">
                <SidePanel>
                    <div className="crime">
                        <h3 className="primary-heading text-white m-0 pt-3 pt-md-5 heading pl-5">Weather Update</h3>
                        <div className="weather__container">
                        {
                            loading ? <Loading /> : this.weatherDetails()
                        }
                        </div>
                    </div>
                </SidePanel>
                {
                    modalOpened &&   <Module id="crime" modalOpened={modalOpened} closeModal={this.toggleModal}>
                        <WeatherDetails city={name}/>
                    </Module>
                }

                {
                    historicalDataOpened &&   <Module  modalOpened={historicalDataOpened} closeModal={this.toggleHistoricalModal}>
                        <HistoricalWeatherDetails city={name}/>
                    </Module>
                }
            </div>

        );
    }
}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getWeather: (city) => dispatch(getWeather(city))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Weather);

