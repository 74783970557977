import React, { Component } from "react";
import "./History.scss";
import {getHistory} from "../../../store/actions";
import {connect} from "react-redux";
import Loading from "../../../components/Loading/Loading";
import {getQueryString, getFilterBY} from "../../../utils/helper";
import SidePanel from "../../../components/SidePanel/SidePanel";
import Module from "../../../components/Module/Module";
import HistroyDetails from "./HistroyDetails";

class History extends Component{

    constructor(props) {
        super(props);
        this.state = {
            modalOpened : false,
            detailsId : null,
            filterBy : '',
            name: ''
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.openedDetails = this.openedDetails.bind(this);
    }


    componentDidMount() {
        const {location} = this.props;
        let filterBy = '',
            name = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            name = getQueryString(location.search, filterBy);
        }
        if (name) {
            this.props.getHistory(name, filterBy);
        }
        this.setState({
            filterBy, name
        })
    }

    componentDidUpdate(nextProps) {
        const {location} = nextProps.history;
        const {name} = this.state;
        let nextName = null;
        let filterBy = '';
        if(location.search) {
            filterBy = getFilterBY(location.search);
            nextName = getQueryString(location.search, filterBy);
        }
        if (nextName && name !== nextName) {
            this.props.getHistory(nextName, filterBy);
            this.setState({
                filterBy,
                name : nextName
            })
        }
    }

    toggleModal(){
        const modalOpened = this.state.modalOpened;
        this.setState({
            modalOpened : !modalOpened
        })
    }

    openedDetails(id){
        this.setState({
            detailsId : id
        });
        this.toggleModal();
    }

    listingRender(){
        const {listing} = this.props;
        const history = listing.history;
        if(history?.history && history?.history.length){
            const infoData =  history?.history[0];
            if(infoData?._id){
                delete infoData._id
            }
            const historicalInformation = []
            for (let key in infoData) {
                historicalInformation.push(infoData[key])
            }
            if(historicalInformation?.length){
                return  historicalInformation.map((h, i) => {
                    return <p key={`indo-${i}`} className="text-white mb-3 px-3 px-md-4">{h}</p>
                })
            }
            return  <div className="py-5 text-center text-white">
                History not  found
            </div>
        }
        // if(history.objectId){
        //     return <>
        //         <ul className="bullet history-listing">
        //             {this.historyMap('Name', history?.name)}
        //             {this.historyMap('Flag', history?.flag)}
        //             {this.historyMap('Country Name', history?.county_name)}
        //             {this.historyMap('City', history?.city)}
        //             {this.historyMap('Capital', history?.capital)}
        //             {this.historyMap('Largest City', history?.largestCity)}
        //             {this.historyMap('Population', history?.population)}
        //         </ul>
        //         <div className="d-flex justify-content-center mt-4">
        //             <button className="btn btn-primary" onClick= {this.toggleModal}>More Details</button>
        //         </div>
        //     </>
        //
        // }
        return (
            <div className="py-5 text-center text-white">
                History not  found
            </div>
        );
    }


    historyMap(key, value){
        return value ?
            <li className="text-white d-flex justify-content-between pointer">
                <span className=" w-50">{key}</span>
                <span className="w-50">
                        {
                            key === 'Flag' ?
                                <img className="flag" src={value} alt={key} /> :
                                value

                        }
                    </span>
            </li>
            :
            null;
    }

    render() {
        const {listing } = this.props,
            { loading } = listing,
            modalOpened = this.state.modalOpened;

        const {name} = this.state;

        return (
            <div className="history-listing">
                <SidePanel>
                    <div className="history-listing__wrapper">
                        <h3 className="primary-heading text-white m-0 py-3 py-md-5 heading pl-5">History of {this.state.name}</h3>
                        {
                            loading ? <Loading /> : this.listingRender()
                        }
                    </div>
                </SidePanel>
                {
                    modalOpened &&   <Module id="history" modalOpened={modalOpened} closeModal={this.toggleModal}>
                        <HistroyDetails city={name} id={this.state.detailsId}/>

                    </Module>
                }
            </div>

        );
    }

}



const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});

const mapDispatchToProps = dispatch => {
    return {
        getHistory: (name, filterBY) => dispatch(getHistory(name, filterBY))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(History);

