import React, { Component } from "react";
import {connect} from "react-redux";
import {isMillion} from "../../../utils/helper";


class InfoDetails extends Component{


    renderDetails(){
        const {city} = this.props;
        const {info} = this.props.listing;
        if(!info || !info.info)
            return null;
        return (
            <div className="modal-content__container-info text-white">
                <div className="head  bg-primary">
                    <h2 className="modal-header-heading  text-center">Statistics about <br/> <span className='font-weight-bold'>"{city}"</span> city</h2>
                </div>
                <div className="px-0 px-md-5 mx-5 my-4 font-secondary">
                    <div className="row ">
                        <div className="col-6 mb-5 key">
                            City Code:
                        </div>
                        <div className="col-6 mb-5">
                            {info.info.cityCode}
                        </div>
                        <div className="col-6 mb-5 key">
                            Population:
                        </div>
                        <div className="col-6 mb-5">
                            {isMillion(info.info.population)}
                        </div>
                        <div className="col-6 mb-5 key">
                            Children Population:
                        </div>
                        <div className="col-6 mb-5">
                            {isMillion(info.info.childPopulation)}
                        </div>
                        <div className="col-6 mb-5 key">
                            Married:
                        </div>
                        <div className="col-6 mb-5">
                            {info.info.married}%
                        </div>
                        <div className="col-6 mb-5 key">
                            Average Age:
                        </div>
                        <div className="col-6 mb-5">
                            {info.info.avgAge}%
                        </div>
                        {
                            info.info.Affordability_Rank ?
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 mb-5 key">
                                        Affordability Rank:
                                    </div>
                                    <div className="col-6 mb-5">
                                        {info.info.Affordability_Rank}
                                    </div>
                                </div>
                            </div> : null
                        }
                        {
                            info.info.Education_And_ChildCare_Rank ?
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6 mb-5 key">
                                            Education And ChildCare Rank:
                                        </div>
                                        <div className="col-6 mb-5">
                                            {info.info.Education_And_ChildCare_Rank}
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {
                            info.info.Family_Fun_Rank ?
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 mb-5 key">
                                        Family Fun Rank:
                                    </div>
                                    <div className="col-6 mb-5">
                                        {info.info.Family_Fun_Rank}
                                    </div>
                                </div>
                            </div> : null
                        }
                        {
                            info.info.Health_And_Safety_Rank ?
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 mb-5 key">
                                        Health And Safety Rank:
                                    </div>
                                    <div className="col-6 mb-5">
                                        {info.info.Health_And_Safety_Rank}
                                    </div>
                                </div>
                            </div> : null
                        }
                        {
                            info.info.Socio_Economics_Rank ?
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 mb-5 key">
                                        Socio Economics Rank:
                                    </div>
                                    <div className="col-6 mb-5">
                                        {info.info.Socio_Economics_Rank}
                                    </div>
                                </div>
                            </div> :null
                        }
                        <div className="col-6 mb-5 key">
                            Details:
                        </div>
                        <div className="col-6 mb-5">
                            <a href={info.info.wikiUrl} target="_blank" rel="noopener noreferrer" >Wikipedia</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return  <div className="info-details-modal">
            {  this.renderDetails() }

        </div>;
    }


}


const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});


export default connect(
    mapStateToProps,
    null
)(InfoDetails);
