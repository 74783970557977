import React, { Component } from "react";
import { Route, Switch, Redirect, NavLink, Link } from "react-router-dom";

import { connect } from "react-redux";
import logo from "../../assets/images/dl-logo.gif";
import BannerSlider from "../../components/BannerSlider/BannerSlider";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import signature from "../../assets/images/landingPageAssets/signature.png";
import aboutImg from "../../assets/images/landingPageAssets/about-us.png";
import ourVision from "../../assets/images/landingPageAssets/our-vision.png";
import playIcon from "../../assets/images/landingPageAssets/play-icon.png";
import dvm from "../../assets/images/landingPageAssets/dvm.png";
import relator from "../../assets/images/landingPageAssets/relator.png";
import partner from "../../assets/images/landingPageAssets/partner.png";
import statistics from "../../assets/images/landingPageAssets/statistics.png";
import movingTip from "../../assets/images/landingPageAssets/moving-tip.png";
import Slider from "react-slick";
import newsletter from "../../assets/images/landingPageAssets/newsletter.png";
import map from "../../assets/images/landingPageAssets/map.png";
import {
  AiFillLinkedin,
  AiOutlineTwitter,
  GrMail,
  GrYoutube,
  ImPhoneHangUp,
  RiFacebookFill,
  TiSocialInstagram,
} from "react-icons/all";
import RealtorsDetails from "../../components/RealtorsListing/RealtorsDetails";
import Relators from "../Relators/Relators";
import LandingPage from "../LandingPage/LandingPage";
import WebFooter from "../../components/WebFooter/WebFooter";
import $ from "jquery";
import { designation, landingPageToggle, logout } from "../../store/actions";
import WebHeader from "../../components/WebHeader/WebHeader";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittedForm: true,
      to: { name: "" },
      from: { name: "" },
      filterBy: "state",
      menuActive: false,
      modal: false,
      newsletterEmail: "",
      newsletterEmailLoading: false,
    };
    // this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.landingPageToggle(true);
    this.watchWindowScroll();
  }

  componentWillUnmount() {
    this.props.landingPageToggle(false);
    // this.watchWindowScroll();
  }

  watchWindowScroll() {
    const $window = $(window);
    const $header = $("#landing-header");
    $window.scroll(function () {
      const windowScroll = $(window).scrollTop();
      if (windowScroll > 0) $header.addClass("header__bg");
      else $header.removeClass("header__bg");
    });
  }

  render() {
    return (
      <div className="root">
        <WebHeader />

        <Switch>
          <Route
            exact
            path="/realtors"
            name="registration"
            component={Relators}
          />
          <Route
            exact={true}
            path="/"
            name="landingpage"
            component={LandingPage}
          />{" "}
        </Switch>

        <WebFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    landingPageToggle: (bol) => dispatch(landingPageToggle(bol)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
