import { LOGOUT_REQUEST } from "../store/actions";
import storeFactory from "../store/store";
import { toast } from "react-toastify";
const { store } = storeFactory();

export const handleFetchResponse = async (res, showMessage) => {
  // console.log('res: ', res, showMessage)
  if (res.status >= 400 && res.status < 600) {
    // console.log('there before')
    if (res.status === 401 && localStorage.DLToken) {
      toast.error("Your Session has been Expired!");
      localStorage.clear();
      store.dispatch({ type: LOGOUT_REQUEST });
      return;
    }

    if (res.status === 404 && localStorage.DLToken) {
      showMessage && (toast.error("Data not found!"));
      return;
    }
    const response = await res.json();
    throw response;
  }
  return res.json();
};
