import {PARTNER_WITH_US_REQUEST, PARTNER_WITH_US} from "../actions";

const initialState = {
    partnerWithUS: '',
    loading: false

};

export default (state = initialState, action) => {
    switch (action.type) {
        case PARTNER_WITH_US_REQUEST:
            return {
                ...state,
                loading: action.payload
            };
        case PARTNER_WITH_US:
            return {
                ...state,
                partnerWithUS: action.payload,
            };
        default:
            return state;
    }
};
