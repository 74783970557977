import React, { Component } from "react";
import { connect } from "react-redux";

class RealtorsDetails extends Component {
  render() {
    const { listing, id } = this.props;
    const {  realtorsListing } = listing;
    const details = realtorsListing.find(data => data._id === id);

    return (
        <div className="modal-content__container-job text-white">
          <div className="modal-header text-center bg-primary border-0 py-4 text-white d-flex justify-content-center align-items-center font-secondary font-weight-bold">
            Details
          </div>
          <div className="content-job-details px-0 px-md-5 mx-5 my-4 font-secondary">
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>Address:</div>
              <div className='col-12 col-md-9 value'>{details.formattedAddress}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>Square Footage:</div>
              <div className='col-12 col-md-9 value'>{details.squareFootage}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>Bedrooms:</div>
              <div className='col-12 col-md-9 value'>{details.bedrooms}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>Bathrooms:</div>
              <div className='col-12 col-md-9 value'>{details.bathrooms}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>Price:</div>
              <div className='col-12 col-md-9 value'>${details.price}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>County:</div>
              <div className='col-12 col-md-9 value'>{details.county}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>City:</div>
              <div className='col-12 col-md-9 value'>{details.city}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>State:</div>
              <div className='col-12 col-md-9 value'>{details.state}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>zipCode:</div>
              <div className='col-12 col-md-9 value'>{details.zipCode}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>latitude:</div>
              <div className='col-12 col-md-9 value'>{details.latitude}</div>
            </div>
            <div className="description-line py-3 row">
              <div className='col-12 col-md-3 key'>longitude:</div>
              <div className='col-12 col-md-9 value'>{details.longitude}</div>
            </div>
          </div>
        </div>
    );
  }
}



const mapStateToProps = state => ({
  listing: state.listing,
  golbal: state.golbal,
});



export default connect(
    mapStateToProps,
    null
)(RealtorsDetails);

