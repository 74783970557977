import React, { Component } from "react";
import {connect} from "react-redux";
import {isMillion} from "../../../utils/helper";
import "./MoveDetails.scss";


class MoveDetails extends Component{


    renderBoundTr(array){
        return array.map((obj)=>{
            return (
                <tr>
                    <td>{obj.INBOUND}</td>
                    <td>{obj.TYPE}</td>
                    <td>{obj.OUTBOUND}</td>
                </tr>
            )
        })

    }

    renderTableBound(key, title){
        return ( <table>
            <thead>
            <tr>
                <th>Inbound</th>
                <th>{title}</th>
                <th>Outbound</th>
            </tr>
            </thead>
                <tbody>
                {this.renderBoundTr(key)}
                </tbody>
        </table>
        )
    }

    renderCard(card, heading, title,  id){
     return card && card.length ?
                <div className="card">
                    <div className="card-header">
                        <a className="card-link d-flex justify-content-between collapsed" data-toggle="collapse" href={`#${id}`}>
                            {heading}
                            <i className="icon-plus-circle center-icon"></i>
                            <i className="icon-minus-circle center-icon"></i>
                        </a>
                    </div>
                    <div id={id} className="collapse" data-parent="#accordion">
                        <div className="card-body">
                            {this.renderTableBound(card, title)}
                        </div>
                    </div>
                </div>
             : null;

    }

    renderDetails(){
        const {city} = this.props;
        const {moves} = this.props.listing;

        if(!moves || !moves.migrate)
            return null;

        const migrate = moves.migrate ;

        return (
            <div className="modal-content__container-info text-white">
                <div className="head bg-primary">
                    <h2 className="modal-header-heading text-center">Stats about <br/> <span className=' font-weight-bold'>"{city}"</span> city</h2>
                </div>
                <div className="px-0 px-md-5 mx-5 my-4 font-secondary">
                    <div className="row">
                        {/*<div className="col-6 mb-5 key">*/}
                        {/*    State Name:*/}
                        {/*</div>*/}
                        {/*<div className="col-6 mb-5">*/}
                        {/*    {migrate.stateName}*/}
                        {/*</div>*/}
                        {/*<div className="col-6 mb-5 key">*/}
                        {/*    City Code :*/}
                        {/*</div>*/}
                        {/*<div className="col-6 mb-5">*/}
                        {/*    {migrate.cityCode}*/}
                        {/*</div>*/}
                        <div className="col-6 mb-5 key">
                            Population:
                        </div>
                        <div className="col-6 mb-5">
                            {isMillion(migrate.population)}
                        </div>
                        <div className="col-6 mb-5 key">
                            Moves In:
                        </div>
                        <div className="col-6 mb-5">
                            {isMillion(migrate.in)}
                        </div>
                        <div className="col-6 mb-5 key">
                            Moves Out:
                        </div>
                        <div className="col-6 mb-5">
                            {isMillion(migrate.out)}
                        </div>
                    </div>
                  {/*  <div className="row">
                        <div className="col-12">
                            <div id="accordion" className="bound-accordion">
                                {this.renderCard(migrate.Primary_Reason_for_Moving, 'Primary Reason for Moving', 'Reason',  'primary-reason')}
                                {this.renderCard(migrate.Age_Ranges, 'Age Ranges', 'Age','age-ranges')}
                                {this.renderCard(migrate.Income, 'Income', 'Income', 'income')}
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        )
    }

    render(){
        return  <div className="info-details-modal">
            {  this.renderDetails() }

        </div>;
    }


}


const mapStateToProps = state => ({
    listing: state.listing,
    golbal: state.golbal,
});


export default connect(
    mapStateToProps,
    null
)(MoveDetails);
