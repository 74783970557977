import React, { Component } from "react";
import collapse from "../../assets/images/icons/collapas.png";
import "./SelectDestination.scss";
import States from "../../constant/states";
import { errorToast } from "../../utils/toaster";

import { sortedStateByCityAlphabetic } from "../../utils/helper";
const citiesSort = sortedStateByCityAlphabetic();

class SelectDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destination: "",
      filterBy: "state",
    };
  }

  change = (event) => {
    let { name, value } = event.target;
    if (name === "filterBy") {
      this.setState({ destination: "" });
    }
    this.setState({ [name]: value });
    // console.log(value, "myName>>>>>>>>");
  };

  submit = () => {
    const { destination } = this.state;
    if (!destination) {
      errorToast("Please select a destination");
      return false;
    }

    console.log(this.state);
    if (this.props.onClickHandler) {
      this.props.onClickHandler(this.state);
    }
  };

  renderOptions() {
    const filterBy = this.state.filterBy;

    return filterBy === "city"
      ? citiesSort.map((item) => {
          return (
            <option key={item.state} value={item.cities[0].name}>
              {item.cities[0].name}
            </option>
          );
        })
      : States.map((item) => {
          return (
            <option key={item.state} value={item.name}>
              {item.name}
            </option>
          );
        });
  }
  render() {
    const formCollapse = this.state.formCollapse;
    let className = formCollapse
      ? "active destination-form"
      : "destination-form";
    className = this.props.className
      ? className + " " + this.props.className
      : className;
    return (
      <div className={className}>
        <form>
          <button
            type="button"
            className="btn-transparent destination-form-close"
            onClick={this.collapseHandler}
          >
            <img src={collapse} alt="close" />
          </button>
          <h3 className="text-white destination-form__title">
            Choose Your Destination
          </h3>
          <div className="destination-form__container d-flex  justify-content-between  align-items-lg-center align-items-md-start align-items-end">
            <div className="field-group d-flex flex-column flex-md-row justify-content-between align-items-center">
              <select
                name="filterBy"
                onChange={this.change}
                value={this.state?.filterBy}
              >
                <option value="state">State</option>
                <option value="city">City</option>
              </select>
              <select
                name="destination"
                onChange={this.change}
                value={this.state.destination}
              >
                <option value="0" hidden>
                  For
                </option>
                {this.renderOptions()}
              </select>
            </div>

            <button
              type="button"
              className="search-form-btn d-flex justify-content-center align-items-center"
              onClick={this.submit}
            >
              <i className="icon-searching-magnifying-glass"></i>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default SelectDestination;
