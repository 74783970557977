import React, { Component } from "react";
import { connect } from "react-redux";
import { setPassword } from "../../../store/actions";

import "../Account.scss";
import {NavLink, Redirect} from "react-router-dom";
import {errorToast, successToast} from "../../../utils/toaster";

class SetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                password : '',
                passwordConfirmation : '',
            },
            fieldError: {
                password : 'Password must be required',
                passwordConfirmation : 'Confirm password must be required',
            },
            submitted : false,
            loading : false,
            emailSend : false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleChange(event) {
        const {name, value } = event.target;
        this.setState({user : {
                ...this.state.user,
                [name] : value
            }});
        if(this.state.submitted){
            this.formValidation();
        }
    }


    handleBlur(){
        if(this.state.submitted){
            this.formValidation();
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        if(!this.state.submitted){
            this.setState( {submitted : true});
        }
        const formValid = this.formValidation();
        if(formValid){
            const {password} = this.state.user;
            const { refreshToken } = this.props.match.params;
            this.setState({ loading: true })
            this.props.setPassword(password, refreshToken).then((res) => {
                this.setState({ emailSend: true })
                successToast('Your password has been changed successfully')
            }).catch((err) => {
                errorToast(err?.payload?.message);
            })
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
    }

    formValidation(){
        let isValid = true;
        const {password, passwordConfirmation } = this.state.user;
        const fieldError = this.state.fieldError;

        if(!password && isValid){
            isValid  = false
        }
        if(password){
            fieldError.password = password.length >= 5 ? false : 'Password must be greater then 5';
            if(isValid){
                isValid  = password.length >= 5
            }
        }

        if(!passwordConfirmation && isValid){
            isValid  = false
        }

        if(passwordConfirmation){
            fieldError.passwordConfirmation = passwordConfirmation === password ? false : 'Password does\'t  match';
            if(isValid){
                isValid  = passwordConfirmation === password

            }
        }

        this.setState( {fieldError : fieldError});
        return isValid;
    }


    render() {
        const { emailSend } = this.state;
        if (emailSend)
            return <Redirect to="/account/login" />;


        const {loading}= this.state;
        const {password, passwordConfirmation} = this.state.user;
        const {
            password: passwordError,
            passwordConfirmation: passwordConfirmationError} = this.state.fieldError;
        const submitted = this.state.submitted;
        return (
            <div className="login w-100">
                <form  className="w-100" onSubmit={this.handleSubmit}>
                    <h3 className="text-center primary-heading mb-5">Reset Password</h3>

                    <div className="input-field">
                        <input
                            className={submitted && passwordError ? 'error' : ''}
                            value={password}
                            name='password'
                            type="password"
                            placeholder="New Password"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}  />
                        {submitted && passwordError ?   <small>{passwordError}</small> : null}
                    </div>
                    <div className="input-field">
                        <input
                            className={submitted && passwordConfirmationError ? 'error' : ''}
                            value={passwordConfirmation}
                            name='passwordConfirmation'
                            type="password"
                            placeholder="Confirmation Password"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}  />
                        {submitted && passwordConfirmationError ?   <small>{passwordConfirmationError}</small> : null}

                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                        <button className="btn btn-primary">Reset Password
                            {loading ? <i className="icon-reload text-white loading"></i> :null
                            }
                        </button>
                        <NavLink className="btn btn-secondary f-14 mt-3 mt-md-0" to="/account/login">Go To Login </NavLink>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

const mapDispatchToProps = dispatch => {
    return {
        setPassword: (password, token) => dispatch(setPassword(password, token))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetPassword);
