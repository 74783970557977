import React, { Component } from "react";
import { connect } from "react-redux";
import { registration } from "../../../store/actions";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import "../Account.scss";
import { emailValidation } from "../../../utils/helper";
import { Link } from "react-router-dom";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      fieldError: {
        name: "Name must be required",
        email: "Email must be required",
        password: "Password must be required",
        password_confirmation: "Confirm password must be required",
      },
      submitted: false,
      isPasswordTypeOpen: false,
      isPasswordAgainTypeOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handlePasswordIconType = this.handlePasswordIconType.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
    if (this.state.submitted) {
      this.formValidation();
    }
  }

  handlePasswordIconType(field){
    let fieldName = field === "password" ? "isPasswordTypeOpen" : "isPasswordAgainTypeOpen";
    let fieldFromState = field === "password" ? this.state.isPasswordTypeOpen : this.state.isPasswordAgainTypeOpen;
    this.setState({
      [fieldName] : !fieldFromState
    })
  }

  handleBlur() {
    if (this.state.submitted) {
      this.formValidation();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.submitted) {
      this.setState({ submitted: true });
    }
    const formValid = this.formValidation();
    // console.log("form submit formValid", formValid);
    if (formValid) {
      this.props.registration({
        ...this.state.user,
      });
    }
  }

  formValidation() {
    let isValid = false;
    const {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
    } = this.state.user;
    const fieldError = this.state.fieldError;

    if (email) {
      const validEmail = emailValidation(email);
      fieldError.email = validEmail ? false : "Please enter a valid Email";
      isValid = validEmail;
    }
    if (!name && isValid) {
      isValid = false;
    }
    if (name) {
      fieldError.name =
        name.length >= 3
          ? false
          : "Password must be greater then and equal to 3";
      if (isValid) {
        isValid = name.length >= 3;
      }
    }
    if (!password && isValid) {
      isValid = false;
    }
    if (password) {
      fieldError.password =
        password.length >= 5 ? false : "Password must be greater then 5";
      if (isValid) {
        isValid = password.length >= 5;
      }
    }

    if (!passwordConfirmation && isValid) {
      isValid = false;
    }

    if (passwordConfirmation) {
      fieldError.password_confirmation =
        passwordConfirmation === password ? false : "Password does't  match";
      if (isValid) {
        isValid = passwordConfirmation === password;
      }
    }

    this.setState({ fieldError: fieldError });
    return isValid;
  }

  render() {
    const { loading } = this.props.auth;
    const {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
    } = this.state.user;
    const {
      name: nameError,
      email: emailError,
      password: passwordError,
      password_confirmation: passwordConfirmationError,
    } = this.state.fieldError;
    const submitted = this.state.submitted;
    return (
      <div className="login w-100">
        <form className="w-100" onSubmit={this.handleSubmit}>
          <h3 className="text-center primary-heading mb-5">Registration</h3>
          <div className="input-field">
            <input
              className={submitted && nameError ? "error" : ""}
              value={name}
              name="name"
              placeholder="Name"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            {submitted && nameError ? <small>{nameError}</small> : null}
          </div>
          <div className="input-field">
            <input
              className={submitted && emailError ? "error" : ""}
              value={email}
              name="email"
              placeholder="Email"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            {submitted && emailError ? <small>{emailError}</small> : null}
          </div>
          <div className="input-field password-input-con">
            <input
              className={submitted && passwordError ? "error" : ""}
              value={password}
              name="password"
              type={!this.state.isPasswordTypeOpen ? "password" : "text"}
              placeholder="Password"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <span onClick={() => this.handlePasswordIconType('password')}>
            {!this.state.isPasswordTypeOpen ? (
            <EyeOutlined style={{ fontSize: '20px', color: '#5b5b5b' }}/>
            ):(
            <EyeInvisibleOutlined style={{ fontSize: '20px', color: '#5b5b5b' }}/>
            )}
            </span>
            {submitted && passwordError ? <small>{passwordError}</small> : null}
          </div>
          <div className="input-field password-input-con">
            <input
              className={submitted && passwordConfirmationError ? "error" : ""}
              value={passwordConfirmation}
              name="password_confirmation"
              type={!this.state.isPasswordAgainTypeOpen ? "password" : "text"}
              placeholder="Confirmation Password"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
            />
            <span onClick={() => this.handlePasswordIconType('passwordAgain')}>
            {!this.state.isPasswordAgainTypeOpen ? (
            <EyeOutlined style={{ fontSize: '20px', color: '#5b5b5b' }}/>
            ):(
            <EyeInvisibleOutlined style={{ fontSize: '20px', color: '#5b5b5b' }}/>
            )}
            </span>
            {submitted && passwordConfirmationError ? (
              <small>{passwordConfirmationError}</small>
            ) : null}
          </div>
          <button className="btn btn-primary">
            Registration
            {loading ? (
              <i className="icon-reload text-white loading"></i>
            ) : null}
          </button>
        </form>

        <div className="login__register">
          <span className="f-14">Already have an account? </span>
          <span>
            <Link to="/account/login" className="f-14">
              Click here
            </Link>
          </span>
          <span className="f-14"> to login.</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    registration: (user) => dispatch(registration(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
